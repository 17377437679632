//teuxdeux refactor into normal banner for toolkit

.bs-docs-header,
.bs-docs-masthead {
    position: relative;
    padding: 30px 0;
    text-align: center;
    background-color: $color-module;    
    background-image: linear-gradient(to bottom, darken($color-module,10%) 0, $color-module 100%);   
    background-repeat: repeat-x;    
    border-bottom: 1px solid $color-module-dark;
    border-top: 1px solid $color-module-darker;
}
.bs-docs-masthead .bs-docs-booticon {
    margin: 0 auto 30px
}
.bs-docs-masthead h1 {
    font-weight: 300;
    line-height: 1;
}
.bs-docs-masthead .lead {
    margin: 0 auto 30px;
    font-size: 20px;
}
.bs-docs-masthead .version {
    margin-top: -15px;
    margin-bottom: 30px;
    color: #9783b9
}
.bs-docs-masthead .btn {
    width: 100%;
    padding: 15px 30px;
    font-size: 20px
}
@media (min-width: 480px) {
    .bs-docs-masthead .btn {
        width: auto
    }
}
@media (min-width: 768px) {
    .bs-docs-masthead {
        padding: 80px 0
    }
    .bs-docs-masthead h1 {
        font-size: 60px
    }
    .bs-docs-masthead .lead {
        font-size: 24px
    }
}
@media (min-width: 992px) {
    .bs-docs-masthead .lead {
        width: 80%;
        font-size: 30px
    }
}
.bs-docs-header {
    margin-bottom: 20px;
    font-size: 20px
}
.bs-docs-header h1 {
    margin-top: 0;
    font-weight: 600;
}
.bs-docs-header p {
    margin-bottom: $space;
    font-weight: 300;
    line-height: 1.4
}
.bs-docs-header .container {
    position: relative
}
@media (min-width: 768px) {
    .bs-docs-header {
        padding-top: 60px;
        padding-bottom: 60px;
        font-size: 24px;
        text-align: left
    }
    .bs-docs-header h1 {
        font-size: 50px;
        line-height: 1;        
    }
}
@media (min-width: 992px) {
    .bs-docs-header h1,
    .bs-docs-header p {
        margin-right: 380px
    }
}