/**
 * Responsive Images
 */

@mixin col-divider-setup($namespace: '') {
    .tk-row-divider#{$namespace} {
        //default square 1:1

        [class*='col-'] {
            border-right: 1px solid $color-border;
            &:last-child {
                border: 0;
            }
        }
    }
}

@include col-divider-setup();

@media (min-width: $screen-xs-min) {
    @include col-divider-setup('-xs');
}

@media (min-width: $screen-sm-min) {
    @include col-divider-setup('-sm');
}

@media (min-width: $screen-md-min) {
    @include col-divider-setup('-md');
}

@media (min-width: $screen-lg-min) {
    @include col-divider-setup('-lg');
}
