@mixin flex-order($namespace: ""){

    //Fex order with breakpoints

    .flex-order-1#{$namespace} {
        order: 1;
    }
    .flex-order-2#{$namespace} {
        order: 2;
    }
    .flex-order-3#{$namespace} {
        order: 3;
    }

   
}

@include flex-order();

@media (min-width: $screen-xs-min) {
    @include flex-order("-xs");
}

@media (min-width: $screen-sm-min) {
    @include flex-order("-sm");
}

@media (min-width: $screen-md-min) {
    @include flex-order("-md");
}

@media (min-width: $screen-lg-min) {
    @include flex-order("-lg");
}




