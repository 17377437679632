// Global Business link color
body.business {
    a {
        &:hover {
            color: $color-lubs-link-hover;
        }
    }
}

// Reset site name hover colour
body.business {
    .local-header-title a:hover {
        color: #fff;
    }
}

// CMS Content Links
.cms {
    body.business & {
        a:not(.btn) {
            color: $color-lubs-link;
        }
        a:hover:not(.btn) {
            color: $color-lubs-link-hover;
        }
        .course-header-cta {
            a {
                color: $color-white;
            }
            a:hover {
                color: $color-white;
            }
        }
        .social-course {
            a {
                color: $color-brand-2;
            }
            a:hover {
                color: $color-brand-2;
            }
        }
    }
}

/*
// Event Links
.event__categories__data {
    body.business & {
        a {
            color: $color-lubs-link;
        }
        a:hover {
            color: $color-lubs-link-hover;
        }
    }
}
*/

// Table Links
.table {
    body.business & {
        a {
            color: $color-lubs-link;
        }
        a:hover {
            color: $color-lubs-link-hover;
        }
    }
}

// Twitter Feed Links
.tweet-content {
    body.business & {
        a {
            color: $color-lubs-link;
        }
        a:hover {
            color: $color-lubs-link-hover;
        }
    }
}

// Staff List Links
.table-profiles {
    body.business & {
        a {
            color: $color-lubs-link;
        }
        a:hover {
            color: $color-lubs-link-hover;
        }
    }
}

// Island Featured Links
.island-featured {
    body.business & {
        a:not(.btn) {
            color: $color-lubs-link;
        }
        a:hover {
            color: $color-lubs-link-hover;
        }
    }
}

// Widget 3006 Linked Navigation
.widget-3006 {
    body.business & {
        a {
            color: $color-lubs-link;
        }
        a:hover {
            color: $color-lubs-link-hover;
        }
    }
}

// Page Content Nav Links
.page-contents-nav {
    body.business & {
        a {
            color: $color-lubs-link;
        }
        a:hover {
            color: $color-lubs-link-hover;
        }
    }
}

// Search Result Links
.flag .url {
    body.business & {
        a {
            color: $color-lubs-link;
        }
        a:hover {
            color: $color-lubs-link-hover;
        }
    }
}

// Business Blog
.tk-item-blog,
.blog-entry__author {
    body.business & {
        a {
            color: $color-lubs-link;
        }
        a:hover {
            color: $color-lubs-link-hover;
        }
    }
}
.blog-entry__title {
    body.business & {
        a {
            color: $color-lubs-link;
        }
        a:hover {
            color: $color-lubs-link-hover;
        }
    }
}

.widget-3004 {
    body.business & {
        a {
            color: $color-lubs-link;
        }
        a:hover {
            color: $color-lubs-link-hover;
        }

        a.more {
            &:hover {
                color: $color-lubs-link-hover;
            }
        }

    }
}

// Instagram
.widget-3004 {
    body.business & {
        a {
            color: $color-lubs-link;
            &:hover {
                color: $color-lubs-link-hover;
            }
        }
    }
}

// Publications
.publications {
    body.business & {
        a {
            color: $color-lubs-link;
            &:hover {
                color: $color-lubs-link-hover;
            }
        }
    }
}

// Publications
.quick-search  {
    body.business & {
        a {
            .title {
                color: $color-lubs-link;
            }
            
            &:hover {
                .title {
                    color: $color-lubs-link-hover;
                }
            }
        }
    }
}

// Remove corporate color from search button hover
.sm-toggle-search {
    body.business & {
        &:hover {
            background: lighten( $color-masthead, 10% );
        }
    }
}

.publications {
    body.business & {
        a {
            color: $color-lubs-link;
        }
    }
}

.list-featured-item-inner {
    body.business & {
        a {
            color: $color-lubs-link;
        }
    }
}

.course-keyfacts {
    body.business & {
        a {
            color: $color-lubs-link;
        }
        a:hover {
            color: $color-lubs-link-hover;
        }
    }
}

.course-throw-content {
    body.business & {
        a {
            color: $color-lubs-link;
        }
        a:hover {
            color: $color-lubs-link-hover;
        }
    }
}

.social-course {
    body.business & {
        a {
            color: $color-brand-2;
        }
        a:hover {
            color: $color-lubs-link-hover;
        }
    }
}

.quicklinks-list {
    body.business & {
        a:hover {
            color: #fff;
        }
    }
}

.btn {
    body.business & {
        &:hover {
            color: #fff;
        }
    }
}

.site-footer-lower a {
    body.business & {
        &:hover {
            color: #fff;
        }
    }
}

.footer-social a {
    body.business & {
        &:hover {
            color: lighten($color-brand-2, 40%);
        }
    }
}
