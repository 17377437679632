/**
 * News and events widget ajax filter
 */

.filter-box {
    display: none;
    position: relative;

    ul {
        background: white;
        padding: $space;
        box-shadow: 0px 1px 2px rgba(black, 0.25);
        margin: 0;
        position: absolute;
        width: 100%;
        top: 0px;
        left: 0;
        z-index: 1;

        &:after {
            content: '';
            display: block; /* reduce the damage in FF3.0 */
            position: absolute;
            top: -10px;
            right: 10px;
            width: 0;
            border-width: 0 10px 10px;
            border-style: solid;
            border-color: #fff transparent;
        }

        li {
            list-style: none;
        }

        li a {
            &:after {
                @extend .tk-icon;
                @extend .tk-icon-chevron-right:before;
                margin-left: 5px;
                position: relative;
                top: 2px;
                color: $color-brand-2;
            }
        }
    }
}

.filter-box.active {
    display: block;
}

/* Event cards

+----+-------+
| 12 | Event |
+----+-------+

*/

.event-card {
    margin-bottom: $space-sm;
    display: table;
    width: 100%;
    @extend .skin-box-white;

    .event-card-date {
        padding: $space-half/2 $space-xs $space-sm $space-sm;
        display: table-cell;
        vertical-align: top;
        border-right: 1px solid $color-border-light;
        width: 15%;

        @media (min-width: $screen-sm-min) {
            padding: $space-xs 0 $space-sm $space-sm;
            border: 0;
            width: 17%;
        }

        @media (min-width: $screen-md-min) {
            vertical-align: middle;
            background: $color-module-dark;
            padding: 0px $space $space-half/2 $space;
            border-top: 4px solid $color-brand-2;
            width: 17%;
        }

        .day {
            display: block;
            text-align: center;
            color: $color-font-dark;
            font-size: 30px;

            line-height: 110%;
            margin: 0;
            letter-spacing: 1px;
            @media (min-width: $screen-xs-min) {
                font-size: 40px;
            }
        }

        .month {
            display: block;
            text-align: center;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 14px;
            color: $color-font-light;
            letter-spacing: 1px;
            @media (min-width: $screen-xs-min) {
                font-size: 15px;
            }
        }
    }
    .event-card-content {
        vertical-align: top;
        display: table-cell !important; //overwrite equalizer
        padding: $space-sm;

        @media (min-width: $screen-sm-min) {
            padding: $space;
        }

        @media (min-width: $screen-md-min) {
            vertical-align: middle;
        }
    }

    h3,
    p {
        margin: 0;
    }

    h3 {
        font-size: 20px;
        line-height: 120%;
        margin-bottom: $space-half;
        position: relative;

        &:after {
            @extend .tk-icon;
            @extend .tk-icon-chevron-right:before;
            position: relative;
            top: 4px;
        }

        a {
            text-decoration: none;
            color: #4d4d4d;
        }
    }

    .location,
    .type {
        color: $color-font-light;
        font-size: 15px;
        margin-bottom: 6px;
        display: block;
        line-height: 140%;

        a {
            color: $color-brand-2;
        }
    }
}


.tk-widget-events {
    position: relative;
    overflow: visible;

    @media (max-width: $screen-md) {
        padding-bottom: 7rem;
    }

    .more--events {
        color: $color-font;
        text-transform: uppercase;
        font-weight: normal;

        @media (max-width: 767px) {
            font-weight: bold;
            color: black;
            display: inline-block;
            width: auto;
            min-width: 115px;;
            border: 1px solid $color-brand-2;
            position: absolute;
            bottom: 0;
            left: 62px;
            right: 62px;
            // transform: translateX(-50%);
            text-align: center;
    
            &:after {
              content: none;
            }

            a {
                font-size: 14px;
                color: inherit;
                font-weight: normal;
                display: block;
				padding: 8px;
            }

            &:hover {
                background: $color-brand-2;
                a {
                    color: #fff;
                    text-decoration: none;
                }
            }
          }
    
          @media (min-width: $screen-sm-min) {
            position: absolute;
            bottom: initial;
            top: -38px;
            right: 10px;

            a {
                color: #151414;

                &:hover {
                    text-decoration: none;
                }
            }
          }
    }

    .event {
        position: relative;
        overflow: auto;
        margin-bottom: 0;
        min-height: 12rem;
        padding: 4px 0 8px;

        &:before {
            content: '';
            display: block;
            height: 1px;
            background: $color-brand-2;
        }

        &:before {
            position: absolute;
            top: 0;
            right: 0;
        }

    }
        .event:before,
        .event:after,
        .event__title,
        .event__image,
        .event__details,
        .event__locations-and-times,
        .event__description,
        .event__categories {
            width: calc(100% - 52px);
            float: right;
        }

        .event--single.event--image {

            .event__title,
            .event__details,
            .event__locations-and-times,
            .event__description,
            .event__categories {
    
                @media (min-width: $screen-sm-min) {
                    width: calc( (100% - 52px) / 5 * 3 - 16px );
                }
            }
        }

        .event__title {
            font-family: $font-family-big;
            font-size: 22px; 
            padding-top: .7rem;

            a {
                color: $headings-color;

                &:hover {
                    color: $color-brand-2;
                }
            }
        }

            .event--image .event__title {
                padding-top: calc(50% - 9px);
                    
                @media (min-width: $screen-sm-min) {
                    padding-top: calc(50% - 14px);
                }
            }

            
            .event--single .event__title {

                @media (min-width: $screen-sm-min) {
                    margin-top: 18px;
                    padding-top: 0;
                }
            }

        .event__image {
            display: block;
            padding-bottom: calc(50% - 26px);
            background: 50% / cover;
            position: absolute;
            top: 8px;
            right: 0;

            @media (min-width: $screen-sm-min) {
                padding-bottom: calc(50% - 26px);
            }
        }

            .event--single .event__image {

                @media (min-width: $screen-sm-min) {
                    position: relative;
                    width: calc( (100% - 52px) / 5 * 2 );
                    float: left;
                    left: 52px;
                    padding-bottom: calc(100% / 5 - 10px);
                    margin-bottom: 14px;
                }
            }

        .event__text-container {
            position: relative;
        }

        .event__dates {
            width: 52px;
            float: left;
            text-align: center;
            padding-top: 1rem;
        }

            .event--image .event__dates {
                margin-top: 50%;
                transform: translateY( -100% );
                position: absolute;
                top: 0;
    
                @media (min-width: $screen-sm-min) {
                    margin-top: calc(50% - 42px);
                    transform: none;
                    transform: translateY( -72px);
                }
            }

            .event--single .event__dates {

                @media (min-width: $screen-sm-min) {
                    margin-top: 0;
                    transform: none;
                }
            }

            .event__dates__start {
                @extend .hide-accessible;
            }

                .event__dates__start__data {
                    position: relative;
                    padding-bottom: 12px;

                    &:after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 7px;
                        right: 7px;
                        height: 4px;
                        background: $color-brand-2;
                    }
                }

            .event__dates__end {
                @extend .hide-accessible;
            }

                .event__dates__end__data {
                    position: relative;
                    padding-top: 5px;
                    &:before {
                        content: 'to';
                        display: block;
                        background: #fff;
                        position: absolute;
                        top: -12px;
                        left: 50%;
                        transform: translateX(-50%);
                        font-size: 12px;
                        padding: 1px 4px;
                    }
                }


            .event__dates__data__month {
                position: relative;
                display: block;
                font-family: $font-family-sans-serif;
                font-size: 14px;
                text-transform: uppercase;

                @media (min-width: $screen-sm-min) {
                    font-size: 18px;
                }
            }

            .event__dates__data__day {
                position: relative;
                display: block;
                font-family: $font-family-big;
                font-size: 28px;
                line-height: 1em;
                color: $color-brand-2;

                @media (min-width: $screen-sm-min) {
                    font-size: 32px;
                }
            }


                .event__details__label--date {

                }

            .event__details__data {
                font-family: $font-family-big;
                display: inline;
            }

            .event__details__data--date {
                position: absolute;
                top: 0;
                left: 0;
                width: 84px;
            }

            .event__details__start-date {
                width: 84px;
                text-align: center;
            }

                .event__details__start-date__month,
                .event__details__end-date__month {
                    position: relative;
                    display: block;
                    font-family: $font-family-big;
                    font-size: 18px;
                    text-transform: uppercase;
                }

                .event__details__start-date__day,
                .event__details__end-date__day {
                    position: relative;
                    display: block;
                    font-family: $font-family-big;
                    font-size: 32px;
                    line-height: 1em;
                    color: $color-brand-2;
                }

                .event__details__start-date__month {
                    padding-top: 10px;
                }

                .event__details__start-date__day {
                    padding-bottom: 18px;

                    &:after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 18px;
                        right: 18px;
                        height: 4px;
                        background: $color-brand-2;
                    }
                }

            .event__details__label {
                @extend .hide-accessible;
            }

            .event__details__data {

            }  

        .event__locations-and-times {
            margin: 0 0 8px;
            font-family: $font-family-sans-serif;
            font-size: 14px;
        }

            .event__details__data--location,
            .event__details__data--time {
                font-family: $font-family-sans-serif;
                line-height: 16px;
            }

            .event__details__data--time {

                &:before {
                    content: " // ";
                    color: $color-brand-2;
                }
            }

        .event__description {
            font-size: 14px;
        }

        .event__categories {
            line-height: 1.2em;
            margin-bottom: 0;
            font-family: $font-family-sans-serif;
            text-transform: uppercase;
            margin-bottom: 8px;
        }

            .event__categories__label {
                @extend .hide-accessible;
            }

            .event__categories__data {
                display: inline;
                font-size: 12px;


                &:after {
                    content: ', ';
                }

                &:last-of-type {
                    &:after {
                        content: none;
                    }
                }
            }


}