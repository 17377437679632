/**
 * Quicklinks
 * Header section & footer nav
 */

.quicklinks-outer {
    position: relative;

    @media (max-width: $screen-xs-min - 1px) {
        padding-bottom: 28px;
        background: #111;
    }
}

.quicklinks {
    background: $color-masthead-dark;
    border-bottom: 1px solid white;
    color: white;
}

.quicklinks-inner {
    padding: 25px 10px 10px 10px;
    color: white;
    @media (min-width: $screen-xs-min) {
        padding-top: 35px;
    }
}

.quicklinks-close {
    position: absolute;
    top: 5px;
    right: 5px;

    .no-js & {
        display: none;
    }
}

.quicklinks-list {
    @include list-quicklinks();
}

.quicklinks-list-def {
    // definition list for address in the footer
    lh {
        padding-left: 6px;
    }
    dt {
        list-style: none;
        padding-left: 6px;
        font-size: 14px;
        color: $color-font-lighter;
        font-weight: $font-weight-book;
        line-height: 24px;
    }
}
