@mixin placeholder {		
	&::-webkit-input-placeholder { /* WebKit, Blink, Edge */
	    @content
	}
	&:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
	   @content
	}
	&::-moz-placeholder { /* Mozilla Firefox 19+ */
	   @content
	}
	&:-ms-input-placeholder { /* Internet Explorer 10-11 */
	   @content
	}
	&::-ms-input-placeholder { /* Microsoft Edge */
	   @content
	}
}