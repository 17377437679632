/**
 * Page footer
 */

//$teuxdeux: fix page footer, rename other page footer to something esle

//.page-footer { //related items
//    background: $color-module;
//    padding: $space 0;
//}

.multi-page-footer, /*deprecated*/
.page-footer {
    margin-bottom: $space;

    @media (min-width: $screen-xs-min) {
        border-top: 1px solid $color-border;
    }

    ul {
        padding: 0;
        margin: 0;
        @extend .clearfix;

        li {
            list-style: none;
            margin-bottom: $space-half;
            @media (min-width: $screen-xs-min) {
                width: 50%;
                float: left;
            }
        }

        a {
            text-decoration: underline;
            display: block;
            position: relative;
            padding: $space-half;
            text-decoration: none;
            color: $color-font;
            background: $color-module;

            @media (min-width: $screen-xs-min) {
                background: none;
            }

            &:hover {
                //text-decoration: underline;
                background-color: $color-module;
            }

            &:after {
                @extend .tk-icon;
                position: absolute;
                top: 50%;
                margin-top: -11px;
                font-size: 22px;
                color: $color-brand-2;
            }
        }

        .heading {
            @extend %all-caps;
            display: block;
            color: $color-brand-2;
        }

        .sub-heading {
            display: block;
        }

        .prev {
            text-align: left;

            a {
                padding-left: 30px;
                &:before {
                    left: 0;
                }
                &:after {
                    left: 0;
                    @extend .tk-icon-chevron-left:before;
                }
            }
        }

        .next {
            text-align: right;

            a {
                padding-right: 30px;
                &:before {
                    right: 0;
                }
                &:after {
                    right: 0;
                    @extend .tk-icon-chevron-right:before;
                }
            }
        }
    }
}
