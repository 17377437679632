/**
 * Mixin: list-no-style
 * Removes all style from ul/ol and child li tags
 */

@mixin list-no-style(){
	list-style: none;
	padding: 0;
	margin: 0;

	li {
		list-style: none;
	}
}

/*

*/

@mixin list-related(){
	@include list-no-style();      
    margin-bottom: $space-xs;

    li {
    	@include type-all-caps();
        display: inline-block;        
        margin-right: $space-xs;        
        color: $color-font-lighter;
    }
}

@mixin list-related-divider() { 
	li:after {
		padding-left: $space-xs;
		content: "|";		
	}
	li:last-child:after {
		content: "";		
	}
}

@mixin list-quicklinks() {
	padding: 0;
	margin: 0;
	margin-bottom: 15px;
    line-height: 24px;

	.title {
		text-transform: uppercase;
		margin-bottom: 15px;
		font-size: 14px;
        color: $color-white;
        display: block;
		&:before {
			content: "";			
		}
	}

	li {
		list-style: none;
		padding-left: 6px;
		font-size: 14px;	

		&:before {
			position: relative;
			content: "-";
			left: -5px;
            color: $color-brand-2;
		}
	}

	a {
		color: $color-off-white;			
		&:hover { 
			color: white;
		}
	}
}