/*------------------------------------*\
    $TK-BUTTONS
\*------------------------------------*/

/**
 * Buttons
 * TODO: Refactor buttons into seperate partial and create mixin to include in forms
 */

@mixin button(){
	@include type-all-caps();
 	display: inline-block;
    vertical-align: middle;
    white-space: nowrap;
    font-family: inherit;
    font-size: 100%;
    cursor: pointer;
    margin-bottom: $space-half;
    min-height: 40px;
    border-radius: 0px;
    box-shadow: 0 1px 2px rgba(0,0,0,.1);
	padding: 8px 12px;	//TODO: remove to allow for smaller bs button but fix the rest

	@media(min-width: $screen-xs-min){
		font-size: 16px;
	}
}

button {
    border-radius: 0; //Chrome 62
}

.btn {
	@include button();
}

.btn-block {
    display: block;
    width: 100%;
}


/**
 * Search button
 * On small screens it turns to a looking glass icon
 * e.g. Library banner search,
 * TODO: add to other searches in the side
 */

.btn-search,
.btn-login {
	width: 100%;
	@media(max-width: $screen-xs-min){
		text-indent: -9999px;
		font-size: 20px;
		position: relative;
		display: block;
		&:before {
			@include tk-icon();
			text-indent: 0px;
			text-align: center;
		    font-size: 24px !important;
		    position: absolute;
		    left: 50%;
		    top: 50%;
		    margin-left: -12px;
		    margin-top: -12px;
		}
	}
}

.btn-search {
	@media(max-width: $screen-xs-min){
		&:before {
			@include tk-icon-action-search();
		}
	}
}

.btn-login {
	@media(max-width: $screen-xs-min){
		&:before {
			@include tk-icon-content-user();
		}
	}
}

/**
 * Buttons colors
 */

.btn-primary {
	background: $color-masthead;
	&:hover {
		background: $color-masthead-light;
	}
}

.btn-brand-2 {
	border: 1px solid $color-brand-2-dark;
	background: $color-brand-2;
	color: white;
	&:hover {
		color: white;
		background: $color-brand-2-dark;
	}
}

/**
 * Buttons size
 * Overwrides Bootstrap
 * e.g. Library banner
 * TODO: Fix to better work with BS large buttons
 */

.btn-sm {
    min-height: auto;
    padding: 3px 6px;
    font-size: 14px;
}

.btn-lg {
	@media (min-width: $screen-sm-min) {
		min-height: 50px;
	}
}

.btn-lg {
	@media (min-width: $screen-sm-min) {
		min-height: 50px;
	}
}

.btn-full-width {
	width: 100%;
	display: block;
}

/**
 * Button icons
 */

.btn-icon { //Used when a button contains an icon font
	border: 0;
	background: none;
	border-radius: 0;
	padding: 3px 6px;
}

/**
 * ie9 bug fix for BS btn-group
 */

.lt-ie9 .btn-group {
	display: inline;
}
/**
 * Filter buttons
 */

// Used on faculy people
.btn-filter {
    background: none;
    border: none;
    margin: 0 !important;
    padding: 0 !important;
    &:focus {
        @include focus-outline;
    }

    .no-outline & {
        @include focus-no-outline;
    }

    &:after {
        @include tk-icon();
        @include tk-icon-nav-chevron-down();
        vertical-align: middle;
        font-size: 24px;
    }
    .btn-group.open & {
        box-shadow: none;
        color: $color-font;
        &:after {
            @include tk-icon-nav-chevron-up();
        }
    }
}

