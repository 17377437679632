/**
 * Icon font CSS generated by gulp see templates -> _tk-icons.scss
 * Using lodash https://lodash.com/docs/4.17.4
 */

@import "toolkit/foundations/tk-icons-mixins"; //custom toolkit icons, file generated by gulp

@font-face {
    font-family: "tk-icons";
    src: url('../fonts/tk-icons.eot');
    src: url('../fonts/tk-icons.eot?#iefix') format('eot'),
        url('../fonts/tk-icons.woff2') format('woff2'),
        url('../fonts/tk-icons.woff') format('woff'),
        url('../fonts/tk-icons.ttf') format('truetype'),
        url('../fonts/tk-icons.svg#tk-icons') format('svg');
    font-weight: normal;
    font-style: normal;
}

.tk-icon,
//.tk-icon:before,
[class*="tk-icon"] {
    @include tk-icon;
}

.tk-icon-16 {
    @include tk-icon-16();
}

.tk-icon-24 {
    @include tk-icon-24();
}

.tk-icon-32 {
    @include tk-icon-32();
}

.tk-icon-48 {
    @include tk-icon-48();
}

/**
 * Icon font class names
 */

.tk-icon-add:before { 
    @include tk-icon-add();    
}
.tk-icon-alert-danger:before { 
    @include tk-icon-alert-danger();    
}
.tk-icon-alert-info:before { 
    @include tk-icon-alert-info();    
}
.tk-icon-alert-success:before { 
    @include tk-icon-alert-success();    
}
.tk-icon-alert-warning:before { 
    @include tk-icon-alert-warning();    
}
.tk-icon-calendar:before { 
    @include tk-icon-calendar();    
}
.tk-icon-campus-map:before { 
    @include tk-icon-campus-map();    
}
.tk-icon-chevron-down:before { 
    @include tk-icon-chevron-down();    
}
.tk-icon-chevron-left:before { 
    @include tk-icon-chevron-left();    
}
.tk-icon-chevron-right:before { 
    @include tk-icon-chevron-right();    
}
.tk-icon-chevron-up:before { 
    @include tk-icon-chevron-up();    
}
.tk-icon-close:before { 
    @include tk-icon-close();    
}
.tk-icon-download:before { 
    @include tk-icon-download();    
}
.tk-icon-external:before { 
    @include tk-icon-external();    
}
.tk-icon-home:before { 
    @include tk-icon-home();    
}
.tk-icon-letter:before { 
    @include tk-icon-letter();    
}
.tk-icon-mail:before { 
    @include tk-icon-mail();    
}
.tk-icon-marker:before { 
    @include tk-icon-marker();    
}
.tk-icon-navicon:before { 
    @include tk-icon-navicon();    
}
.tk-icon-news:before { 
    @include tk-icon-news();    
}
.tk-icon-phone:before { 
    @include tk-icon-phone();    
}
.tk-icon-print:before { 
    @include tk-icon-print();    
}
.tk-icon-profile:before { 
    @include tk-icon-profile();    
}
.tk-icon-remove:before { 
    @include tk-icon-remove();    
}
.tk-icon-rss:before { 
    @include tk-icon-rss();    
}
.tk-icon-search:before { 
    @include tk-icon-search();    
}
.tk-icon-share:before { 
    @include tk-icon-share();    
}
.tk-icon-social-facebook:before { 
    @include tk-icon-social-facebook();    
}
.tk-icon-social-google:before { 
    @include tk-icon-social-google();    
}
.tk-icon-social-instagram:before { 
    @include tk-icon-social-instagram();    
}
.tk-icon-social-linkedin:before { 
    @include tk-icon-social-linkedin();    
}
.tk-icon-social-twitter:before { 
    @include tk-icon-social-twitter();    
}
.tk-icon-social-weibo:before { 
    @include tk-icon-social-weibo();    
}
.tk-icon-social-youtube:before { 
    @include tk-icon-social-youtube();    
}
.tk-icon-sort:before { 
    @include tk-icon-sort();    
}
.tk-icon-star:before { 
    @include tk-icon-star();    
}
.tk-icon-triangle-down:before { 
    @include tk-icon-triangle-down();    
}
.tk-icon-triangle-left:before { 
    @include tk-icon-triangle-left();    
}
.tk-icon-triangle-right:before { 
    @include tk-icon-triangle-right();    
}
.tk-icon-triangle-up:before { 
    @include tk-icon-triangle-up();    
}
.tk-icon-action-add:before { 
    @include tk-icon-action-add();    
}
.tk-icon-action-bookmark:before { 
    @include tk-icon-action-bookmark();    
}
.tk-icon-action-call:before { 
    @include tk-icon-action-call();    
}
.tk-icon-action-cancel:before { 
    @include tk-icon-action-cancel();    
}
.tk-icon-action-create:before { 
    @include tk-icon-action-create();    
}
.tk-icon-action-delete:before { 
    @include tk-icon-action-delete();    
}
.tk-icon-action-download:before { 
    @include tk-icon-action-download();    
}
.tk-icon-action-link-external:before { 
    @include tk-icon-action-link-external();    
}
.tk-icon-action-lock:before { 
    @include tk-icon-action-lock();    
}
.tk-icon-action-pause:before { 
    @include tk-icon-action-pause();    
}
.tk-icon-action-play:before { 
    @include tk-icon-action-play();    
}
.tk-icon-action-print:before { 
    @include tk-icon-action-print();    
}
.tk-icon-action-remove:before { 
    @include tk-icon-action-remove();    
}
.tk-icon-action-reply:before { 
    @include tk-icon-action-reply();    
}
.tk-icon-action-search:before { 
    @include tk-icon-action-search();    
}
.tk-icon-action-upload:before { 
    @include tk-icon-action-upload();    
}
.tk-icon-content-bar:before { 
    @include tk-icon-content-bar();    
}
.tk-icon-content-basket:before { 
    @include tk-icon-content-basket();    
}
.tk-icon-content-bbq:before { 
    @include tk-icon-content-bbq();    
}
.tk-icon-content-bed:before { 
    @include tk-icon-content-bed();    
}
.tk-icon-content-bike:before { 
    @include tk-icon-content-bike();    
}
.tk-icon-content-bus:before { 
    @include tk-icon-content-bus();    
}
.tk-icon-content-cafe:before { 
    @include tk-icon-content-cafe();    
}
.tk-icon-content-calendar:before { 
    @include tk-icon-content-calendar();    
}
.tk-icon-content-couch:before { 
    @include tk-icon-content-couch();    
}
.tk-icon-content-email-open:before { 
    @include tk-icon-content-email-open();    
}
.tk-icon-content-email:before { 
    @include tk-icon-content-email();    
}
.tk-icon-content-fitness:before { 
    @include tk-icon-content-fitness();    
}
.tk-icon-content-home:before { 
    @include tk-icon-content-home();    
}
.tk-icon-content-hospital:before { 
    @include tk-icon-content-hospital();    
}
.tk-icon-content-key:before { 
    @include tk-icon-content-key();    
}
.tk-icon-content-location:before { 
    @include tk-icon-content-location();    
}
.tk-icon-content-map:before { 
    @include tk-icon-content-map();    
}
.tk-icon-content-music:before { 
    @include tk-icon-content-music();    
}
.tk-icon-content-news:before { 
    @include tk-icon-content-news();    
}
.tk-icon-content-parking:before { 
    @include tk-icon-content-parking();    
}
.tk-icon-content-restaurant:before { 
    @include tk-icon-content-restaurant();    
}
.tk-icon-content-rss:before { 
    @include tk-icon-content-rss();    
}
.tk-icon-content-shower:before { 
    @include tk-icon-content-shower();    
}
.tk-icon-content-table-tennis:before { 
    @include tk-icon-content-table-tennis();    
}
.tk-icon-content-tag:before { 
    @include tk-icon-content-tag();    
}
.tk-icon-content-train:before { 
    @include tk-icon-content-train();    
}
.tk-icon-content-tv:before { 
    @include tk-icon-content-tv();    
}
.tk-icon-content-user:before { 
    @include tk-icon-content-user();    
}
.tk-icon-content-videogame:before { 
    @include tk-icon-content-videogame();    
}
.tk-icon-content-wc:before { 
    @include tk-icon-content-wc();    
}
.tk-icon-content-wifi:before { 
    @include tk-icon-content-wifi();    
}
.tk-icon-device-desktop:before { 
    @include tk-icon-device-desktop();    
}
.tk-icon-device-laptop:before { 
    @include tk-icon-device-laptop();    
}
.tk-icon-device-phone:before { 
    @include tk-icon-device-phone();    
}
.tk-icon-form-check-checked:before { 
    @include tk-icon-form-check-checked();    
}
.tk-icon-form-check-unchecked:before { 
    @include tk-icon-form-check-unchecked();    
}
.tk-icon-form-radio-checked:before { 
    @include tk-icon-form-radio-checked();    
}
.tk-icon-form-radio-unchecked:before { 
    @include tk-icon-form-radio-unchecked();    
}
.tk-icon-nav-arrow-dropdown-down:before { 
    @include tk-icon-nav-arrow-dropdown-down();    
}
.tk-icon-nav-arrow-dropdown-up:before { 
    @include tk-icon-nav-arrow-dropdown-up();    
}
.tk-icon-nav-chevron-down:before { 
    @include tk-icon-nav-chevron-down();    
}
.tk-icon-nav-chevron-left:before { 
    @include tk-icon-nav-chevron-left();    
}
.tk-icon-nav-chevron-right:before { 
    @include tk-icon-nav-chevron-right();    
}
.tk-icon-nav-chevron-up:before { 
    @include tk-icon-nav-chevron-up();    
}
.tk-icon-nav-more-horizontal:before { 
    @include tk-icon-nav-more-horizontal();    
}
.tk-icon-nav-more-vertical:before { 
    @include tk-icon-nav-more-vertical();    
}
.tk-icon-nav-navicon:before { 
    @include tk-icon-nav-navicon();    
}
.tk-icon-nav-select:before { 
    @include tk-icon-nav-select();    
}
.tk-icon-content-vr:before { 
    @include tk-icon-content-vr();    
}
.tk-icon-device-tablet:before { 
    @include tk-icon-device-tablet();    
}
.tk-icon-nav-chevron-left-large:before { 
    @include tk-icon-nav-chevron-left-large();    
}
.tk-icon-nav-chevron-right-large:before { 
    @include tk-icon-nav-chevron-right-large();    
}
.tk-icon-nav-chevron-down-large:before { 
    @include tk-icon-nav-chevron-down-large();    
}
.tk-icon-nav-chevron-up-large:before { 
    @include tk-icon-nav-chevron-up-large();    
}
.tk-icon-content-washing-machine:before { 
    @include tk-icon-content-washing-machine();    
}
.tk-icon-content-credit-card:before { 
    @include tk-icon-content-credit-card();    
}
.tk-icon-content-sport:before { 
    @include tk-icon-content-sport();    
}
.tk-icon-content-security:before { 
    @include tk-icon-content-security();    
}
.tk-icon-content-build:before { 
    @include tk-icon-content-build();    
}
.tk-icon-content-document:before { 
    @include tk-icon-content-document();    
}
.tk-icon-action-filter:before { 
    @include tk-icon-action-filter();    
}
.tk-icon-action-card:before { 
    @include tk-icon-action-card();    
}
.tk-icon-action-list:before { 
    @include tk-icon-action-list();    
}
.tk-icon-action-cancel-thin:before { 
    @include tk-icon-action-cancel-thin();    
}
.tk-icon-action-share-circle:before { 
    @include tk-icon-action-share-circle();    
}
.tk-icon-action-play-circle-outline:before { 
    @include tk-icon-action-play-circle-outline();    
}
.tk-icon-content-announcement:before { 
    @include tk-icon-content-announcement();    
}
.tk-icon-nav-dots-horizontal:before { 
    @include tk-icon-nav-dots-horizontal();    
}
.tk-icon-content-marker:before { 
    @include tk-icon-content-marker();    
}
.tk-icon-content-security-home:before { 
    @include tk-icon-content-security-home();    
}
.tk-icon-content-ping-pong:before { 
    @include tk-icon-content-ping-pong();    
}
.tk-icon-content-bank:before { 
    @include tk-icon-content-bank();    
}



/* Hidden font text for accessibility */

.icon-font {}

.icon-font-text {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
}