/*

    MULTILINK WIDGET

*/
.wrapper--multilink {
    margin: 0 auto;
}

.multilink {
    margin-bottom: 32px;
    .lead {
        font-family: $font-family-big;
        letter-spacing: .5px;
        text-align: center;
    }
    .link-area {
        margin-top: 24px;
        .desktop-links {
            @media( max-width: $screen-sm - 1 ) {
                display: block;
            }
            @media( min-width: $screen-sm ) {
                display: none;
            }
            @media( min-width: $screen-md ) {
                display: block;
            }
        }
        .tablet-links {
            text-align: center;
            @media( max-width: $screen-sm - 1 ) {
                display: none;
            }
            @media( min-width: $screen-sm ) {
                display: block;
            }
            @media( min-width: $screen-md ) {
                display: none;
            }
        }
    }
    .gallery-area.open {
        background-color: $body-bg;
        z-index: 99999;
        position: fixed;
        height: 100vh;
        width: 100vw;
        top:0;
        left: 0;
    }
    .links {
        text-transform: uppercase;
        list-style-type: none;
        padding: 0;
        margin-left: 0;
        @media( min-width: $screen-sm ) and ( max-width: $screen-md ) {
            margin-bottom: 24px;
        }
        li {
            padding: 4px 0;
        }
        a {
            color: $text-color;
            text-decoration: none;
            border-bottom: 0;
            &:hover {
                color: $color-brand-2;
            }
            &:after {
                font-family: "tk-icons" !important;
                speak: none;
                font-style: normal;
                font-variant: normal;
                font-weight: normal;
                text-decoration: none;
                text-transform: none;
                -ms-transform: translate(0px, -1px);
                transform: translate(0px, -1px);
                line-height: 1;
                font-size: inherit;
                display: inline-block;
                vertical-align: middle;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                content: "\EA0A";
                margin-left: 5px;
                margin-bottom: 2px;
                position: relative;
            }
        }
    }
    .link-section {
        height: 0;
        opacity: 0;
        transition: .5s ease all;
        overflow: hidden;
        @media( max-width: $screen-sm - 1 ) {
            position: fixed;
            height: 100vh;
            width: 100%;
            z-index: -1;
            top: 0;
            left: 0;
            background-color: #fff;
            border: 16px solid #aaa;
        }
        &:last-child .next-link {
            display: none;
        }
        &.active {
            height: auto;
            opacity: 1;
            @media( max-width: $screen-sm - 1 ) {
                z-index: 9999999;
                height: 100vh;
            }
        }
        h1,h2,h3,h4,h5,h6 {
            @media( max-width: $screen-sm - 1 ) {
                margin-top: 0;
            }
        }
        div[class^="rule-img"] {
            @media( max-width: $screen-sm - 1 ) {
                margin-bottom: 0;
            }
        }
        h1,h2,h3,h4,h5,h6, div[class^="rule-img"], .close, .next-link {
            display: block;
            @media( min-width: $screen-sm ) {
                display: none;
            }
        }
        .close {
            position: absolute;
            top: 0;
            right: 4px;
            padding: 4px;
            color: $text-color;
            border: 0;
            z-index: 99999999;
            opacity: 1;
        }
        .next-link {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            padding-bottom: 6px;
            hr {
                background-color: $text-color;
                margin-top: 4px;
                margin-bottom: 4px;
            }
            a {
                text-transform: uppercase;
                color: $text-color;
                font-size: 16px;
                border: 0;
                padding: 4px;
                display: block;
                &:hover {
                    text-decoration: none;
                }
            }
        }
        .link-excerpt {
            font-size: 18px;
            margin-bottom: 12px;
            margin-top: 12px;
            @media( max-width: $screen-sm - 1 ) {
                padding-left: 8px;
                padding-right: 8px;
            }
        }
        a > img {
            border: 0;
            padding: 0;
            width: 100%;
            height: auto;
            backface-visibility: visible;
            @media( max-width: $screen-sm - 1 ) {
                padding-left: 8px;
                padding-right: 8px;
            }
        }
        .more {
            @media( max-width: $screen-sm - 1 ) {
                margin-left: 8px;
            }
        }
        @media( min-width: $screen-sm - 1) and (max-width: $screen-md ) {
            padding: 0 15%;
        }
    }
}

.skin-bg-dark {
    .multilink {
        @media( max-width: $screen-md ) {
            padding: 0 10px;
        }
        .gallery-area.open {
            color: $text-color;
            .more {
                color: $text-color;
                &:hover {
                    color: $color-brand-2;
                }
            }
        }
        .links {
            a {
                color: white;
                &:hover {
                    color: $color-brand-2;
                }
            }
        }
    }
}