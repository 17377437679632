/* 
Ekkolightbox styles custmomised */
/*

<div class="ekko-lightbox modal fade in show">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
                <h4 class="modal-title">image title</h4>
            </div>
            <div class="modal-body">
                <div class="ekko-lightbox-container">
                    <div class="ekko-lightbox-item fade in show">
                        //Media goes here
                    </div>
                    <div class="ekko-lightbox-item fade">
                        //Alt conatiner as holding wrapper
                    </div>
                    <div class="ekko-lightbox-nav-overlay"><a href="#"><span class="left-arrow"></span></a><a href="#"><span class="right-arrow"></span></a></div>
                </div>
            </div>
            <div class="modal-footer">4 / 9</div>
        </div>
    </div>
</div>

*/

//Whole container
.ekko-lightbox {
    //wraps media inside in .modal-body
    .ekko-lightbox-container {
        position: relative;

        > div.ekko-lightbox-item {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            &.in {
                z-index: 10;
            }
        }
    }

    .modal-title {
        margin-top: 2px;
        @media(max-width: 700px) {
            margin-top: 4px;
        }
    }

    iframe {
        width: 100%;
        height: 100%;
    }

    //Left and right arrow
    .ekko-lightbox-nav-overlay {
        a {
            display: block;
            width: 50px;
            height: 50px;
            background: $color-masthead;
            position: absolute;
            z-index: 10;
            top: 50%;
            margin-top: -25px;
            color: white;

            span {
                color: white;
                @extend .tk-icon;
                @extend .tk-icon-32;
                display: block;
                padding: 10px 4px;
                &:before {
                    @extend .tk-icon-nav-chevron-right-large:before;
                }
            }

            &:first-child {
                left: 0px;
                border-right: 5px solid $color-brand-2;
                @media (min-width: $screen-lg-min) {
                    left: -45px;
                }

                span:before {
                    @extend .tk-icon-nav-chevron-left-large:before;
                }
            }

            &:last-child {
                right: 0px;
                text-align: right;
                border-left: 5px solid $color-brand-2;
                @media (min-width: $screen-lg-min) {
                    right: -45px;
                }
                span:before {
                    @extend .tk-icon-nav-chevron-right-large:before;
                }
            }

            &:hover,
            &:focus {
                text-decoration: none;
                outline: none;
            }
        }
    }

    a:hover {
        opacity: 1;
        text-decoration: none;
    }

    /* Modal */

    &.modal {
        padding: 0;
    }

    & + .modal-backdrop.in {
        opacity: 0.8;
    }

    .modal {
        &-dialog {
        }
        &-content {
            background: none;
            box-shadow: none;
            border: 0;
        }
        &-header {
            border: 0;
            padding: 0 0 $space-sm 0;
            .close {
                //close button
                color: white;
                opacity: 1;
                box-shadow: none;
                text-shadow: none;
                span {
                    display: none;
                }
                &:after {
                    @extend .tk-icon;
                    @extend .tk-icon-32;
                    @extend .tk-icon-action-cancel:before;
                    position: relative;
                    top: 2px;
                    left: 2px;
                }
            }
        }
        &-title {
            color: white;
        }
        &-body {
            padding: 0;
        }

        &-footer {
            text-align: left;
            color: #fff;
            border-top: 0;
            font-size: 18px;
        }
    }
    .modal-info-btn {
        position: absolute;
        color: white;
        z-index: 11;
        right: -40px;
        top: 0px;
        width: 32px;
        height: 32px;
        font-size: 32px;
        line-height: 0;

        @media (min-width: $screen-sm-min) {
            right: -55px;
            width: 48px;
            height: 48px;
            font-size: 48px;
        }

        &:after {
            text-indent: 0px;
            @extend .tk-icon;
            @extend .tk-icon-alert-info:before;
            text-decoration: none;
        }
        &:hover,
        &:focus {
            color: $color-brand-2;
            text-decoration: none;
            border: 0;
        }
    }

    .modal-share {
        position: absolute;
        right: -40px;
        bottom: 0px;
        line-height: 0;
        @media (min-width: $screen-sm-min) {
            right: -55px;
        }

        .modal-share-btn {
            display: block;
            color: white;
            font-size: 32px;
            display: inline-block;
            vertical-align: middle;

            @media (min-width: $screen-sm-min) {
                font-size: 48px;
            }

            &:after {
                text-indent: 0px;
                @extend .tk-icon;
                @extend .tk-icon-action-share-circle:before;
                text-decoration: none;
            }
        }

        a.social {
            color: white;
            font-size: 18px;
            padding: 0 5px;
            display: none;
            vertical-align: middle;
            opacity: 0;
            transform: translate(10px, 0);
            transition: opacity 0.5s ease;
        }

        &.active {
            a.social {
                opacity: 1;
                transform: translate(0, 0);
                display: inline-block;
            }
        }
    }
}

// http://tobiasahlin.com/spinkit/
.ekko-lightbox-loader {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    display: flex; /* establish flex container */
    flex-direction: column; /* make main axis vertical */
    justify-content: center; /* center items vertically, in this case */
    align-items: center;

    > div {
        width: 40px;
        height: 40px;

        position: relative;
        text-align: center;

        > div {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background-color: #fff;
            opacity: 0.6;
            position: absolute;
            top: 0;
            left: 0;

            animation: sk-bounce 2s infinite ease-in-out;
            &:last-child {
                animation-delay: -1s;
            }
        }
    }

    > div > div {
        background-color: #222;
    }
}

@-webkit-keyframes sk-bounce {
    0%,
    100% {
        -webkit-transform: scale(0);
    }
    50% {
        -webkit-transform: scale(1);
    }
}

@keyframes sk-bounce {
    0%,
    100% {
        transform: scale(0);
        -webkit-transform: scale(0);
    }
    50% {
        transform: scale(1);
        -webkit-transform: scale(1);
    }
}

/*
    Add dark mask and over styles to the ekko lightbox
    used on library
*/

.ekko-lightbox-default {
    //Hack to prevent jump of image in transition

    .fade {
        display: none;
    }

    .fade.in {
        display: block;
    }

    //modal styles

    .modal {
        &-dialog {
            width: auto !important;
            margin: 0 auto;
            //border: 1px solid red;
        }

        &-header {
            padding: 10px;
            @media (min-width: $screen-md-min) {
                padding: 10px 0;
            }
        }

        &-body {
            padding: 0;
        }

        &-footer {
            padding-top: 30px;

            .heading-related {
                color: white;
            }
        }

        &-pagination {
            position: absolute;
            right: 20px;
            bottom: 20px;
            height: 32px;
            color: white;
            z-index: 10;
        }
    }

    & + .modal-backdrop.in {
        opacity: 1;
    }

    .modal-loaded {
        padding: 10px;
    }
}

/**
 * Custom modal
 */

$galleryFooterHeight: 60px;

.ekko-custom {
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;

    transition: all 0.25 ease;

    //Hack to prevent jump of image in transition

    .fade {
        display: none;
    }

    .fade.in {
        display: block;
    }

    .modal-header {
        display: none;
    }

    &.ekko-sidebar-active {
        .modal-header {
            padding: 10px;
        }
    }

    .modal-dialog {
        flex-grow: 1 !important;
        flex-shrink: 1 !important;
        flex-basis: auto !important; //
        width: 100%; //ie11 flex bug
        margin: 0;
        margin-top: 20px !important; //room for footer, maring left and right break ie11 flex
        margin-bottom: 50px !important;
        padding-left: 60px;
        padding-right: 60px;

        .ekko-lightbox-container {
            //overwrite jquery setting height
            height: auto !important;
        }

        .ekko-lightbox-container > div.ekko-lightbox-item.in {
            position: static; //overwrite absolute for verticla centering
        }
    }

    .modal-custom-sidebar {
        position: absolute;
        top: 0;
        right: 0;
        
        background: rgba($color-masthead-dark, 0.95);
        @media (min-width: 700px) {
            background: $color-masthead-dark;
        }
        width: 0;
        height: 100%;
        transition: all 0.5s ease;
        overflow-y: scroll;
    }

    &.ekko-sidebar-active {
        @media (min-width: 700px) {
            padding-right: 50% !important; //fixes weird chrome bug //TODO:
        }
        @media (min-width: 800px) {
            padding-right: 33.33% !important; //fixes weird chrome bug //TODO:
        }
        .modal-custom-sidebar {
            z-index: 9999;
            @media (max-width: 699px) {
                width: 100%;
                height: 100%;
            }
            @media (min-width: 700px) {
                width: 50%;
                min-width: 50%;
            }
            @media (min-width: 800px) {
                width: 33.33%;
                min-width: 33.33%;
            }
        }
    }

    .modal-custom-sidebar-header {
        overflow: hidden;
        @media (min-width: 700px) {
            display: none;
        }

        a {
            display: block;
            float: right;
            color: white;
            font-size: 32px;
            padding: 8px 7px 0 0;
        }
    }

    .modal-custom-sidebar-inner {
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: $galleryFooterHeight + 20px;
        color: white;
        opacity: 0;
    }

    &.ekko-sidebar-active {
        .modal-custom-sidebar-inner {
            opacity: 1;
            transition: opacity 0.5s ease;
            transition-delay: 0.5s;
        }
    }

    .modal-content {
        background: none;
        box-shadow: none;
        border: 0;
    }

    .modal-body {
        margin: 0;

        //border: 1px solid red;
    }

    .modal-pagination {
        //border: 1px solid red;
        position: absolute;
        right: 0px;
        bottom: -30px;
        display: block;
        color: white;
        z-index: 10;
    }

    //custom nav implemented
    .ekko-lightbox-nav-overlay {
        display: none !important;
    }
}

.ekko-custom + .modal-backdrop.in {
    opacity: 1;
}

//Custom nav left and right outside of modal

.modal-custom-nav {
    button {
        @include owl-button();
        position: absolute;
        background: $color-masthead;
        z-index: 1000;
        top: 50%;
        margin-top: -50px;

        &:hover,
        &:focus {
            text-decoration: none;
            outline: none;
        }
    }
    .prev {
        @include owl-button-prev();
        @include owl-button-prev-lg();
        left: 0px;
    }
    .next {
        @include owl-button-next();
        @include owl-button-next-lg();
        right: 0px;
    }

    .ekko-lightbox.ekko-sidebar-active & .next {
        @media (min-width: 700px) {
            right: 50%;
        }
        @media (min-width: 800px) {
            right: 33.33%;
        }
    }
}

.modal-custom-header {
    position: absolute;
    z-index: 10000;
    width: 100%;
    top: 0;
    left: 0;
    padding: 5px;

    @media( max-width: 700px ) {
        z-index: 9999;
    }

    button {
        position: absolute;
        right: 7px;
        top: 8px;
        float: none;
        color: white;
        opacity: 1;
        text-shadow: none;
        &:hover {
            color: white;
        }
    }
}

.ekko-sidebar-active {
    .modal-custom-header button {
        right: calc(33.33% + 6px);
        @media(max-width: 800px) {
            right: calc(50% + 6px);
        }
        @media(max-width: 700px) {
            right: 6px;
        }
    }
}

//Custom footer with thumbnails
.modal-custom-footer {
    position: absolute;
    z-index: 10000;
    width: 100%;
    bottom: 0;
    left: 0;
    height: $galleryFooterHeight;

    .swiper-ekko .item {
        height: $galleryFooterHeight;
        list-style: none;
        min-width: 80px;
        position: relative;

        .tk-icon {
            position: absolute;
            left: 50%;
            top: 50%;
            margin-left: -12px;
            margin-top: -12px;
        }

        &.active {
            background: #777;
        }

        a {
            background: #222;
            border-right: 1px solid #444;
            display: block;
            height: 100%;
            width: 100%;
            text-align: center;
            color: white;
            font-size: 32px;
            &.active {
                background: #333;
            }
            &:hover {
                background: #333;
            }
        }

        img {
            padding: 8px;
            max-width: 100%;
            max-height: 100%;
            width: auto;
            display: inline-block;
            vertical-align: middle;
            margin: 0 auto;
        }
    }
}
