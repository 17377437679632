.flag {
    @extend .clearfix;
    width: 100%;
    padding: 0 0 $space-sm 0;
    margin-bottom: $space-sm;
    border-bottom: 1px solid $color-border;

    @media (min-width: $screen-xs-min) {
        padding: 0 0 $space 0;
        margin-bottom: $space;
    }

    .flag-img {
        float: left;
        width: 33.33%;
        padding-right: 10px;
        padding-bottom: 3px;

        @media (min-width: $screen-xs-min) {
            padding-right: $space;
            padding-bottom: $space-half/2;
        }
    }

    .flag-img-1-2 {
        width: 33.33%;
        @media (min-width: $screen-sm-min) {
            width: 50%;
        }
    }

    .flag-img-1-3 {
        width: 33.33%;
    }

    .flag-img-1-4 {
        width: 33.33%;
        @media (min-width: $screen-sm-min) {
            width: 25%;
        }
    }

    .flag-body {
    }

    .flag-body-no-wrap {
        overflow: hidden;
    }
}

.flag-featured {
    //we dont use _skins.scss because we want more control
    @include skin-box-module-border();
    @include border-left-brand-2();

    padding-top: $space-sm;
    padding-right: $space-sm;
    padding-left: $space-sm;

    @media (min-width: $screen-xs-min) {
        padding-top: $space;
        padding-right: $space;
        padding-left: $space;
    }
}
