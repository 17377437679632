/**
 * Homepage blog widget
 */

.tk-widget-blog {
    position: relative;

    @media (max-width: 767px) {
        padding-bottom: 7rem;
    }

    .blog-entry {
        position: relative;
        overflow: auto;
        padding: 0 0 32px;
        margin: 4px 0 -4px;



        &:before {
            content: '';
            display: block;
            position: absolute;
            background: $color-brand-2;
            height: 1px;
            top: 0;
            left: 0;
            right: 0;
        }
    }
        .blog-entry--image {
            padding-top: calc(56% + 9px);

            @media (min-width: $screen-sm) {
                padding-top: 5px;
            }
        }

        .blog-entry__title {
            margin-bottom: 8px;
            padding-top: 4px;
            font-family: $font-family-big;
            
            a {
                color: inherit;
            }
        }

        .blog-entry__image {
            display: block;
            width: 100%;
            padding-bottom: 56%;
            background: 50% / cover;
            position: absolute;
            top: 4px;
            right: 0;

            @media (min-width: $screen-sm) {
                width: 40%;
                padding-bottom: calc(56% / 5 * 2);
                position: relative;
                right: auto;
                float: left;
                margin-right: 16px
            }

            @media (min-width: $screen-md) {
                width: calc(50% - 8px);
                padding-bottom: 28%; 
            }
        }

        .blog-entry__date {
            font-family: $font-family-sans-serif;
            font-size: 12px;
            text-transform: uppercase;
            display: block;
            margin-bottom: 8px;
        }

        .blog-entry__summary {
            font-family: $font-family-sans-serif;
            font-size: 16px;
        }

            @media (min-width: $screen-sm) {
                .blog-entry--image {

                    .blog-entry__title,
                    .blog-entry__summary,
                    .blog-entry__date,
                    .blog-entry__more-container {
                        width: calc(60% - 16px);
                        float: right;
                    }

                }
            }

            @media (min-width: $screen-md) {
                .blog-entry--image {

                    .blog-entry__title,
                    .blog-entry__summary,
                    .blog-entry__date,
                    .blog-entry__more-container {
                        width: calc(50% - 8px);
                        clear: right;
                    }

                }
            }
}

/**
 * Blog index
 */
.tk-index-blog {

    position: relative;
    margin-bottom: 20px;
    padding-top: 1px;

    &:before {
        content: '';
        display: block;
        position: absolute;
        background: $color-brand-2;
        height: 1px;
        top: 0;
        left: 0;
        right: 0;
    }

    .blog-entry {
        position: relative;
        overflow: auto;
        transition: background-color .3s ease-in-out;


        &:after {
            content: '';
            display: block;
            position: absolute;
            background: $color-brand-2;
            height: 1px;
            bottom: 0;
            left: 0;
            right: 0;
        }

    }

        .blog-entry--image {
            padding-top: calc(47% + 18px);

            @media (min-width: $screen-sm) {
                padding-top: 5px;
            }
        }

        @media (min-width: $screen-sm) {
            .blog-entry--image {

                .blog-entry__title,
                .blog-entry__author,
                .blog-entry__date,
                .blog-entry__categories {
                    width: calc(100% / 3 * 2 - 8px);
                    float: right;
                    clear: right;
                }

            }
        }

        .blog-entry__title {
            margin-bottom: 12px;
            padding-top: 4px;
            font-family: $font-family-big;

            @media (min-width: $screen-sm) {
                margin-top: 10px;
            }
        }


        .blog-entry__image {
            display: block;
            width: 80%;
            padding-bottom: 47%;
            background: 50% / cover;
            position: absolute;
            top: 16px;
            left: 0;

            @media (min-width: $screen-sm) {
                width: calc(100% / 3 - 8px);
                padding-bottom: 18%;
                position: relative;
                top: auto;
                float: left;
                margin: 12px 16px 16px 0;
            }

        }

        .blog-entry__date {
            font-family: $font-family-sans-serif;
            font-weight: 600;
            font-size: 14px;
            text-transform: uppercase;
            display: block;
            margin-bottom: 8px;

            @media (min-width: $screen-sm) {
                font-size: 16px;
            }
        }

        .blog-entry__author {
            font-family: $font-family-sans-serif;
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 14px;

            @media (min-width: $screen-sm) {
                font-size: 16px;
            }
        }

        .blog-entry__categories {

        }

            .blog-entry__categories__item {
                display: inline;
                font-family: $font-family-sans-serif;
                font-size: 14px;

                @media (min-width: $screen-sm) {
                    font-size: 16px;
                }

                &:after {
                    content: ', ';
                }

                &:last-child {

                    &:after {
                        content: '';
                    }
                }
            }

}

.tk-item-blog {
    margin-bottom: 30px;
    
    .blog-entry__title {
        font-family: $font-family-big;
        font-size: 20px;
        text-align: center;
        padding-top: 18px;
        margin-bottom: 8px;

        @media (min-width: $screen-sm) {
            font-size: 28px;
            line-height: 1.14;
        }

        @media (min-width: $screen-sm) {
            font-size: 32px;
        }
    }

    .blog-entry__categories {
        text-align: center;
        margin-bottom: 18px;
    }

        .blog-entry__categories__item {
            display: inline;
            font-family: $font-family-big;
            font-size: 18px;
            color: $color-brand-2;

            @media (min-width: $screen-sm) {
                font-size: 24px;
                line-height: normal;
            }

            &:after {
                content: '';
                display: inline-block;
                height: .8em;
                width: 1px;
                background-color: $color-font;
                margin: 0 .4em -.1em;;
            }

            &:last-child {

                &:after {
                    content: none;
                }
            }
        }

        .blog-entry__date,
        .blog-entry__author {
            display: block;
            margin-bottom: 8px;
            text-align: center;
            font-family: $font-family-sans-serif;
            font-size: 14px;
            font-weight: 600;

            @media (min-width: $screen-sm) {
                font-size: 16px;
            }
        }

        .blog-entry__key-info {
            overflow: auto;
            border: solid $color-brand-2;
            border-width: 2px 0;
            padding: 8px 0;
            margin: 20px 0 16px;
        }

            .blog-entry__key-info__about-author {

                p {
                    font-size: 16px;

                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }

                &.tk-item-blog--image .blog-entry__key-info__about-author  {
                    @media (min-width: $screen-sm) {
                        width: calc(60% - 16px);
                        float: left;
                    }
                }

            .blog-entry__key-info__image {
                @media (min-width: $screen-sm) {
                    width: 40%;
                    height: auto;
                    float: right;
                }
            }


        .blog-entry__content {

            p {
                font-size: 16px;
                widows: 3;

                @media (min-width: $screen-sm) {
                    font-size: 18px;
                }
            }
        }

        .blog-entry__contact,
        .blog-entry__related,
        .blog-entry__disclaimer {
            padding-top: 1em;
            border-top: 2px solid $color-brand-2;
        }

            .blog-entry__contact__title,
            .blog-entry__related__title,
            .blog-entry__disclaimer__title  {
                font-family: $font-family-sans-serif;
                font-size: 20px;
            }

            .blog-entry__contact__content,
            .blog-entry__related__content,
            .blog-entry__disclaimer__content  {

                > * {
                    font-size: 16px;
    
                    @media (min-width: $screen-sm) {
                        font-size: 18px;
                    }
                }
            }
}