/**
 * Images
 */


img {
	max-width: 100%; // never bigger than the container
}

//see courses for .round-img

.img-profile-round, //deprecated
.img-avatar { //used specifically for profiles
	border-radius: 1000px;
	width: 80px !important;
	height: 80px !important;	
	overflow: hidden;
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
	padding: 0 !important; //when used in combination with rs-img

 	@media(min-width: $screen-sm-min){
		width: 100px !important;
		height: 100px !important;
 	}	
}

.img-avatar-lg {
	width: 120px !important;
	height: 120px !important;
	overflow: hidden;
 	@media(min-width: $screen-sm-min){
		width: 140px !important;
		height: 140px !important;
 	}
}

.img-avatar-sm {
	width: 60px !important;
	height: 60px !important; 	
	overflow: hidden;
}

.featured-img, //deprecated
.img-featured {	
	margin-bottom: $space;	
	width: 100%;
}

// Images within the CMS WYSIWYG wrapper

.jadu-cms,
.cms {

 	img {
        max-width: 100%;               
        margin: 0 auto $space-half auto;
        display: block;
        height: auto;
    }

    img[src$=".svg"] {
		width: 100%;
	}
	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		img[src$=".svg"] {
	    		width: 100%; 
	  	}
	}

    img.float_right,
    img.alignright,
    img.align-right {
		width: auto;
		float: right;
		margin: 0 0 $space-half $space-half;
    }

    img.float_left,
    img.alignleft,
    img.align-left  {
		width: auto;
		float: left;
		margin: 0 $space-half $space-half 0;
    }   

}

//Used for video

.embed-responsive {
	max-width: 100%;
}

//Images with captions

figure, 
.cms figure {

	margin: 0 0 $space-half 0;
	position: relative;

	img {
		margin: 0;
	}

	figcaption {
		background: rgba($color-masthead, 0.85);
		color: white;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		padding: $space-xs/2;
	}
}
.cms {
    .embed-responsive {
        margin-bottom: $space;
    }
}

/* Hacky images used on short courses */
//for accreditation

.img-box {
	
	border: 1px solid $color-border;
	position: relative;
	float: left;
	margin: 0 1% $space-xs 1%;
	position: relative;
	left: -1%;
	width: 48%;		
	padding-bottom: 48%;

	@media(min-width: $screen-sm-min){
		width: 23%;		
		padding-bottom: 23%;
	}

	img {
		position: absolute;
		top: 5%;
		left: 5%;
		max-width: 90%;
		max-height: 90%;
	}

}
