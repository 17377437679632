// Build mixins rather than extends

//All caps typography used across the website
@mixin type-all-caps(){
	font-family: $font-family-sans-serif;
	text-transform: uppercase;		
	font-size: 14px;		
	line-height: 110%;

	@media(min-width: $screen-xs-min){
		letter-spacing: 0.05em;
		line-height: 140%;		
		font-size: 15px;
	}

	@media(min-width: $screen-sm-min){
		font-size: 15px;
	}
}