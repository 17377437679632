// TODO: inherit styles from standard cards

.showcase-cards {
    overflow: auto;
}

.showcase-card {
    position: relative;
    padding-top: calc(50% - 8px);
    margin-bottom: 12px;
    margin-left: 8px;
    margin-right: 8px;

    @media (min-width: $screen-sm) {
        float: left;
        width: calc(50% - 16px);
        padding-top: calc(25% - 8px);
    }

    .showcase-cards--count-1 &,
    .showcase-cards--count-3 &,
    .showcase-cards--count-5 &,
    .showcase-cards--count-7 & {

        &:nth-of-type(1) {

            @media (min-width: $screen-sm) {
                float: none;
                width: auto;
                padding-top: 40%;
            }

            @media (min-width: 768px) {
                padding-top: calc(50% - 8px);
            }

            @media (min-width: $screen-md) {
                padding-top: 40%;
            }
        }
    }

    .showcase-cards--count-4 & {

        &:nth-of-type(1) {
            @media (min-width: $screen-md) {
                width: calc(100% - 16px);
                padding-top: 40%;
            }
        }

        &:nth-of-type(2),
        &:nth-of-type(3),
        &:nth-of-type(4) {
            @media (min-width: $screen-md) {
                width: calc((100% - 0.5px) / 3 - 16px);
                padding-top: calc(100% / 6 - 8px);
            }
        }
    }

    .showcase-cards--count-6 & {

        &:nth-of-type(1) {

            @media (min-width: $screen-sm) {
                // float: none;
                width: calc(50% - 16px);
                padding-top: 40%;
            }

            @media (min-width: 769px) {
                padding-top: calc(25% - 8px);
            }

            @media (min-width: $screen-md) {
                width: calc(100% - 16px);
                padding-top: 40%;
            }
        }

        &:nth-of-type(4),
        &:nth-of-type(5),
        &:nth-of-type(6) {
            @media (min-width: $screen-md) {
                width: calc((100% - 0.5px) / 3 - 16px);
                padding-top: calc(100% / 6 - 8px);
            }
        }
    }

    .showcase-cards--count-7 & {

        &:nth-of-type(2),
        &:nth-of-type(3),
        &:nth-of-type(4),
        &:nth-of-type(5),
        &:nth-of-type(6),
        &:nth-of-type(7) {
            @media (min-width: $screen-md) {
                width: calc((100% - 1px) / 3 - 16px);
                padding-top: calc(100% / 6 - 8px);
            }
        }
    }
}

    .showcase-card__text-container {
        padding: 16px;
        background: $color-module-light;

        .showcase-cards--count-1 .showcase-card:nth-of-type(1) &,
        .showcase-cards--count-3 .showcase-card:nth-of-type(1) &,
        .showcase-cards--count-5 .showcase-card:nth-of-type(1) &,
        .showcase-cards--count-6 .showcase-card:nth-of-type(1) &,
        .showcase-cards--count-7 .showcase-card:nth-of-type(1) & {

            @media (min-width: $screen-md) {
                position: absolute;
                top: 66%;
                left: 10%;
                -ms-transform: translateY(-50%);
                transform: translateY(-50%);
                width: 50%;
                padding: 24px;
                z-index: 1;
            }
        }

        .showcase-cards--count-4 .showcase-card:nth-of-type(1) & {

            @media (min-width: $screen-md) {
                position: absolute;
                top: 66%;
                left: 10%;
                transform: translateY(-50%);
                width: 50%;
                padding: 24px;
                z-index: 1;
            }
        }
    }

    .showcase-card__title {
        font-family: $font-family-big;
        font-weight: 800;
        font-size: 24px;

    }

        .showcase-card__title__link {
            color: inherit;

            &:hover,
            &:focus {
                color: inherit;
                text-decoration: none;
                outline: none;
            }

            &:focus {
                text-decoration: underline;
            }

            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                margin-top: 50%;
                z-index: 10;
                height: 5px;
                background-color: $color-brand-1;
                width: 16px;
                transition: width .2s ease-in-out;
                transition-delay: .2s;

                .showcase-cards--count-1 .showcase-card:nth-of-type(1) &,
                .showcase-cards--count-3 .showcase-card:nth-of-type(1) &,
                .showcase-cards--count-4 .showcase-card:nth-of-type(1) &,
                .showcase-cards--count-5 .showcase-card:nth-of-type(1) &,
                .showcase-cards--count-6 .showcase-card:nth-of-type(1) &,
                .showcase-cards--count-7 .showcase-card:nth-of-type(1) & {

                    @media (min-width: $screen-md) {
                        margin-top: 0;
                    }
                }
            }

            &:hover,
            &:focus {
                &::before {
                    width: 100%;
                    transition: width 0.4s ease-in-out;
                }
            }

            &::after {
                content: "";
                position: absolute;
                z-index: 1;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
            }

        }

    .showcase-card__text {
        font-size: 18px;
    }

    .showcase-card__image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        padding-bottom: 50%;
        background: 50% / cover;

        .showcase-cards--count-1 .showcase-card:nth-of-type(1) &,
        .showcase-cards--count-3 .showcase-card:nth-of-type(1) &,
        .showcase-cards--count-4 .showcase-card:nth-of-type(1) &,
        .showcase-cards--count-5 .showcase-card:nth-of-type(1) &,
        .showcase-cards--count-7 .showcase-card:nth-of-type(1) & {

            @media (min-width: $screen-md) {
                width: 80%;
                left: auto;
                padding-bottom: 40%;
            }
        }

        .showcase-cards--count-6 .showcase-card:nth-of-type(1) & {
            @media (min-width: $screen-md) {
                width: 80%;
                left: auto;
                padding-bottom: 40%;
            }
        }
    }

    .showcase-card__category-list {
        padding-top: 10px;
        margin-bottom: 0;
    }

    .showcase-card__category-term {
        @extend .hide-accessible;
    }

    .showcase-card__category-data {
        font-size: 16px;
        font-weight: 800;
        text-transform: uppercase;

        &::before {
            content: "\EA33";
            display: inline-block;
            position: relative;
            left: -0.2em;
            top: 0.25em;
            font-family: "tk-icons";
            color: $color-brand-2;
            font-size: 1.3em;
            transition: color 0.2s ease-in-out;
        }

        .showcase-card:hover & {
            &::before {
                color: $color-brand-1;
                transition: color 0.4s ease-in-out;
            }
        }
    }
