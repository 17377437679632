.site-footer {}

	.site-footer-upper {
		padding: 5px 0;
		border-top: 1px solid $color-border-light;
		background: $color-white;

		body.business & {
			background: $color-masthead;
		}
	}

		.site-footer-upper-social, //deprecated
		.footer-social {

			//outline: 1px solid blue;
			width: 100%;
			display: flex;
			flex-wrap: wrap;

			align-items: center;
			justify-content: center;

			@media (min-width: $screen-sm-min) {
				max-width: 50%;
				width: auto;
				float: right;
			}

			& > a {
				@include tk-transition();
		 		color: $color-brand-2;
		 		text-align: center;
		 		margin: $space-half $space-half;

		 		@media (min-width: $screen-sm-min) {
		 			margin: $space 0 $space $space;
		 		}

		 		@media (min-width: $screen-md-min) {
		 			margin: 14px 0 14px $space;
		 		}

		 		&:hover {
		 			color: $color-font;
		 			text-decoration: none;
				 }
				 
				 .site-footer-upper-dark & {
					&:hover,
					&:focus {
						color: lighten($color-brand-2, 30%);
					} 
				 }
		 	}

		 	.tk-icon {
		 		font-size: 24px;
		 		line-height: 1;

			 	@media (min-width: $screen-md-min) {
			 		font-size: 32px;
			 	}
		 	}
		}

		.site-footer-upper-logos, //deprecated
		.footer-img {
			display: flex;
			align-items: center;
			justify-content: center;

			@media (min-width: $screen-sm-min) {
				max-width: 50%;
				width: auto;
				float: left;
			}

			& > a {
		 		text-align: center;
		 		&:hover {
		 			text-decoration: none;
		 		}
			}

			img {
				margin: 0 $space-half $space-half 0;
				max-height: 55px;

		 		@media (min-width: $screen-sm-min) {
		 			margin: 0 $space-half 0 0;
		 		}

				@media (min-width: $screen-md-min) {
					max-height: 60px;
				}
			}
		}

	.site-footer-upper-dark {
		background: $color-masthead-light;
		border-top: 0;
	}


.site-footer-middle {
    background: $color-masthead;
	padding: $space 0;
}

.site-footer-lower {
    background: $color-masthead-dark;
    padding: $space-half 0;
    ul {
        padding: 0;
        margin: 0;

        li {
        	margin-bottom: $space-half;
        	@media(min-width: $screen-xs-min){
        		display: inline-block;
        		margin: 0;
        	}

            font-size: $font-size-small;
            color: #b7b7b7;
            a {
                color: $color-white;
                padding:  0;
                @media(min-width: $screen-xs-min){
	        		padding: $space-half;
	        	}
                &:hover,
                &:focus {
                	background: none;
                	text-decoration: underline;
                }
            }
        }
	}
	
	body.business & {
        background: $color-masthead;
        padding-top: 52px;
        position: relative;

		&:before,
		&:after {
			display: block;
            position: absolute;
			height: 108px;
			top: -35px;
			background-image: url(../img/bs-swoosh.svg); 
			background-size: 826.168px 107.421px;

			@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
				background-image: url(../img/bs-swoosh.png); 
			}

			@media (min-width: $screen-xs-min) {
				top: -35px;
			}

			@media (min-width: 838px) {
				top: -32px;
			}
		}

        &:before{
            content: '';
            left: 0;
            right: 0;
			background-position: left -2px center;

			@media (min-width: 696px) {
				right: 300px;
				max-width: 650px;
			}

		}
		
		&:after {
			content: none;
			right: 0;
			width: 500px;
			background-position: left -2px center;

			@media (min-width: 696px) {
				content: '';
			}

			@media (min-width: 860px) {
				width: 800px;
				background-position: right -2px center;
			}

		}

		@media (min-width: $screen-sm) {
			.nav {
				margin-top: 1em;
			}
		}
		

		.ie9 & {
			&:before,
			&:after {
				content: none;
			}
		}
	}

	body.business-section--ug & {

		&:before,
		&:after {
			background-image: url(../img/bs-swoosh-ug.svg); 

			@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
				background-image: url(../img/bs-swoosh-ug.png); 
			}
		}

	}

	body.business-section--masters & {
		
		&:before,
		&:after {
			background-image: url(../img/bs-swoosh-masters.svg); 

			@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
				background-image: url(../img/bs-swoosh-masters.png); 
			}
		}

	}

	body.business-section--phd & {
		
		&:before,
		&:after {
			background-image: url(../img/bs-swoosh-phd.svg); 

			@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
				background-image: url(../img/bs-swoosh-phd.png); 
			}
		}

	}

	body.business-section--mba & {
		
		&:before,
		&:after {
			background-image: url(../img/bs-swoosh-mba.svg); 

			@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
				background-image: url(../img/bs-swoosh-mba.png); 
			}
			
		}

	}

	body.business-section--exec-ed & {
		
		&:before,
		&:after {
			background-image: url(../img/bs-swoosh-exec-ed.svg); 

			@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
				background-image: url(../img/bs-swoosh-exec-ed.png); 
			}
		}

	}

	body.business-section--corp & {
		
		&:before,
		&:after {
			background-image: url(../img/bs-swoosh-corp.svg); 

			@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
				background-image: url(../img/bs-swoosh-corp.png); 
			}
		}

	}

	body.business-section--od & {
		
		&:before,
		&:after {
			background-image: url(../img/bs-swoosh-od.svg); 

			@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
				background-image: url(../img/bs-swoosh-od.png); 
			}
		}

	}
	
}

