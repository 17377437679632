.cards-swiper-container {
    overflow: hidden;
    padding: 10px 0;
}
.cards-swiper {
    opacity: 0;
    position: relative;

    .no-js &,
    &.owl-carousel {
        opacity: 1;
    }

    .card-stacked {
        width: 25%;
        float: left;
    }

    .owl-stage-outer {
        overflow: visible;
        &:before,
        &:after {
            z-index: 1;
            content: '';
            display: block;
            width: 100vw;
            height: 100%;
            position: absolute;
            top: 0;
        }
        &:after {
            right: -100vw;
            background: linear-gradient(
                90deg,
                rgba($color-module-light, 0.85),
                rgba($color-module-light, 0.85)
            );
        }
        &:before {
            left: -100vw;
            background: linear-gradient(
                90deg,
                rgba($color-module-light, 0.85),
                rgba($color-module-light, 0.85)
            );
        }
    }
}

.cards-swiper .owl-nav-custom {
    button.owl-prev,
    button.owl-next {
        @include owl-button();
        &.disabled {
            display: none;
        }
    }
    .owl-next {
        @include owl-button-next();
        @include owl-button-next-xs();
        @media (min-width: $screen-xs-min) {
            @include owl-button-next-sm();
        }
        @media (min-width: $screen-md-min) {
            @include owl-button-next-md();
        }
        @media (min-width: ($screen-lg-min+80)) {
            @include owl-button-next-lg();
        }
    }
    .owl-prev {
        @include owl-button-prev();
        @include owl-button-prev-xs();
        @media (min-width: $screen-xs-min) {
            @include owl-button-prev-sm();
        }
        @media (min-width: $screen-md-min) {
            @include owl-button-prev-md();
        }
        @media (min-width: ($screen-lg-min+80)) {
            @include owl-button-prev-lg();
        }
    }
}
