// Accommodation quotes - incorrectly labelled profiles
.profile-block { 
  @include tk-clearfix();      
  background: $color-brand-2;  
  position: relative;
  padding: $space;  
  color: #fff; 

  .profile-img {

      width: 20%;        
      float: right;        
      position: relative;                        
      padding-bottom: 20%;           
      border-radius: 1000px;
      margin-bottom: $space;   

      background-size: cover;        
      background-position: center; 
      
      img {       
          display: none;        
      }       

  }

  .more {
      color: white;
      padding: $space-sm 0 0 0;
      @media (min-width: $screen-md-min) { //teuxdeux: should be done with modernizr  
          padding: 0 $space $space $space;
      }
      &:after {
          color: white;
      }
  }


  @media (min-width: $screen-md-min) { //teuxdeux: should be done with modernizr  
      display: flex; // overwrites floats        
      width: 100%;
      padding: 0;
        
      .profile-quote {
          width: 66.66%;   
          position: relative;                           
      }

      .profile-quote-inner {
          padding-left: 0;   
          display: table;
          width: 100%;
          height: 100%;                     
      }

      .profile-info {
          display: table-row;        
      
          & > * {               
              display: table-cell;                
              padding: $space;
          }
      }

      .profile-blockquote {            
          display: table-row;            
          & > * {                
              display: table-cell;
              vertical-align: bottom;
              padding: $space;
              height: 100%;                
          }            
      }
      
      .profile-img {                
          border-radius: 0;
          width: 33.33%;            
          margin: 0;   
          padding: 0;        

          &:after {  // padding bottom ratio does not work on direct child of flexbox, so maintain height with a pseudo element              
              padding-bottom: 100%;
              content:"";
              display: block;
          }     
      }
  }
}

.profile-block-no-img {
  .profile-quote {
      width: 100%;   
      padding: 0;
      @media (min-width: $screen-md-min) {   
          min-height: 350px;
      }                          
  }       
}

.profile-block-alt { //align img left
  @media (min-width: $screen-md-min) {              
      flex-direction: row-reverse;
  }
}

//full width, rather than constrained by the wrapper

.profile-block-fw {
  
  @media (min-width: $screen-md-min) {        

      .profile-quote {
          width: 75%;            
      }

      .profile-quote-inner {
          padding-left: 33.33%;           
      }
     
      .profile-img {            
          width: 25%;
          //padding-bottom: 25%;            
      }
  }
}

.profile-block-fw.profile-block-no-img {

  .profile-quote {        
      padding-left: 0;         
  }

   @media (min-width: $screen-md-min) {        

      .profile-quote {
          width: 100%;      
      }
  }

}

.profile-block-fw.profile-block-alt {
  @media (min-width: $screen-md-min) {        
      .profile-quote-inner {
          padding-right: 33.33%;           
          padding-left: 0;
      }
  }
}

.profile-quote {
  blockquote {
    color: #fff;
  }
}

// /* Profile widget/supplement */

.profiles-supplement {

  // Remove whitespace when presented as a carousel
  &.owl-loaded {
    font-size: 0;
  }

  .profile {
    @include tk-clearfix();
    position: relative;
    background: $color-module-light;
    text-align: center;
    padding: 18px;

    @media (min-width: $screen-sm-min) {
      padding: 0;
      text-align: left;
    }
  }
    
    .profile__title {
      @extend %heading-related !optional;
      
      @media (min-width: $screen-sm-min) {
        position: absolute;
        top: $space;
        left: $space;
        width: #{calc(100% / 3 * 2 - 32px)};
      }
    }
  
      .profile__title__name {
        font-weight: bold;
        display: block;
  
        @media (min-width: $screen-sm-min) {
          display: inline;
        }
      }
  
      .profile__title__course {
        font-weight: 100;

        @media (min-width: $screen-sm-min) {
          &:before {
            content: ' / ';
            color: $color-brand-2;
            font-weight: bold; 
          }
        }
      }
  
    .profile__image {
      background: 50% / cover;

      @media (max-width: 767px) {
        display: block;
        width: 50%;
        margin: 0 auto;
        padding-bottom: 50%;
        border-radius: 50%;
      }

      @media (min-width: $screen-sm-min) {
        width: calc(100% / 3);
        padding-bottom: calc(100% / 3);
        float: right;;
      }

    }

    // Add margin if a carousel to accommodate floating navigation
    &.owl-loaded {
      .profile__image {
        @media (max-width: 767px) {
          margin-bottom: 56px;
        }
      }
    }
  
    .profile__quote {
      border: none;
      font-family: $font-family-big;
      padding-left: $space;
      padding-right: $space;
      padding-bottom: 0;
      padding-right: 0;
      position: relative;
      font-size: 18px;
      font-style: italic;
      margin-bottom: 2rem;

      @media (min-width: $screen-sm-min) {
        position: absolute;
        bottom: 46px;
        left: 32px;
        max-width: calc(100% / 3 * 2 - 42px);
        padding: 0;
        margin-bottom: 0;
      }

      .template--homepages-single-nosidebar & {
        @media (min-width: $screen-lg-min){
          font-size: 22px;
          left: 10%;
          max-width: calc(100% / 5 * 3 - 84px);
        }
      } 

  
      &:before,
      &:after {
          font-size: 44px;
          color: $color-brand-2;
          line-height: 13px;
      }
  
      &:before {
          content: '\201C';
          position: relative;
          top: 15px;
          left: -3px;
  
          @media (min-width: $screen-sm-min) {
              position: absolute;
              left: -20px;
              top: 12px;
          }
      }
  
      &:after {
          content: '\201D';
          position: relative;
          top: 16px;
      }
    }
  
    .profile__more {
      color: $color-font;
      text-transform: uppercase;
      font-weight: normal;

      @media (max-width: 767px) {
        font-weight: bold;
        color: black;
        display: inline-block;
        width: auto;
        min-width: 115px;;
        padding: 8px;
        border: 1px solid $color-brand-2;

        &:after {
          content: none;
        }
      }

      @media (min-width: $screen-sm-min) {
        position: absolute;
        bottom: 14px;
        left: 32px;
      }
      .template--homepages-single-nosidebar & {
        @media (min-width: $screen-lg-min) {
          left: 10%;
        }
      }
    }

  .owl-dots {
    text-align: center;
    position: relative;

    @media (max-width: 767px) {
        position: absolute;
        top: 0;
        width: 88px;
        height: 32px;
        margin-top: calc(55% + 40px);
        left: 50%;
        transform: translateX(-50%);
    }

    @media (min-width: $screen-sm-min) {
        position: absolute;
        bottom: 20px;
        right: calc(100% / 3 + 52px);
    }
  }

  .owl-dot {
    z-index: 2;
    background:#000 !important;
    height: 12px;
    width: 12px;
    border-radius: 50%; 
    margin-left: 8px;

    @media (max-width: 767px) {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }

    &:first-of-type {
        margin-left: 0;
    }

    &.active {
        background: $color-brand-2 !important;
    }
  }


  .owl-nav-custom {
    position: relative;
    width: 148px;
    margin: 0 auto;

    @media (max-width: 767px) {
        position: absolute;
        top: 0;
        margin-top: calc(55% + 40px);
        left: 50%;
        transform: translateX(-50%);
        height: 32px;
    }

    @media (min-width: $screen-sm-min) {
        width: 63px;
        height: 32px;
        position: absolute;
        top: 1px;
        right: 0;    
    }
  }

  .owl-prev,
  .owl-next {
    position: absolute;
    top: 0 !important;

    @media (max-width: 767px) {
        background: transparent !important;
        width: 18px !important;
        padding: 0 !important;
        &:before {
            color: $color-brand-2;
        }
    }

    @media (min-width: $screen-sm-min) {
      top: 1px;
      margin-top: 0 !important;
      width: 32px !important;
      height: 32px !important;
    }

    &:before {
      font-size: 24px;

      @media (min-width: $screen-sm-min) {
          color: $color-brand-2;
          font-size: 20px !important;
      }
    }
  }

  .owl-prev {
    left: 0 !important;

    @media (max-width: 767px) {
      
      text-align: left;
      padding-left: 0;
    }

    @media (min-width: $screen-sm-min) {
      left: auto;
      right: 32px;
      border-right: 1px solid $color-brand-2 !important; 
    }
  }

  .owl-next {
    right: 0 !important;

    @media (max-width: 767px) {
      text-align: right;
    }

    @media (min-width: $screen-sm-min) {
      border-left: 1px solid $color-brand-2 !important; 
      right: 0;
    }
  }
}
