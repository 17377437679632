/* Button mixins */

@mixin owl-button() {
    @include tk-icon();
    border: 0;
    color: white;
    position: absolute;
    z-index: 2;
    background-color: $color-masthead;
    &.disabled {
        pointer-events: none;
        opacity: 0.25;
        cursor: default;
    }
}

//prev button position
@mixin owl-button-prev() {
    &:before {
        font-size: 16px;
        @include tk-icon-nav-chevron-left-large();
    }
}

@mixin owl-button-prev-xs() {
    top: 5px;
    right: 40px;
    left: auto;
    width: 30px;
    height: 30px;
    margin: 0;
}

@mixin owl-button-prev-sm() {
    top: 10px;
    right: 60px;
    left: auto;
    width: 40px;
    height: 40px;
    margin: 0;
    &:before {
        font-size: 24px;
    }
}

@mixin owl-button-prev-md() {
    top: 10px;
    right: 65px;
    left: auto;
    width: 45px;
    height: 45px;
    margin: 0;
    &:before {
        font-size: 32px;
    }
}

@mixin owl-button-prev-lg() {
    top: 50%;
    right: auto;
    left: -45px;
    width: 50px;
    height: 50px;
    border-right: 5px solid $color-brand-2;
    margin-top: -25px;
    &:before {
        font-size: 32px;
    }
}

//next button position

@mixin owl-button-next() {
    &:before {
        font-size: 16px;
        @include tk-icon-nav-chevron-right-large();
    }
}

@mixin owl-button-next-xs() {
    top: 5px;
    right: 5px;
    left: auto;
    width: 30px;
    height: 30px;
    margin: 0;
}

@mixin owl-button-next-sm() {
    top: 10px;
    right: 10px;
    left: auto;
    width: 40px;
    height: 40px;
    margin: 0;
    &:before {
        font-size: 24px;
    }
}

@mixin owl-button-next-md() {
    top: 10px;
    right: 10px;
    left: auto;
    width: 45px;
    height: 45px;
    margin: 0;
    &:before {
        font-size: 32px;
    }
}

@mixin owl-button-next-lg() {
    top: 50%;
    right: -45px;
    left: auto;
    width: 50px;
    height: 50px;
    margin-top: -25px;
    border-left: 5px solid $color-brand-2;
    &:before {
        font-size: 32px;
    }
}
