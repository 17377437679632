/**
 * Responsive Images
 */

@mixin responsive-img-setup($namespace: '') {
    .rs-img#{$namespace} {
        //default square 1:1
        display: block;
        width: auto;
        overflow: hidden;
        position: relative;
        padding-bottom: 100%;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        background-color: $color-brand-2;
        background-image: url('../img/uol-1-1-tower.png');
        a {
            border: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0);
            transition: background 0.25s ease;
            &:hover {
                background: rgba(0, 0, 0, 0.25);
            }
        }
        img {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }
    }
    .rs-img-1-1#{$namespace} {
        //1:1
        padding-bottom: 100%;
        background-image: url('../img/uol-1-1-tower.png');
    }
    // .rs-img-4-3#{$namespace} {
    // 	//4:3
    // 	background-size: cover;
    // 	padding-bottom: 75%;
    // 	background-image: url('../img/uol-2-1-tower.png');
    // }
    // .rs-img-16-9#{$namespace} {
    // 	//16:9
    // 	background-size: cover;
    // 	padding-bottom: 56.25%;
    // 	background-image: url('../img/uol-2-1-tower.png');
    // }
    .rs-img-2-1#{$namespace} {
        //2:1
        padding-bottom: 50%;
        background-image: url('../img/uol-2-1-tower.png');
    }

    .rs-img-3-4#{$namespace} {
        padding-bottom: 133.333%;
        background-image: url('../img/uol-1-1-tower.png');
    }

    .rs-img-4-3#{$namespace} {
        padding-bottom: 75%;
        background-image: url('../img/uol-1-1-tower.png');
    }



    // 5/3 ratio gives this
    // +---+---------+
    // |   |         |
    // +---+         |
    // |   |         |
    // +---+---------+

    .rs-img-5-3 {
        //give you
        padding-bottom: 65.6%;
        background-image: url('../img/uol-2-1-tower.png');
    }

    .lt-ie9 .rs-img#{$namespace} {
        background: none !important;
        position: relative;
        img {
            position: static;
            top: auto;
            left: auto;
        }
    }
}

@include responsive-img-setup();
@media (min-width: $screen-xs-min) {
    @include responsive-img-setup('-xs');
}

@media (min-width: $screen-sm-min) {
    @include responsive-img-setup('-sm');
}

@media (min-width: $screen-md-min) {
    @include responsive-img-setup('-md');
}

@media (min-width: $screen-lg-min) {
    @include responsive-img-setup('-lg');
}

@supports (object-fit: cover) {
    //Modern object fit if supported
    [class*='rs-img'] {
        img {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        a {
            img {
                transform: scale(1);
                transition: all 0.25s ease;
            }
        }
        a:hover {
            img {
                transform: scale(1.075);
            }
        }
    }
}

//Any link that is hovered trigger resize
a [class*='rs-img'] {
    img {
        transform: scale(1);
        transition: all 0.25s ease;
    }
    &:hover {
        img {
            transform: scale(1.075);
        }
    }
}

/* Image caption used inside of responsive images */

[class*='rs-img'] .img-caption {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: $color-masthead;
    background: rgba($color-masthead, 0.75);
    margin: 0;
    padding: $space-xs/2 $space-xs;
    line-height: 140%;
    color: white;
    font-size: 15px;
}
.rs-img-mask {
    position: relative;
    font-size: 48px;
    color: white;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.5) 100%
        );
        z-index: 1;
    }
}

.rs-img-video {
    &:after {
        content: '';
        top: 50%;
        left: 50%;
        margin-top: -24px;
        margin-left: -24px;
        position: absolute;
        @include tk-icon;
        @include tk-icon-action-play-circle-outline;
        width: 48px;
        height: 48px;
        display: block;
        z-index: 2;
    }
}
