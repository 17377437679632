//Shameful css
/*

$screen-xs:                  480px;
$screen-phone:               $screen-xs-min;

$screen-sm:                  768px;
$screen-tablet:              $screen-sm-min;

$screen-md:                  1040px;
$screen-desktop:             $screen-md-min;

$screen-lg:                  1260px;
$screen-lg-desktop:          $screen-lg-min;

// So media queries don't overlap when required, provide a maximum
$screen-xxs-max:              ($screen-xs-min - 1);
$screen-xs-max:              ($screen-sm-min - 1);
$screen-sm-max:              ($screen-md-min - 1);
$screen-md-max:              ($screen-lg-min - 1);

*/

// body {
//     @media (max-width: $screen-xs-min) {
//          border: 2px solid blue;
//     }
//     //BS medium-small hack
//     @media (min-width: $screen-ms-min) {
//          border: 2px solid green;
//     }
//     @media (min-width: $screen-sm-min) {
//         border: 2px solid yellow;
//     }
//     @media (min-width: $screen-md-min) {
//         border: 2px solid orange;
//     }
//     @media (min-width: $screen-lg-min) {
//         border: 2px solid red;
//     }
// }
//Banner button
.slide-scroll {
    display: none;
    position: absolute;
    padding-top: 32px;
    //border: 1px solid red;
    bottom: 10px;
    left: 50%;
    z-index: 10;
    color: white;
    width: 50px;
    margin-left: -25px;
    text-align: center;

    &:after,
    &:before {
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        top: 0;
    }
    &:after {
        border: 2px solid white;
        width: 20px;
        height: 32px;
        margin-left: -10px;
        border-radius: 100px;
    }
    &:before {
        top: 4px;
        margin-left: -2px;
        background: white;
        width: 4px;
        height: 7px;
        border-radius: 100px;
        animation: scroll 2s infinite;
    }
}

@keyframes scroll {
    0% {
        transform: translateY(0px);
        opacity: 1;
    }
    100% {
        transform: translateY(10px);
        opacity: 0;
    }
}

//5 column grid for BS
@mixin five($namespace: "") {
    .col-#{$namespace}-1-5,
    .col-#{$namespace}-2-5,
    .col-#{$namespace}-3-5,
    .col-#{$namespace}-4-5 {
        position: relative; // Prevent columns from collapsing when empty
        min-height: 1px; // Inner gutter via padding
        padding-left: ceil(($grid-gutter-width / 2));
        padding-right: floor(($grid-gutter-width / 2));
        float: left;
    }
    .col-#{$namespace}-1-5 {
        width: 20%;
    }
    .col-#{$namespace}-2-5 {
        width: 40%;
    }
    .col-#{$namespace}-3-5 {
        width: 60%;
    }
    .col-#{$namespace}-4-5 {
        width: 80%;
    }
    .col-#{$namespace}-5-5 {
        width: 100%;
    }
}

@media (min-width: $screen-ms-min) {
    @include five('ms');
}

@media (min-width: $screen-sm-min) {
    @include five('sm');
}

.sidebar-widget {
    h3 {
        @extend %heading-related;
    }
}

.ajax-loader {
    width: auto !important;
}

.collapsing {
    //customises the bs collapse transition
    @include tk-transition(height, $duration: 0.3s, $effect: zoom);
}

.main {
}
.back-top {
    position: relative;
    a {
        @extend .heading-related;
        top: -30px;
        position: absolute;
        left: $space-sm;
        &:after {
            @include tk-icon();
            @include tk-icon-chevron-up();
            margin-left: 5px;
            position: relative;
            color: $color-brand-2;
        }
    }
}

//Related dropdown
.select-group {
    overflow: hidden;
    margin-top: 4px;
    margin-bottom: 4px;
    select {
        margin: 0;
        &:nth-of-type(1) {
            border-bottom: 0;
        }
    }
}

.cms {
    //border: 1px solid red;
}

////
//media griups for images and video
.media-group {
    // border: 1px solid red;
    // margin-bottom: $space-lg;
}

.media-group-item {
    //    border: 1px solid blue;
}

.cms-wrapper-lg {
    @media (min-width: $screen-md-min) {
        width: 104%;
        margin-left: -2%;
    }
    @media (min-width: $screen-lg-min) {
        width: 124%;
        margin-left: -12%;
    }
}

//Swiper/banner remove from html
.js-swiper-scroll,
.swiper-more {
    display: none;
}
