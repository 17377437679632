
/**
 * Borders
 */

@mixin border-top-brand-1() {
    border-top: 5px solid $color-brand-1-light;
}

@mixin border-top-brand-2() {
    border-top: 5px solid $color-brand-2;
}

@mixin border-bottom-brand-1() {
    border-bottom: 5px solid $color-brand-1-light;
}

@mixin border-left-brand-1() {
    border-left: 5px solid $color-brand-1-light;
}

@mixin border-top-brand-2() {
    border-top: 5px solid $color-brand-2;
}

@mixin border-right-brand-2() {
    border-right: 5px solid $color-brand-2;
}

@mixin border-bottom-brand-2() {
    border-bottom: 5px solid $color-brand-2;
}

@mixin border-left-brand-2() {
    border-left: 5px solid $color-brand-2;
}

@mixin border-bottom-divider() {
    border-bottom: 1px solid $color-border;
}