// Breakpoints
@mixin break($point) {
  @if $point==md {
    @media (max-width: 1000px) {
      //less than 1000px
      @content;
    }
  }
  @if $point==sm {
    @media (max-width: 800px) {
      @content;
    }
  }
}

body {
  // @include break(md){
  //   border: 2px solid green;
  // }
  // @include break(sm){
  //   border: 2px solid blue;
  // }
}

/**
 * Tiles grid
 */

@mixin tiles-grid-setup($namespace: "") {
  //Flat card
  .tiles-col-1-1#{$namespace} {
    width: 100%;
  }
  .tiles-col-1-2#{$namespace} {
    width: 50%;
  }
  .tiles-col-1-4#{$namespace} {
    width: 25%;
  }
}

@include tiles-grid-setup();
@include break(md) {
  @include tiles-grid-setup("-md");
}

@include break(sm) {
  @include tiles-grid-setup("-sm");
}

//Multi
.tiles-grid {
  width: 100%;
  overflow: hidden;
}

[class^="tiles-col-"] {
  float: left;
}

.tiles-col-right {
  float: right;
}

.tiles-col-right-md {
  @include break(md) {
    float: right;
  }
}

.tiles-empty {
  @include break(md) {
    display: none;
  }
  .tile {
    background-color: transparent;
    &:hover {
      background-color: transparent;
    }
  }
}

/* Tiles */

.tile {
  background: $color-module-dark;
  position: relative;
  transition: all 0.25s ease;
  @include break(sm) {
    border-right: 4px solid white;
    border-left: 4px solid white;
    border-bottom: 4px solid white;
  }
}

/* Tile Skin */

.tile-skin-img {
  //tile with bg image
  overflow: hidden;
  transition: 0.25s ease all;
  &:hover {
    .tile-bg {
      @include break(md) {
        transform: scale(1);
      }
      &:after {
        opacity: 1;
      }
    }
  }
  .tile-bg:after {
    background: rgba(33, 33, 33, 0.6);
    background: linear-gradient( to bottom, rgba(33, 33, 33, 0) 0%, rgba(33, 33, 33, 0.9) 100%);
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    transition: all 0.25s ease;
  }
  &:hover:after {
    background: linear-gradient( to bottom, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 1) 100%);
    border: 2px solid red;
  }
}

.tile-skin-brand-1 {
  //backgrounds colors
  background: $color-brand-1;
  &:hover {
    background: $color-brand-1-dark;
  }
  &:hover .tile-content {
    background: $color-brand-1-dark;
  }
  .tile-content {
    background: $color-brand-1;
  }
}

.tile-skin-brand-2 {
  //backgrounds colors
  background: $color-brand-2;
  &:hover {
    background: $color-brand-2-dark;
  }
  &:hover .tile-content {
    background: $color-brand-2-dark;
  }
  .tile-content {
    background: $color-brand-2;
  }
}

.tile-skin-nav {
  //tilenav
  background: none;
  @include break(sm) {
    display: none;
  }
  &:hover {
    background: none;
  }
}

/* tile Layout*/

@mixin tiles-shape-setup($namespace: "") {
  //rectangle
  .tile-shape-rectangle#{$namespace} {
    width: 100%;
    padding-bottom: 50%;
  } //square
  .tile-shape-square#{$namespace} {
    width: 100%;
    padding-bottom: 100%;
  }
}

@include tiles-shape-setup();
@include break(md) {
  @include tiles-shape-setup("-md");
}

@include break(sm) {
  @include tiles-shape-setup("-sm");
}

/* tile Children */

/*
<div class="tile">
  <span class="tile-bg></span>
  <div class="tile-inner">Content</div>
  <a class="tile-link" href="#">Link</a>
</div>
*/

//tile__bg
.tile-bg {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-transform: scale(1);
  transform: scale(1);
  transition: 0.25s ease all;
  .tile-shape-split & {
    width: 50%;
    margin-left: 50%;
  }
  .tile-shape-split-md & {
    @include break(md) {
      width: 50%;
      margin-left: 50%;
    }
  }
}

//tile inner
.tile-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

//tile inner nav
.tile-inner ul {
  padding: 0;
  margin: 0;
  li {
    font-size: 16px;
    list-style: none;
  }
  a {
    text-decoration: none;
    text-transform: uppercase;
    color: $color-font;
    text-shadow: none;
    letter-spacing: 0.02em;
    &:after {
      @extend .tk-icon;
      @extend .tk-icon-chevron-right:before;
      margin-left: 5px;
      position: relative;
      color: $color-brand-2;
    }
    &:hover {
      border-bottom: 1px solid $color-module-darker;
    }
  }
}

.tile-inner[min-width~="450px"] ul li {
  font-size: 18px;
}

.tile-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-indent: -9999px;
  z-index: 1;
}

/* Content - Lives inside .tile-inner */

.content, //deprecated
.tile-content {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  color: white;
  overflow: hidden;
  font-size: 16px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.65);
  transition: background 0.25s ease;
  h3 {
    margin: 0 0 10px 0;
    line-height: 125%;
    font-weight: 300;
    font-family: "freight-display-pro", sans-serif;
    &:after {
      @extend .tk-icon;
      @extend .tk-icon-chevron-right:before;
      position: relative;
    }
  }
  p {
    line-height: 130%;
    margin: 0 0 6px 0;
  }
  a {
    color: $color-white;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.65);
  }
}

//split
.tile-shape-split {
  //splits the tile into 2 by changing .content & .tile__bg
  .content, //deprecated
  .tile-content {
    width: 50%;
  }
}

.tile-shape-split-md {
  @include break(md) {
    .content, //deprecated
    .tile-content {
      width: 50%;
    }
  }
}

.tile-skin-no-img {
  &[class*="-shape-split"] {
    .content, //deprecated
    .tile-content {
      width: 100% !important;
    }
    .tile-bg {
      background: none !important;
    }
  }
}

.tile .more {
  display: none;
}

.tile[min-width~="440px"] .more {
  display: inline;
}

.tile p {
  font-size: 14px;
  display: none;
}

.tile[min-width~="450px"] p {
  font-size: 16px;
  display: block;
}

.tile[min-width~="600px"] p {
  font-size: 18px;
}

.tile h3 {
  font-size: 18px;
  letter-spacing: 1px;
}

// .tile[min-width~="600px"] h3 {
//     // font-size: 28px;
// }
@media (min-width: $screen-sm-min) {
  //when we've got a sidebar
  .tile h3 {
    font-size: 24px;
  }
}

.content__inner, //deprecated
.tile-content-inner {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: $space-xs;
  @media (min-width: $screen-xs-min) {
    //padding: $space;
  }
}

.tile[min-width~="450px"] .content__inner {}

//content goes to top instead of bottom
.content--alt .content__inner, //deprecated
.tile-content-alt .tile-content-inner {
  top: 0;
}

/*
 * EVENTS TILES
 */

.tiles-events {
  .tile-content p {
    display: block;
    font-size: 18px;
  }
}

/*
 * VIDEO TILES
 */

.tile-skin-video {
  overflow: hidden;
  .tile-bg:after {
    background: rgba(33, 33, 33, 0.6);
    background: linear-gradient( to bottom, rgba(33, 33, 33, 0) 0%, rgba(33, 33, 33, 0.9) 100%);
    transition: 0.25s ease all;
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
  }
  &:hover {
    .tile-content:after {
      color: $color-brand-2;
      border-color: $color-brand-2;
    }
    .tile-bg:after {
      background: rgba(33, 33, 33, 0.75);
      background: linear-gradient( to bottom, rgba(33, 33, 33, 0) 0%, rgba(33, 33, 33, 0.92) 100%);
      opacity: 1;
    }
  }
  .tile-content {
    &:after {
      position: relative;
      float: left;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      content: "\EA33";
      color: #fff;
      font-family: "tk-icons" !important;
      font-size: 32px;
      line-height: 30px;
      padding: 6px 3px 6px 7px;
      text-align: center;
      border: 5px solid;
      border-color: #fff;
      border-radius: 50%;
      transition: 0.25s ease all;
    }

    h3 {
      @media (max-width: $screen-xs) {
        margin-bottom: -7px;
      }
    }

    h3:after {
      content: "";
    }
  }
}

/*
 * PROFILE TILE
 */

.tiles-profile {
  width: 75%;
  position: relative;
  float: left;
  display: flex;
  box-sizing: border-box;
  background-color: $color-module-light;
  @media (max-width: 799px) {
    float: none;
    width: 100%;
    flex-direction: column-reverse;
    background-color: $color-module-light;
    border-left: 4px solid #fff;
    border-right: 4px solid #fff;
    border-bottom: 4px solid #fff;
  }
  .profile-outline {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    outline: 1px solid $color-brand-2;
    outline-offset: -12px;
    z-index: 1;
    top: 0;
    left: 0;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      display: none;
    }
  }
  .tiles-profile-left {
    width: 66.6667%;
    z-index: 2;
    position: relative;
    @media( max-width: 799px ) {
      width: 100%;
    }
    .profile-inner {
      padding: 24px;
      z-index: 2;
      @media( max-width: 799px ) {
        padding: 16px 24px 24px 24px;
      }
      @media( max-width: 567px ) {
        padding: 24px 24px 32px 24px;
      }

      .profile-name, .profile-type, .profile-quote, .profile-more-link {
        text-align: center;
      }

      .profile-name {
        font-family: $font-family-big;
        margin-bottom: 24px;
        color: $color-font-dark;
        font-size: 28px;
        line-height: 24px;
        position: relative;
        &:after {
          content: "";
          position: absolute;
          width: 20%;
          height: 1px;
          bottom: -12px;
          left: 40%;
          background-color: $color-brand-2;
        }
      }

      .profile-type {
        font-family: $font-family-sans-serif;
        font-size: 16px;
        line-height: 16px;
        text-transform: uppercase;
        color: $color-font-light;
      }

      .profile-quote {
        padding: 12px 12px 6px;
        font-size: 16px;
      }
    }
  }
  .tiles-profile-right {
    width: 33.3333%;
    padding-bottom: 33.333%;
    position: relative;
    @media( max-width: 799px ) {
      width: 100%;
      padding-bottom: 50%;
      margin-top: 32px;
    }
    @media( max-width: 567px ) {
      padding-bottom: 66%;
      margin-top: 48px;
    }
    @media( max-width: 425px ) {
      padding-bottom: 88%;
      margin-top: 24px;
    }
    .profile-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      @media( max-width: 799px ) {
        left: 50%;
        transform: translateX(-50%);
        width: 50%;
      }
      @media( max-width: 567px ) {
        width: 66%;
      }
      @media( max-width: 425px ) {
        width: 88%;
      }
    }
  }
}

@media (min-width: 1024px) and (max-width: 1280px) {
  .tiles-profile[max-width~="660px"] {
    .profile-inner {
      padding: 12px !important;
    }
    .profile-name {
      font-size: 24px !important;
      line-height: 22px !important;
    }
    .profile-type {
      font-size: 14px !important;
      line-height: 14px !important;
    }
    .profile-outline {
      outline-offset: -6px;
    }
    .profile-quote {
      padding: 6px !important;
      line-height: 20px;
    }
  }
}

/*
 * TILES FACTS
 */

.tiles-facts {
  .tile {
    background-color: $color-font-dark;
    @include break(sm) {
      padding-bottom: 0;
    }
    .tile-inner {
      @include break(sm) {
        position: relative;
      }
    }
    .tile-content {
      padding: 30px;
      text-align: center;
      color: $color-off-white;

      @media (max-width: 1140px) {
        padding: 24px 18px;
      }
      @include break(md) {
        padding: 24px 18px;
      }
      @include break(sm) {
        position: relative;
        padding-bottom: 30px;
      }
      .facts-lead,
      .facts-sub {
        display: block;
      }
      .facts-sub {
        font-size: 18px;
        font-weight: 100;
        @include break(md) {
          font-size: 16px;
        }
      }
      .facts-lead {
        font-family: "freight-display-pro", sans-serif;
        font-size: 24px;
        line-height: 28px;
        letter-spacing: 1px;
        font-style: italic;
        margin-bottom: 12px;
        @include break(md) {
          font-size: 22px;
          line-height: 26px;
        }
        @include break(sm) {
          margin-bottom: 6px;
        }
      }
      h3 {
        color: $color-brand-2;
        font-size: 42px;
        line-height: 42px;
        @include break(md) {
          font-size: 36px;
        }
        @include break(sm) {
          margin-bottom: 6px;
        }
        @media (max-width: 450px) {
          font-size: 30px;
        }
      }
      h3:after {
        content: "";
      }
    }
    .tile-content:after {
      content: "";
      position: absolute;
      width: 25%;
      height: 1px;
      bottom: 30px;
      left: 37.5%;
      background-color: #aa8f6f;
      @include break(md) {
        bottom: 24px;
      }
    }
  }
}