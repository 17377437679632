.widget-banner--lubs-accreditation {
    position: relative;
}

.lubs-accreditation {

    display: none;

    @media(min-width: $screen-sm-min){
        .widget-banner--lubs-accreditation & {
            display: block;
        }
    }   
    width: 402px;
    height: 60px;
    margin: 0;
    padding: 10px 10px;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 10;
    transform: translateY(100%);
    text-align: right;

}

    .lubs-accreditation__label {
        display: inline-block;
        font-size: 80%;
        font-weight: bold;
        vertical-align:top;
    }

    .lubs-accreditation__item {
        display: inline-block;
        height: 100%;
        margin: 0 0 0 10px;
        vertical-align:top;
    }

        .lubs-accreditation__item--amba {
            margin-left: 5px;
        }

        .lubs-accreditation__item__image {
            width: 190px;
            height: 200px;
            max-height: 80%;
            max-width: 80px;
            margin-top: 7px;

            .lubs-accreditation__item--equis & {
                margin-top: 0;
                max-height: 90%;
            }

            .lubs-accreditation__item--aacsb & {
                margin-top: 4px;
            }

            .lubs-accreditation__item--amba & {
                max-height: 100%;
                max-width: 90px;
                margin-top: 1px;
            }
        }

// Triple Accreditation logos interfere with the breadcrumbs and first in-page title 
.widget-banner--lubs-accreditation + div {

    @media(min-width: $screen-sm-min){

        .breadcrumb-responsive {
            max-width: calc(100% - 400px);
    
            body.accreditation & {
    
            }
            .breadcrumb {
                // display: flex;
            }
        }

        &.cards-container--no-heading {
            .container-row {
                padding-top: 60px;
            }
        }

        // Additional padding for the first title after triple accreditation 
        .column-container-primary {

            & > .container-row {

                & > .wrapper-md {
    
                    & > .h2-lg {            
                        padding-top: 50px - 20px;
                    }
                }

                // > .wrapper-md,
                > div[class^="wrapper"] {
                    padding-top: 3em;
                }


    
            }
    
        }

        .h2-lg {            
            // padding-top: 30px;

            .wrapper-md & {
                padding-top: 0;
            }
        }

        > .wrapper-pd-md,
        > .wrapper-md {
            padding-top: 3em;
        }

        .column-container-secondary {
            margin-top: 60px;
        }

    }

}
