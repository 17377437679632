/**
 * Dividers
 */

/* Divider header */

.divider-header {
    @extend .clearfix;
    margin: 0 0 $space 0;
    padding: $space-half 0;
    position: relative;

    @media (min-width: $screen-sm-min) {
        border-bottom: 1px solid $color-border;
    }
}

.divider-header-heading {
    @extend %all-caps;
    margin: 0;
    @media (min-width: $screen-sm-min) {
        float: left;
    }
}

.divider-header-heading-underline {
    @extend %all-caps;
    position: relative;
    border-bottom: 1px solid $color-border;
    padding: $space-half/2 0;
    margin-bottom: $space-half/2;

    @media (min-width: $screen-sm-min) {
        border: 0;
        margin: 0;
        padding: 0;
        float: left;
        &:after {
            position: absolute;
            content: '';
            height: 4px;
            width: 100%;
            background: $color-brand-2;
            bottom: -12px;
            left: 0;
        }
    }
}

.divider-header-action {
    @extend %more;
    @extend %more-dark;
    margin: 0;
    @media (min-width: $screen-sm-min) {
        float: right;
    }

    a {
        color: $color-font;
    }
}

.divider-header-btn {
    @extend .all-caps;
    @media (min-width: $screen-sm-min) {
        float: right;
    }

    > span {
        display: inline-block;
    }

    ul {
        padding: 0;
        margin: 0;
        display: inline-block;

        li {
            list-style: none;
            display: inline-block;
            a {
                color: $color-font-lighter;
            }
            &.active a {
                color: $color-font;
            }
        }
    }

    a {
        color: $color-font;
    }
}

// deprecated old divider header

.content-header {
    @extend .clearfix;
    border-bottom: 1px solid $color-border;
    margin-bottom: $space;
}

.content-header-heading {
    @extend %all-caps;
    margin: 0;
    padding: 5px 0;
}

.content-header-heading-underline {
    border-bottom: 4px solid $color-brand-2;
    margin-bottom: -4px;
}

/* Horizontal Rules */

hr {
    display: block;
    width: 100%;
    border: 0;
    height: 1px;
    background: $color-border;
    overflow: visible;
    margin: $space-half 0;

    @media (min-width: $screen-xs-min) {
        margin: $space 0;
    }
}

.hr-sm {
    margin: $space-half/2 0;
}

.rule-image, /*deprecated*/
.rule-img {
    position: relative;
    margin-bottom: $space-half;
    padding: 10px 0;

    &:after,
    &:before {
        position: absolute;
        content: '';
        width: calc(50% - 70px);
        height: 1px;
        top: 50%;
        background: $color-brand-2;
    }

    &:after {
        right: 0;
    }

    &:before {
        left: 0;
    }

    img {
        @extend .hide-accessible;
    }

    span {
        background-color: $color-brand-2;
        background-image: url('../img/uol-avatar.png');
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center;

        display: block;
        border-radius: 100px;
        width: 60px;
        height: 60px;
        margin: 0 auto;

        position: relative;
        z-index: 2;

        @media (min-width: $screen-xs-min) {
            width: 100px;
            height: 100px;
        }
    }
}

.rule-img-dark {
    span {
        background-image: url('../img/uol-avatar-dark.png');
        background-color: $color-masthead;
    }
}
.rule-img-light {
    span {
        background-image: url('../img/uol-avatar-dark.png');
        background-color: $color-masthead;
    }
}

.rule-image-sm, /*deprecated*/
.rule-img-sm {
    @extend .rule-img;

    &:after,
    &:before {
        width: calc(50% - 50px);
    }

    span {
        width: 50px;
        height: 50px;

        @media (min-width: $screen-xs-min) {
            width: 60px;
            height: 60px;
        }
    }
}

.rule-img-module {
    &:after {
        background: $color-module;
    }
}
