/**
 * Icon font CSS generated by gulp see templates -> _tk-icons-mixins.scss
 * Using lodash https://lodash.com/docs/4.17.4
 */

@mixin tk-icon() {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "tk-icons" !important; 
    speak: none;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;    
    text-decoration: none;
    text-transform: none;   

    //Better alignment
    transform: translate(0px,-1px);
    
    line-height: 1;
    font-size: inherit;

    display: inline-block;
    vertical-align: middle;
    
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;    
}

/**
 * Icon font mixins
 */

@mixin tk-icon-16() {
    font-size: 16px;
}

@mixin tk-icon-24() {
    font-size: 24px;
}

@mixin tk-icon-32() {
    font-size: 32px;
}

@mixin tk-icon-48() {
    font-size: 48px;
}



@mixin tk-icon-add() {
    content: "\EA01" 
}

@mixin tk-icon-alert-danger() {
    content: "\EA02" 
}

@mixin tk-icon-alert-info() {
    content: "\EA03" 
}

@mixin tk-icon-alert-success() {
    content: "\EA04" 
}

@mixin tk-icon-alert-warning() {
    content: "\EA05" 
}

@mixin tk-icon-calendar() {
    content: "\EA06" 
}

@mixin tk-icon-campus-map() {
    content: "\EA07" 
}

@mixin tk-icon-chevron-down() {
    content: "\EA08" 
}

@mixin tk-icon-chevron-left() {
    content: "\EA09" 
}

@mixin tk-icon-chevron-right() {
    content: "\EA0A" 
}

@mixin tk-icon-chevron-up() {
    content: "\EA0B" 
}

@mixin tk-icon-close() {
    content: "\EA0C" 
}

@mixin tk-icon-download() {
    content: "\EA0D" 
}

@mixin tk-icon-external() {
    content: "\EA0E" 
}

@mixin tk-icon-home() {
    content: "\EA0F" 
}

@mixin tk-icon-letter() {
    content: "\EA10" 
}

@mixin tk-icon-mail() {
    content: "\EA11" 
}

@mixin tk-icon-marker() {
    content: "\EA12" 
}

@mixin tk-icon-navicon() {
    content: "\EA13" 
}

@mixin tk-icon-news() {
    content: "\EA14" 
}

@mixin tk-icon-phone() {
    content: "\EA15" 
}

@mixin tk-icon-print() {
    content: "\EA16" 
}

@mixin tk-icon-profile() {
    content: "\EA17" 
}

@mixin tk-icon-remove() {
    content: "\EA18" 
}

@mixin tk-icon-rss() {
    content: "\EA19" 
}

@mixin tk-icon-search() {
    content: "\EA1A" 
}

@mixin tk-icon-share() {
    content: "\EA1B" 
}

@mixin tk-icon-social-facebook() {
    content: "\EA1C" 
}

@mixin tk-icon-social-google() {
    content: "\EA1D" 
}

@mixin tk-icon-social-instagram() {
    content: "\EA1E" 
}

@mixin tk-icon-social-linkedin() {
    content: "\EA1F" 
}

@mixin tk-icon-social-twitter() {
    content: "\EA20" 
}

@mixin tk-icon-social-weibo() {
    content: "\EA21" 
}

@mixin tk-icon-social-youtube() {
    content: "\EA22" 
}

@mixin tk-icon-sort() {
    content: "\EA23" 
}

@mixin tk-icon-star() {
    content: "\EA24" 
}

@mixin tk-icon-triangle-down() {
    content: "\EA25" 
}

@mixin tk-icon-triangle-left() {
    content: "\EA26" 
}

@mixin tk-icon-triangle-right() {
    content: "\EA27" 
}

@mixin tk-icon-triangle-up() {
    content: "\EA28" 
}

@mixin tk-icon-action-add() {
    content: "\EA29" 
}

@mixin tk-icon-action-bookmark() {
    content: "\EA2A" 
}

@mixin tk-icon-action-call() {
    content: "\EA2B" 
}

@mixin tk-icon-action-cancel() {
    content: "\EA2C" 
}

@mixin tk-icon-action-create() {
    content: "\EA2D" 
}

@mixin tk-icon-action-delete() {
    content: "\EA2E" 
}

@mixin tk-icon-action-download() {
    content: "\EA2F" 
}

@mixin tk-icon-action-link-external() {
    content: "\EA30" 
}

@mixin tk-icon-action-lock() {
    content: "\EA31" 
}

@mixin tk-icon-action-pause() {
    content: "\EA32" 
}

@mixin tk-icon-action-play() {
    content: "\EA33" 
}

@mixin tk-icon-action-print() {
    content: "\EA34" 
}

@mixin tk-icon-action-remove() {
    content: "\EA35" 
}

@mixin tk-icon-action-reply() {
    content: "\EA36" 
}

@mixin tk-icon-action-search() {
    content: "\EA37" 
}

@mixin tk-icon-action-upload() {
    content: "\EA39" 
}

@mixin tk-icon-content-bar() {
    content: "\EA3A" 
}

@mixin tk-icon-content-basket() {
    content: "\EA3B" 
}

@mixin tk-icon-content-bbq() {
    content: "\EA3C" 
}

@mixin tk-icon-content-bed() {
    content: "\EA3D" 
}

@mixin tk-icon-content-bike() {
    content: "\EA3E" 
}

@mixin tk-icon-content-bus() {
    content: "\EA3F" 
}

@mixin tk-icon-content-cafe() {
    content: "\EA40" 
}

@mixin tk-icon-content-calendar() {
    content: "\EA41" 
}

@mixin tk-icon-content-couch() {
    content: "\EA42" 
}

@mixin tk-icon-content-email-open() {
    content: "\EA43" 
}

@mixin tk-icon-content-email() {
    content: "\EA44" 
}

@mixin tk-icon-content-fitness() {
    content: "\EA45" 
}

@mixin tk-icon-content-home() {
    content: "\EA46" 
}

@mixin tk-icon-content-hospital() {
    content: "\EA47" 
}

@mixin tk-icon-content-key() {
    content: "\EA48" 
}

@mixin tk-icon-content-location() {
    content: "\EA49" 
}

@mixin tk-icon-content-map() {
    content: "\EA4A" 
}

@mixin tk-icon-content-music() {
    content: "\EA4B" 
}

@mixin tk-icon-content-news() {
    content: "\EA4C" 
}

@mixin tk-icon-content-parking() {
    content: "\EA4D" 
}

@mixin tk-icon-content-restaurant() {
    content: "\EA4E" 
}

@mixin tk-icon-content-rss() {
    content: "\EA4F" 
}

@mixin tk-icon-content-shower() {
    content: "\EA50" 
}

@mixin tk-icon-content-table-tennis() {
    content: "\EA51" 
}

@mixin tk-icon-content-tag() {
    content: "\EA52" 
}

@mixin tk-icon-content-train() {
    content: "\EA53" 
}

@mixin tk-icon-content-tv() {
    content: "\EA54" 
}

@mixin tk-icon-content-user() {
    content: "\EA55" 
}

@mixin tk-icon-content-videogame() {
    content: "\EA56" 
}

@mixin tk-icon-content-wc() {
    content: "\EA57" 
}

@mixin tk-icon-content-wifi() {
    content: "\EA58" 
}

@mixin tk-icon-device-desktop() {
    content: "\EA59" 
}

@mixin tk-icon-device-laptop() {
    content: "\EA5A" 
}

@mixin tk-icon-device-phone() {
    content: "\EA5B" 
}

@mixin tk-icon-form-check-checked() {
    content: "\EA5C" 
}

@mixin tk-icon-form-check-unchecked() {
    content: "\EA5D" 
}

@mixin tk-icon-form-radio-checked() {
    content: "\EA5E" 
}

@mixin tk-icon-form-radio-unchecked() {
    content: "\EA5F" 
}

@mixin tk-icon-nav-arrow-dropdown-down() {
    content: "\EA60" 
}

@mixin tk-icon-nav-arrow-dropdown-up() {
    content: "\EA61" 
}

@mixin tk-icon-nav-chevron-down() {
    content: "\EA62" 
}

@mixin tk-icon-nav-chevron-left() {
    content: "\EA63" 
}

@mixin tk-icon-nav-chevron-right() {
    content: "\EA64" 
}

@mixin tk-icon-nav-chevron-up() {
    content: "\EA65" 
}

@mixin tk-icon-nav-more-horizontal() {
    content: "\EA66" 
}

@mixin tk-icon-nav-more-vertical() {
    content: "\EA67" 
}

@mixin tk-icon-nav-navicon() {
    content: "\EA68" 
}

@mixin tk-icon-nav-select() {
    content: "\EA69" 
}

@mixin tk-icon-content-vr() {
    content: "\EA6A" 
}

@mixin tk-icon-device-tablet() {
    content: "\EA6B" 
}

@mixin tk-icon-nav-chevron-left-large() {
    content: "\EA6C" 
}

@mixin tk-icon-nav-chevron-right-large() {
    content: "\EA6D" 
}

@mixin tk-icon-nav-chevron-down-large() {
    content: "\EA6E" 
}

@mixin tk-icon-nav-chevron-up-large() {
    content: "\EA6F" 
}

@mixin tk-icon-content-washing-machine() {
    content: "\EA70" 
}

@mixin tk-icon-content-credit-card() {
    content: "\EA71" 
}

@mixin tk-icon-content-sport() {
    content: "\EA72" 
}

@mixin tk-icon-content-security() {
    content: "\EA73" 
}

@mixin tk-icon-content-build() {
    content: "\EA74" 
}

@mixin tk-icon-content-document() {
    content: "\EA75" 
}

@mixin tk-icon-action-filter() {
    content: "\EA76" 
}

@mixin tk-icon-action-card() {
    content: "\EA77" 
}

@mixin tk-icon-action-list() {
    content: "\EA78" 
}

@mixin tk-icon-action-cancel-thin() {
    content: "\EA79" 
}

@mixin tk-icon-action-share-circle() {
    content: "\EA7A" 
}

@mixin tk-icon-action-play-circle-outline() {
    content: "\EA7B" 
}

@mixin tk-icon-content-announcement() {
    content: "\EA7C" 
}

@mixin tk-icon-nav-dots-horizontal() {
    content: "\EA80" 
}

@mixin tk-icon-content-marker() {
    content: "\EA81" 
}

@mixin tk-icon-content-security-home() {
    content: "\EA82" 
}

@mixin tk-icon-content-ping-pong() {
    content: "\EA83" 
}

@mixin tk-icon-content-bank() {
    content: "\EA84" 
}
