.tk-title-content-logo {
    margin: 10px -10px 1em;
    overflow: auto;
}

    .tk-title-content-logo__image-container {
        text-align: center;

        @media (max-width: $screen-sm) {
            margin-top: 13px;
            text-align: left;
            max-width: calc(50% + 10px);
        }
        .cms & a > img,
        .cms & > img {
            border: none;
            padding: 0;
            margin: 0;

            &:hover {
                border: none;
            }
        }

        @media (min-width: $screen-sm) {
            .cms & a > img,
            .cms & > img {
                margin: 0 auto;
            }
        }

        p {
            display: none;
        }
    }

    .tk-title-content-logo__image {

        @media (max-width: $screen-sm) {
            
        }
    }

    .tk-title-content-logo__content-image-container {
        clear: both;
    }

    .tk-title-content-logo__text-container {

    }

    .tk-title-content-logo__title {
        font-size: 24px;
        width: 100%;
        text-align: left;
        margin-bottom: 6px;

        @media (max-width: $screen-sm) {
            font-size: 20px;
        }

        &:before {
            width: 100%;
            left: 0;
        }

    }

    .tk-title-content-logo__content {
        padding-top: 12px;
    }