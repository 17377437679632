/**
 * $LOCAL HEADER
 */

 .local-header {
    color: white;
    background: $color-local-header;
    z-index: $zindex-navbar;

    body.business & {
        background: $color-masthead;
        padding-bottom: 6px;
        position: relative;


        &:after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 6px;
            background: $color-lubs-corp;
            background-image:
            linear-gradient(
                to right, 
                $color-lubs-corp,
                $color-lubs-corp    (100% / 7),
                $color-lubs-masters (100% / 7),
                $color-lubs-masters (100% / 7 * 2),
                $color-lubs-phd     (100% / 7 * 2),
                $color-lubs-phd     (100% / 7 * 3),
                $color-lubs-od      (100% / 7 * 3),      
                $color-lubs-od      (100% / 7 * 4),       
                $color-lubs-mba     (100% / 7 * 4), 
                $color-lubs-mba     (100% / 7 * 5),
                $color-lubs-ug      (100% / 7 * 5),
                $color-lubs-ug      (100% / 7 * 6),
                $color-lubs-exec-ed (100% / 7 * 6),
                $color-lubs-exec-ed 100%
            );
        }
    }

    body.business-section--ug & {
        &:after {
            background: $color-lubs-ug;
            background-image:
            linear-gradient(
                to right, 
                $color-lubs-ug,
                $color-lubs-ug              (100% / 7),
                $color-lubs-ug-secondary    (100% / 7),
                $color-lubs-ug-secondary    (100% / 7 * 2),
                $color-lubs-ug-tertiary     (100% / 7 * 2),
                $color-lubs-ug-tertiary     (100% / 7 * 3),
                $color-lubs-ug              (100% / 7 * 3),      
                $color-lubs-ug              (100% / 7 * 4),       
                $color-lubs-ug-secondary    (100% / 7 * 4), 
                $color-lubs-ug-secondary    (100% / 7 * 5),
                $color-lubs-ug-tertiary     (100% / 7 * 5),
                $color-lubs-ug-tertiary     (100% / 7 * 6),
                $color-lubs-ug              (100% / 7 * 6),
                $color-lubs-ug               100%
            );
        }
    }

    body.business-section--masters & {
        &:after {
            background: $color-lubs-masters;
            background-image:
            linear-gradient(
                to right, 
                $color-lubs-masters,
                $color-lubs-masters              (100% / 7),
                $color-lubs-masters-secondary    (100% / 7),
                $color-lubs-masters-secondary    (100% / 7 * 2),
                $color-lubs-masters-tertiary     (100% / 7 * 2),
                $color-lubs-masters-tertiary     (100% / 7 * 3),
                $color-lubs-masters              (100% / 7 * 3),      
                $color-lubs-masters              (100% / 7 * 4),       
                $color-lubs-masters-secondary    (100% / 7 * 4), 
                $color-lubs-masters-secondary    (100% / 7 * 5),
                $color-lubs-masters-tertiary     (100% / 7 * 5),
                $color-lubs-masters-tertiary     (100% / 7 * 6),
                $color-lubs-masters              (100% / 7 * 6),
                $color-lubs-masters               100%
            );
        }
    }

    body.business-section--phd & {
        &:after {
            background: $color-lubs-phd;
            background-image:
            linear-gradient(
                to right, 
                $color-lubs-phd,
                $color-lubs-phd              (100% / 7),
                $color-lubs-phd-secondary    (100% / 7),
                $color-lubs-phd-secondary    (100% / 7 * 2),
                $color-lubs-phd-tertiary     (100% / 7 * 2),
                $color-lubs-phd-tertiary     (100% / 7 * 3),
                $color-lubs-phd              (100% / 7 * 3),      
                $color-lubs-phd              (100% / 7 * 4),       
                $color-lubs-phd-secondary    (100% / 7 * 4), 
                $color-lubs-phd-secondary    (100% / 7 * 5),
                $color-lubs-phd-tertiary     (100% / 7 * 5),
                $color-lubs-phd-tertiary     (100% / 7 * 6),
                $color-lubs-phd              (100% / 7 * 6),
                $color-lubs-phd               100%
            );
        }
    }

    body.business-section--mba & {
        &:after {
            background: $color-lubs-mba;
            background-image:
            linear-gradient(
                to right, 
                $color-lubs-mba,
                $color-lubs-mba              (100% / 7),
                $color-lubs-mba-secondary    (100% / 7),
                $color-lubs-mba-secondary    (100% / 7 * 2),
                $color-lubs-mba-tertiary     (100% / 7 * 2),
                $color-lubs-mba-tertiary     (100% / 7 * 3),
                $color-lubs-mba              (100% / 7 * 3),      
                $color-lubs-mba              (100% / 7 * 4),       
                $color-lubs-mba-secondary    (100% / 7 * 4), 
                $color-lubs-mba-secondary    (100% / 7 * 5),
                $color-lubs-mba-tertiary     (100% / 7 * 5),
                $color-lubs-mba-tertiary     (100% / 7 * 6),
                $color-lubs-mba              (100% / 7 * 6),
                $color-lubs-mba               100%
            );
        }
    }

    body.business-section--exec-ed & {
        &:after {
            background: $color-lubs-exec-ed;
            background-image:
            linear-gradient(
                to right, 
                $color-lubs-exec-ed,
                $color-lubs-exec-ed              (100% / 7),
                $color-lubs-exec-ed-secondary    (100% / 7),
                $color-lubs-exec-ed-secondary    (100% / 7 * 2),
                $color-lubs-exec-ed-tertiary     (100% / 7 * 2),
                $color-lubs-exec-ed-tertiary     (100% / 7 * 3),
                $color-lubs-exec-ed              (100% / 7 * 3),      
                $color-lubs-exec-ed              (100% / 7 * 4),       
                $color-lubs-exec-ed-secondary    (100% / 7 * 4), 
                $color-lubs-exec-ed-secondary    (100% / 7 * 5),
                $color-lubs-exec-ed-tertiary     (100% / 7 * 5),
                $color-lubs-exec-ed-tertiary     (100% / 7 * 6),
                $color-lubs-exec-ed              (100% / 7 * 6),
                $color-lubs-exec-ed               100%
            );
        }
    }

    body.business-section--corp & {
        &:after {
            background: $color-lubs-exec-ed;
            background-image:
            linear-gradient(
                to right, 
                $color-lubs-corp,
                $color-lubs-corp              (100% / 7),
                $color-lubs-corp-secondary    (100% / 7),
                $color-lubs-corp-secondary    (100% / 7 * 2),
                $color-lubs-corp-tertiary     (100% / 7 * 2),
                $color-lubs-corp-tertiary     (100% / 7 * 3),
                $color-lubs-corp              (100% / 7 * 3),      
                $color-lubs-corp              (100% / 7 * 4),       
                $color-lubs-corp-secondary    (100% / 7 * 4), 
                $color-lubs-corp-secondary    (100% / 7 * 5),
                $color-lubs-corp-tertiary     (100% / 7 * 5),
                $color-lubs-corp-tertiary     (100% / 7 * 6),
                $color-lubs-corp              (100% / 7 * 6),
                $color-lubs-corp               100%
            );
        }
    }

    body.business-section--od & {
        &:after {
            background: $color-lubs-exec-ed;
            background-image:
            linear-gradient(
                to right, 
                $color-lubs-od,
                $color-lubs-od              (100% / 7),
                $color-lubs-od-secondary    (100% / 7),
                $color-lubs-od-secondary    (100% / 7 * 2),
                $color-lubs-od-tertiary     (100% / 7 * 2),
                $color-lubs-od-tertiary     (100% / 7 * 3),
                $color-lubs-od              (100% / 7 * 3),      
                $color-lubs-od              (100% / 7 * 4),       
                $color-lubs-od-secondary    (100% / 7 * 4), 
                $color-lubs-od-secondary    (100% / 7 * 5),
                $color-lubs-od-tertiary     (100% / 7 * 5),
                $color-lubs-od-tertiary     (100% / 7 * 6),
                $color-lubs-od              (100% / 7 * 6),
                $color-lubs-od               100%
            );
        }
    }
}


.local-header-inner {
    display: table;
    width: 100%;
    a {
        color: white;
    }
}

/**
 * The site title
 */

.local-header-title {
    display: table-cell;
    vertical-align: middle;
    padding: 10px 40px 10px 0;

    > a {
        font-size: 18px;

        margin: 0;
        font-family: $font-family-sans-serif;

        @media (min-width: $screen-xs-min) {
            font-size: 18px;
        }

        @media (min-width: $screen-sm-min) {
            font-size: 20px;
        }

        .tk-icon {
            vertical-align: middle;
        }
    }

    a {
        border: 0;
        color: white;
        &:hover {
            color: white;
            text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.5);
        }
    }
}

    .local-header-title__heading {
        font-size: 18px;
        margin-bottom: 0;
        
        @media (min-width: $screen-sm-min) {
            font-size: 20px;
        }
    }

/**
 * The local header search button 
 * to trigger the serach dropdown
 */

.local-header-search {
    display: table-cell;
    text-align: right;
    vertical-align: middle;
    @media (min-width: $screen-xs-min) {
        font-size: 20px;
    }
}

.sm-toggle-search {
    transition: background 0.25s ease;

    @media (min-width: $screen-xs-min) {
        display: inline-block;
        padding: 6px 12px;
    }

    &:hover {
        background: $color-brand-1-dark;
    }

    .no-js & {
        display: none;
    }
}

.site-search-btn:before {
    @include tk-icon();
    @include tk-icon-search();
    font-size: 22px;
    position: relative;
    top: -2px;
}

.active .site-search-btn:before {
     @include tk-icon-close();
}

/**
 * The local header call-to-ation button
 * As seen on library
 */

.local-header-cta {
    display: table-cell;
    text-align: right;
    vertical-align: middle;
    height: 100%;

    a {
        background: $color-brand-1-dark;
        padding: 10px;
        text-transform: uppercase;
        font-size: 15px;
        display: inline-block;
        transition: background 0.25s ease;

        @media (min-width: $screen-sm-min) {
            font-size: 16px;
        }

        &:hover {
            background: $color-brand-1;
            text-decoration: none;
        }
    }
}

/**
 * The local header nav 
 * As seen on courses/oci
 */

.local-header-nav {
    display: table-cell;
    text-align: right;
    vertical-align: middle;
    height: 100%;

    ul {
        margin: 0;
        padding: 0;
        overflow: hidden;
        float: right;
    }

    li {
        float: left;
        list-style: none;
    }

    li.active a {
        background: $color-brand-1-dark;
    }

    a {
        display: block;
        background: $color-brand-1;
        padding: 13px;
        text-transform: uppercase;
        font-size: 15px;
        transition: background 0.25s ease;

        @media (min-width: $screen-sm-min) {
            font-size: 16px;
        }

        &:hover {
            background: $color-brand-1-dark;
            color: $color-white;
            text-decoration: none;
        }
    }
}
