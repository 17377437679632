/**
 * Skins
 */

/*

This file is a bit of a mess
many depracted skin names as we havent
been constistent in their use
Avoid extending these use mixins instead

the rule now is:

.skin-"shorted proterty"-"value"-"modifier"

e.g. for a light brand background we use: .skin-bg-brand-light

when combining different styles create a unique name e.g.

.skin-box-module {
    background: $color-module;
    @include tk-box-shadow-dark();
}

*/

/**
 * Classes
 */

.sk-bg-white, //deprecated
.skin-bg-white {
    background: $color-white !important;
}

.sk-bg-module, //deprecated
.skin-bg-module {
    background: $color-module;
}

.sk-bg-module-light, //deprecated
.skin-bg-module-light, {
    background: $color-module-light;
}

.skin-bg-module-dark, {
    background: $color-module-dark;
}

.skin-bg-module-lighter, {
    background: $color-module-lighter;
}

.sk-bg-brand-1, //deprecated
.skin-bg-brand-1 {
    background: $color-brand-1;
}

.sk-bg-brand-1-dark, //deprecated
.skin-bg-brand-1-dark {
    background: $color-brand-1-dark;
}

.skin-bg-brand-2 {
    background: $color-brand-2;
}

.skin-bg-masthead { 
    background: $color-masthead;
}

.skin-bg-masthead-light { 
    background: $color-masthead-light;
}

//rows
.skin-bg-beige {
    background: $color-beige;
}

.skin-bg-dark {
    background: $color-masthead;
    color: white;
    a {
        color: white;
        &:hover {
            color: $color-brand-2;
        }
    }
}

@media screen and (min-width: 768px) {
    .skin-bg-dark--desktop {
        background: $color-masthead;
        color: white;
        a {
            color: white;
            &:hover {
                color: $color-brand-2;
            }
        }
    }
}

/**
 * Rows
 * Rows of content mostly the widgets
 */

.sk-widget-block, //deprecated
.skin-widget-block, //deprecated
.skin-row-module {
    background: $color-module;
}

.skin-row-module-light {
    background: $color-module-light;
}

.skin-row-module-lighter {
    background: $color-module-lighter;
}

.skin-row-white-divider, //depracated
.skin-row-divider{
    border-top: 1px solid $color-module-dark;
    border-bottom: 1px solid $color-module-dark;
}

//divider

.divider,
.skin-divider {
    border-bottom: 1px solid $color-border;
}

/**
 * Skins for boxes
 * Cards and islands
 */

.bg-white, //deprecated
.skin-box-white {
    @include skin-box-white();
}

.skin-box-pale {
    background-color: $color-module-light;
}

.island-bg-module, //deprecated
.bg-module, //deprecated
.skin-box-module {
    @include skin-box-module();
}

.skin-box-module-border {
    @include skin-box-module-border();
}

.skin-box-dark {
    @include skin-box-dark();
}

/**
 * Border styles
 * Mostly applied to cards and island search forms
 * Must appear after box styles as needs to be more specific for borders
 */

.bd-t, //deprecated
.skin-bd-t {
    @include border-top-brand-2();
}

.bd-r, //deprecated
.skin-bd-r, {
    @include border-right-brand-2();
}

.bd-b, //deprecated
.skin-bd-b, {
    @include border-bottom-brand-2();
}

.bd-l, //deprecated
.skin-bd-l {
    @include border-left-brand-2();
}

// LUBS specific 
.skin-bd-b--lubs-ug {
    border-bottom-color: $color-lubs-ug;
}

.skin-bd-b--lubs-masters {
    border-bottom-color: $color-lubs-masters;
}

.skin-bd-b--lubs-mba {
    border-bottom-color: $color-lubs-mba;
}

.skin-bd-b--lubs-phd {
    border-bottom-color: $color-lubs-phd;
}

.skin-bd-b--lubs-exec-ed {
    border-bottom-color: $color-lubs-exec-ed;
}

