
/* Skin mixins*/

//box-shadow: 0 1px 2px rgba(0,0,0,.1);         
//Shadows

/**
 * For white module on a grey background
 */

@mixin tk-box-shadow-light(){    
    box-shadow: 0 1px 2px rgba(0,0,0,.17);         
}

/**
 * For grey modules on a white background
 */

@mixin tk-box-shadow-dark(){    
    box-shadow: 0 1px 2px rgba(0,0,0,.12);         
}

/* Grey box with a shadow */

@mixin skin-box-module(){
	background: $color-module;
    @include tk-box-shadow-dark();
}

/* Grey box with a shadow and a border */

@mixin skin-box-module-border(){
	background: $color-module;
	border: 1px solid $color-border-light;     
    @include tk-box-shadow-dark();
}

/* White box with a shadow */

@mixin skin-box-white() {
    background: $color-white;
    @include tk-box-shadow-light();
}

/* Black box with a shadow */

@mixin skin-box-dark() {
    background: $color-masthead;
    color: white;
    @include tk-box-shadow-dark();
}
