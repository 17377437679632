.announcement {
    position: relative;
    overflow: hidden;
    //background: $color-brand-2;
}

.announcement-close {
    position: absolute;
    top: 25px;
    transform: translateY(-50%);
    right: 10px;
    background: none;
    border: 0;
    z-index: 1;
}

.announcement-inner {

    border: 2px solid $brand-danger;
    margin: 10px;
    padding: 6px 25px 6px 60px;
    border-radius: 3px;
    position: relative;
    min-height: 40px;

    p {
        padding: 6px 10px;
        margin: 0;
        padding: 0;
        color: $color-font;
        font-size: 16px;
        line-height: 140%;
        a {
            color: $color-font;
            border-bottom: 1px solid $color-border;
            &:hover {
                text-decoration: none;
            }
        }

    }
}

.announcement-icon {
    background:$brand-danger;
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 100%;

    .tk-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -16px;
        margin-left: -16px;
        color: white;

    }
}
