
/* Breadcrumb decoration */

.breadcrumb {	    
    font-size: 13px;        
    line-height: 120%;
    margin-bottom: 0;
    overflow-y: scroll;
    white-space: nowrap;
    letter-spacing: 0.05em;      
    text-transform: uppercase;      

    @media(min-width: $screen-xs-min){
        line-height: 140%;                
    }

    @media(min-width: $screen-sm-min){
        overflow: visible;
        white-space: normal;
    }

    @media (min-width: $screen-md-min) {
        margin-bottom: $space-half;        
    }

    a {
        &:hover {
            text-decoration: none;
            color: $color-brand-2;
            border-bottom: 1px solid $color-brand-2;
        }
    }

    
}

.breadcrumb > li+li:before {
    padding: 0 1px;
    @media(min-width: $screen-xs-min){
        padding: 0 2px 0 5px;
    }   
}

.breadcrumb-responsive {

    position: relative;

    .breadcrumb {
        overflow: visible;        
    }

    //has got some hidden elemnst
    &.active {
        li:last-child {            
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    //button has clicked to reveal all
    &.full {
        padding: 0;        

        .breadcrumb {        
            white-space: normal;
            display: block;
        }

        .breadcrumb li {
            display: inline-block;
            white-space: normal;
        }
    }

    .breadcrumb-more  {
        background: $color-module-dark;
        color: $color-font;
        padding: 0 10px;
        margin: 6px 8px 0 0;        
        color: black;
        border-radius: 3px;        
        vertical-align: top;        
        border: 0;        
        left: 0;
        overflow: hidden;
        float: left;
        font-size: 14px;
    }

    .breadcrumb {                
        font-size: 13px;        
        line-height: 120%;
        margin-bottom: 0;        
        letter-spacing: 0.05em;      
        text-transform: uppercase; 
        display: flex;

        li {
            //display: table-cell;
            white-space: nowrap;
        }     

        a {
            color: $color-font-light;
        }

        .active {
            color: $color-font;
            font-weight: $font-weight-semibold;
        }
    }
}