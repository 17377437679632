/**
 * @author zhixin wen <wenzhixin2010@gmail.com>
 */

.ms-parent {
    display: inline-block;
    position: relative;
    vertical-align: middle;
}

.ms-choice {
    display: block;
    width: 100%;
    height: 26px;
    padding: 0;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid #aaa;
    text-align: left;
    white-space: nowrap;
    line-height: 26px;
    color: #444;
    text-decoration: none;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background-color: #fff;
}

.ms-choice.disabled {
    background-color: #f4f4f4;
    background-image: none;
    border: 1px solid #ddd;
    cursor: default;
}

.ms-choice > span {
    position: absolute;
    top: 0;
    left: 0;
    right: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    padding-left: 8px;
}

.ms-choice > span.placeholder {
    color: #999;
}

.ms-choice > div {
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 25px;
    background: url('multiple-select.png') left top no-repeat;
}

.ms-choice > div.open {
    background: url('multiple-select.png') right top no-repeat;
}

.ms-drop {
    width: 100%;
    overflow: hidden;
    display: none;
    margin-top: -1px;
    padding: 0;
    position: absolute;
    z-index: 1000;
    background: #fff;
    color: #000;
    border: 1px solid #aaa;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.ms-drop.bottom {
    top: 100%;
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
}

.ms-drop.top {
    bottom: 100%;
    box-shadow: 0 -4px 5px rgba(0, 0, 0, 0.15);
}

.ms-search {
    display: inline-block;
    margin: 0;
    min-height: 26px;
    padding: 4px;
    position: relative;
    white-space: nowrap;
    width: 100%;
    z-index: 10000;
}

.ms-search input {
    width: 100%;
    height: auto !important;
    min-height: 24px;
    padding: 0 20px 0 5px;
    margin: 0;
    outline: 0;
    font-family: sans-serif;
    font-size: 1em;
    border: 1px solid #aaa;

    border-radius: 0;
    box-shadow: none;
    background: #fff url('multiple-select.png') no-repeat 100% -22px;
    background: url('multiple-select.png') no-repeat 100% -22px,
        linear-gradient(top, #ffffff 85%, #eeeeee 99%);
}

.ms-search,
.ms-search input {
    box-sizing: border-box;
}

.ms-drop ul {
    overflow: auto;
    margin: 0;
    padding: 5px 8px;
}

.ms-drop ul > li {
    list-style: none;
    display: list-item;
    background-image: none;
    position: static;
}

.ms-drop ul > li .disabled {
    opacity: 0.35;
    filter: Alpha(Opacity=35);
}

.ms-drop ul > li.multiple {
    display: block;
    float: left;
}

.ms-drop ul > li.group {
    clear: both;
}

.ms-drop ul > li.multiple label {
    width: 100%;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ms-drop ul > li label {
    font-weight: normal;
    display: block;
    white-space: nowrap;
}

.ms-drop ul > li label.optgroup {
    font-weight: bold;
}

.ms-drop input[type='checkbox'] {
    vertical-align: middle;
}

.ms-drop .ms-no-results {
    display: none;
}

/////overwrite

/* Over write plugins code to fit styles */

.ms-choice {
    border-radius: 0;
    height: 40px;
    min-height: 40px;
    margin: 0 !important; //Remove css applied to button by toolkit
    border: 1px solid $color-border;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    background: $color-form
        url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEwAACxMBAJqcGAAAAFxJREFUKJHNzcENRGAUReGvALRAqFgTFuqQ2M80gOiDDcnLH8FiFnOW756Tx99SoEV+s2Xx2GPDkEQFxmPrYtBgSaIoT6jS1zH64HsnX0WPcoxWzG/kkxrlW/m37BqBF+nMeae3AAAAAElFTkSuQmCC')
        center right 8px no-repeat;

    line-height: normal;
    text-transform: none;
    letter-spacing: normal;

    .svg & {
        background: $color-form
            url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiIgdmlld0JveD0iLTI1IDI3IDEyIDEyIj48cGF0aCBmaWxsPSJub25lIiBzdHJva2U9IiMwMDAiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBkPSJNLTE0LjggMzAuOWwtNC4yIDQuMi00LjItNC4yIi8+PC9zdmc+')
            center right 8px no-repeat;
    }

    padding-left: 12px;

    & > div {
        background: none;
    }
    & > span {
        letter-spacing: normal;
        top: 10px;
    }
}

.ms-drop {
    background: $color-white;
    border: 1px solid $color-border;
    border-radius: 0px;
}

.ms-drop ul {
    padding: 0px 8px;
}

.ms-drop ul > li {
    border-top: 1px solid $color-border;
    &:last-of-type {
    }
    &:first-of-type {
        border-top: 0px solid $color-border;
        label {
            //padding-top: 5px;
        }
    }
    label {
        margin: 0;
        padding: 5px 0;
        input {
            margin-right: 10px;
            margin-top: 0;
        }
    }
}
.ms-search input {
    background: none;
}

.ms-drop ul > li label input {
    margin: 0px 5px 5px 0;
}
