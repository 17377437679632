/*------------------------------------*\
    $FORMS
\*------------------------------------*/

//Temp var until the ui review and color scheme change
$color-form: #f8f7f6;
//$color-form: $color-module-light;

/**
 * Forms - TODO improve consistent sizing on screens
 */

form {
    line-height: 160%;
}

//Remove IE10's various form styles including “clear field” X button and select arrow
*::-ms-clear {
    display: none;
}

%shared-form {
    margin: 0;
    margin-bottom: $space-half; //is later removed lower down if in a .form-group
    max-width: 100%;
    border-radius: 0;
    border: 1px solid $color-border;
    transition: all 0.25s ease;
}


/**
 * Labels
 */

.label {
    font-family: $font-family-sans-serif;
    font-weight: $font-weight-book;
    border-radius: 0;
}

label {
    margin-bottom: $space-half/2;
    font-weight: $font-weight-book;

    // Radio button alignment fix
    &.radio {
        margin-left: 20px;
    }
}

legend {
    font-size: 16px;
    @media(min-width: $screen-xs-min) {
        font-size: 18px;
    }
    @media(min-width: $screen-sm-min) {
        font-size: 22px;
    }
}


/**
 * Forms
 */

select,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"] {
    //prevent iOS zoom on form input focus
    font-size: 16px;
}


/**
 * All inputs
 */

input,
textarea,
select {
    @extend %shared-form;
    &:disabled {
        @include form-state-disabled();
    }
    &:focus {
        @include form-state-focus();
    }
}

textarea {
    min-height: 100px;
}


/**
 * Box inputs
 */

input[type="text"],
input[type="email"],
input[type="search"],
input[type="password"],
input[type="number"],
input[type="date"],
input[type="tel"],
input[type="url"],
textarea {
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
    width: 100%;
    display: block;
    padding: $space-half/2 $space-half;
    min-height: 40px;
    -webkit-appearance: none; // Remove default shadow
    -moz-appearance: none;
    .lt-ie9 & {
        //Remove min height in ie9 as it causes weird display
        min-height: 0;
    } // Larger input
    &.tk-input-lg {
        @media (min-width: $screen-sm-min) {
            min-height: 50px;
            @include placeholder {
                font-size: 18px;
            }
        }
    }
}


/**
 * Button inputs
 * TODO: Extends .btn class, refactor to include a mixin
 * NB use <button> when possible as it's more flexible
 */

input[type="submit"],
input[type="button"],
input[type="reset"] {
    @include button();
    width: 100%;
    max-width: 100%; //.main & { // force a button style when used in the WYSIWYG
    border: 1px solid $btn-primary-border;
    color: $btn-primary-color;
    background: $btn-primary-bg; //margin-top: $space-half/2;
    margin-bottom: $space-half/2; //}
}


/**
 * Selects
 */

select,
.select {
    display: block;
    min-height: 40px;
    width: 100%;
    height: 40px;
    padding: 0 $space*2 0 $space-half;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .1);
    background: $color-form url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEwAACxMBAJqcGAAAAFxJREFUKJHNzcENRGAUReGvALRAqFgTFuqQ2M80gOiDDcnLH8FiFnOW756Tx99SoEV+s2Xx2GPDkEQFxmPrYtBgSaIoT6jS1zH64HsnX0WPcoxWzG/kkxrlW/m37BqBF+nMeae3AAAAAElFTkSuQmCC") center right 8px no-repeat;
    -webkit-appearance: none; //Remove default shadow
    -moz-appearance: none;
    &::-ms-expand {
        //IE10 remove stupid arrow, do we need this?
        display: none;
    }
    .svg & {
        background: $color-form url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIxLjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxMiAxMiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTIgMTI7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDpub25lO30KPC9zdHlsZT4KPHBhdGggZD0iTTkuOCwyLjlMNiw2LjdMMi4yLDIuOUwxLDQuMWw1LDVsNS01TDkuOCwyLjl6Ii8+CjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0tNi0zNmgyNHYyNEgtNlYtMzZ6Ii8+Cjwvc3ZnPgo=") center right 8px no-repeat;
        background-size: 12px;
    }
    .lt-ie9 & {
        background-image: none;
        padding: 0;
        padding-bottom: 8px;
        min-height: 0;
    }
    &.tk-select-lg {
        @media (min-width: $screen-sm-min) {
            min-height: 50px;
            font-size: 18px;
        }
    }
}

select[class*="input-"] {
    //overwrite BS lineheight
    line-height: 100%;
}

select[multiple] {
    height: auto;
    background: none;
    &:-webkit-scrollbar {
        margin-top: $space-half;
        -webkit-appearance: none;
        width: 14px;
        height: 14px;
    }
    &:-webkit-scrollbar-thumb {
        border-radius: 8px;
        margin: 5px;
        border: 3px solid white;
        background: rgba(black, 0.5);
    }
}
//See _multi-select for more
.multi-select {
    margin: 4px 0;
}

select.select-dark {
    background: $btn-primary-bg;
    color: white;
    border: 1px solid $btn-primary-border;
    font-size: 14px;
    @media(min-width: $screen-xs-min) {
        font-size: 16px;
    }
    .svg & {
        background: $btn-primary-bg url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiIgdmlld0JveD0iLTEgMyAxMiAxMiIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAtMSAzIDEyIDEyIj48cGF0aCBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBkPSJNOS4yIDYuOUw1IDExLjEuOCA2LjkiLz48L3N2Zz4=") center right 8px no-repeat;
    }
}


/**
 * Checkboxes, radio buttons and file uploads
 */

input[type="checkbox"],
input[type="radio"],
input[type="file"] {
    margin-top: $space-half/2;
    margin-right: $space-half/2;
    margin-bottom: $space-half;
    border: 0;
}

input[type="file"] {
    max-width: 250px;
}


/**
 * Custom radios and checkboxes using tk-icons
 */

//used on research opportunities/degrees
.input-tk-check {
    input[type="radio"],
    input[type="checkbox"] {
        position: absolute;
        left: -999999em;
        width: 1px;
        height: 1px;
        overflow: hidden;
    } //Labels
    label {
        display: block;
        padding: 0 $space/2 0 0;
        margin: 0;
        cursor: pointer;
        transition: background 0.25s ease;
        &:before {
            @include tk-icon();
            font-size: 24px;
            color: $color-brand-2;
        }
    }

    //accessible
    input:focus + label {
        @include focus-outline;
    }

    .no-outline & input:focus + label {
        @include focus-no-outline;
    }


    //Radio
    input[type="radio"]+label {
        &:before {
            @include tk-icon-form-radio-unchecked();
        }
    }
    //Radio active
    input[type="radio"]:checked+label {
        &:before {
            @include tk-icon-form-radio-checked();
            color: $color-font;
        }
    } //Check
    input[type="checkbox"]+label {
        &:before {
            @include tk-icon-form-check-unchecked();
        }
    } //check active
    input[type="checkbox"]:checked+label {
        &:before {
            @include tk-icon-form-check-checked();
            color: $color-font;
        }
    }
}

.lt-ie9 {
    .input-tk-check {
        width: auto;
        float: none;
        border-bottom: 1px solid $color-border;

        input[type="radio"] {
            position: static;
            width: auto;
            height: auto;
        }
        label {
            background: none;
            display: inline;
        }
        input[type="radio"]:checked+label {
            background: none;
            color: $color-font;
        }
    }
}


/* Forms elements inside of islands */

[class*="island"] {
    input,
    select,
    button,
    .btn {
        margin: $space-half/2 0; //is later removed lower down if in a .form-group
    }
}

/**
 * Custom toolkit form groupings
 */

.tk-form-inline {
    // Kick in the inline
    // Inline-block all the things for "inline"
    @media (min-width: $screen-xs-min) {
        .form-group {
            display: inline-block;
            margin-bottom: 0;
            vertical-align: middle;
        }
    }
}

.tk-form-inline-divider {
    @media (min-width: $screen-xs-min) {
        .form-group {
            padding-right: 10px;
            margin-right: 10px;
            position: relative;
            &:after {
                content: "";
                height: 20px;
                width: 1px;
                background: $color-border;
                position: absolute;
                right: 0;
                top: 5px;
            }
            &:last-child:after {
                display: none;
            }
        }
    }
}

.tk-form-row {
    margin-left: -10px;
    margin-right: -10px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    [class*="col-"] {
        //float: none;
    }
}

// Extends BS dropdown menu with checkbox filter, used on faculty people index
.dropdown-checkbox-menu {
    label {
        margin: 0;
        cursor: pointer;
    }
    .divider {
        margin: 0;
        padding: 0;
    }
    li {
        padding: 2px $space-xs $space-xs/2 $space-xs;
        margin: 0;
        white-space: nowrap;
        cursor: pointer;
        &:hover {
            background: $color-module;
            cursor: pointer;
        }
    }
}