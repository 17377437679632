/**
 * Custom row class that repliactes .row - for Jadu because row is used in the control center
 */

.tk-row { //use this intead of row because fo jadu
    @extend .clearfix;
    margin-left:  ceil(($grid-gutter-width / -2)); // replicating @include make-row;
    margin-right: floor(($grid-gutter-width / -2));
    position: relative;
}

//Flexbox modifier
.tk-row-flex {
    display: flex;
    flex: 1 0 auto;
    flex-direction: row;
    flex-wrap: wrap;

    //remove the extended clearfix as it  messes with safari flex
    &:before,
    &:after {
       display: none;
    }

}

.tk-row-flex-reverse {
    flex-direction: row-reverse;
}

.tk-row-flex-bottom {
    align-items: flex-end;
}

/* Grid with a flush gutter */
.row-flush,
.tk-row-flush { //flush grid
	margin: 0;
    padding: 0;
    @extend .clearfix;

	[class*="col-"] {
		padding: 0;
	}
}

/* Grid with a reduce gutter */

.row-reduce-gutter > [class*='col-'] { //reduce the gutter on the columns
    padding-left: $space-half;
    padding-right: $space-half;
}

