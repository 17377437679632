/**
 * Main Navingation
 */

/**
 * Masthead Navincon
 */

.navicon {
    position: absolute;
    bottom: 10px;
    left: 8px;
    height: 0;
    opacity: 0;
    .state-nav-desktop &,
    .state-nav-mobile & {
        height: auto;
        opacity: 1;
    }
    .state-nav-mobile & {
        //@media (min-width: $screen-xs-min) {
        bottom: 8px;
        left: 8px;
        transition: left 0.3s ease 0.2s; //}
    }
    .state-nav-desktop & {
        // @media (min-width: $screen-xs-min) {
        display: none; //}
    }
    button {
        text-transform: uppercase;
        color: white;
        border: 2px solid white;
        display: inline-block;
        font-size: 14px;
        background: $color-masthead;
        &:before {
            @include tk-icon();
            @include tk-icon-navicon();
            margin-right: 4px;
            font-size: 16px;
            position: relative;
            float: left;
            top: 1px;
        }
    }
}

.state-navicon-active .navicon {
    button {
        &:before {
            @include tk-icon-close();
        }
    }
}

/**
 * Navigation
 */

.tk-nav {
    //shared styles accross breakpoints
    background: $color-nav-bar;
    position: relative;
    opacity: 0;
    height: 0;
    z-index: $zindex-navbar;
    .state-nav-desktop &,
    .state-nav-mobile & {
        opacity: 1;
        height: auto;
    }

    .no-js & {
        opacity: 1;
        height: auto;

        .tk-nav-header {
            display: none;
        }

        ul {
            margin-left: 10px;
        }

        li {
            list-style: disc;
            margin-left: 10px;
        }
        a {
            padding: 0;
        }
    }

    ul {
        padding: 0;
        margin: 0;
    }

    li {
        list-style: none;
    }

    a {
        padding: 10px 10px;
        color: $color-font;
        font-size: 15px;
        transition: background 0.25s ease;

        .js & {
            display: none;
        }
        
        &:hover,
        &:focus {
            text-decoration: none;
        }
    }
    li.heading a {
        font-weight: $font-weight-semibold;
    }
    li.list-divider a {
        padding: 0 !important;
        border-bottom: 1px solid $color-border !important;
    }
    .tk-nav-header {
        //hidden navicon header, used to close flyout
        background: $color-masthead-light;
        button {
            color: white;
            padding: 3px 6px;
            text-transform: uppercase;
            border: 2px solid white;
            display: inline-block;
            font-size: 14px;
            margin: 6px;
            &:before {
                @include tk-icon();
                @include tk-icon-close();
                position: relative;
                float: left;
                top: 2px;
                margin-right: 4px;
            }
        }
    }
    .tk-nav-inner {
        margin: 0px;
        z-index: $zindex-navbar;
    }
    .tk-nav-list {
        .tk-nav-dropdown > a {
            position: relative;
        }
        .tk-nav-dropdown > a:after {
            @include tk-icon();
            right: 5px;
            position: absolute;
            font-size: 16px;
            top: 50%;
            margin-top: -8px;
        }
        .tk-nav-dropdown > a:after {
            @include tk-icon-triangle-down();
        }
        .tk-nav-dropdown.active > a:after {
            @include tk-icon-triangle-up();
        }
    }
}

/**
 * Navigation Priority
 */

/**
 * Desktop Nav
 */

//@media (min-width: $screen-xs-min) { //mobile up
.state-nav-desktop {
    .tk-nav {
        .tk-nav-header {
            display: none;
        }
        .tk-nav-list {
            //main ul
            /* Top Level li, a */
            > li {
                display: inline-block;
                margin-left: -3px;
            }
            > li > a {
                display: block;
                position: relative;
                text-transform: uppercase;
                &:hover,
                &:focus {
                    text-decoration: none;
                    background: $color-module-darker;
                }
            }
            /**
			 * Top level dropdown
			 */
            > li.tk-nav-dropdown {
                position: static; //overide BT remove later
                > a:after {
                    position: relative;
                    font-size: 16px;
                    top: 2px;
                    left: 3px;
                }
                > ul {
                    z-index: $zindex-dropdown;
                    width: 100%;
                    left: 0;
                    transform: rotateX(-90deg);
                    transform-origin: 0 0;
                    background: #fff;
                    position: absolute;
                    transition: all 0 ease;
                    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
                    border-top: 0;
                    overflow: hidden;

                    @media (min-width: $screen-xs-min) {
                        column-count: 2;
                        padding: 12px 24px 24px;

                        li {
                            // padding: 12px 0 8px 8px;
                            width: 100%;
                            break-inside: avoid;
                            opacity: 0;

                            a {
                                padding-left: 6px;
                                display: none;
                            }
                        }
                    }

                    @media (min-width: $screen-md) {
                        column-count: 3;
                    }
                }

                &.tk-nav-dropdown--items-1,
                &.tk-nav-dropdown--items-2,
                &.tk-nav-dropdown--items-3,
                &.tk-nav-dropdown--items-4,
                &.tk-nav-dropdown--items-5,
                &.tk-nav-dropdown--items-6 {
                    > ul {
                        @media (min-width: $screen-xs-min) {
                            column-count: 1;
                        }

                        @media (min-width: $screen-md) {
                            padding-right: calc((100% / 3 * 2) + 36px * 2);
                        }
                    }
                }

                &.tk-nav-dropdown--items-7,
                &.tk-nav-dropdown--items-8,
                &.tk-nav-dropdown--items-9,
                &.tk-nav-dropdown--items-10,
                &.tk-nav-dropdown--items-11,
                &.tk-nav-dropdown--items-12
                 {
                    > ul {
                        @media (min-width: $screen-xs-min) {
                            column-count: 2;
                        }

                        @media (min-width: $screen-md) {
                            padding-right: calc(100% / 3 + 36px * 2);
                        }
                    }
                }

            }

            > li.tk-nav-dropdown.active {
                background: $color-white;
                > a {
                    background: $color-white;
                }
                > ul {
                    left: 0;
                    width: 100%;
                    transform: rotateX(0deg);

                    li {
                        opacity: 1;
                        transition: opacity .2s ease-in-out;
                        transition-delay: .2s;

                        a {
                            display: block;
                        }
                    }

                }
            }
            /* Generic Level li,a and .tk-nav-dropdowns */
            ul li a {
                border-bottom: 1px solid $color-border;
                padding: $space-half 15px;
                &:hover {
                    background: $color-module;
                }
            }
            ul li.tk-nav-dropdown {
                overflow: hidden;
                z-index: 5;
                > a:after {
                    position: relative;
                    margin-left: 10px;
                    top: 3px;
                }
                ul {
                    //teux deux accessible
                    display: none;
                    background: rgba(#19130d, 0.05);
                    border-left: 4px solid rgba($color-brand-2, 1);
                }
            }
            ul li.tk-nav-dropdown.active {
                > a {
                    background: white;
                }
                > ul {
                    //display: block; handled by slide down
                }
            }
        }
    }
    .tk-nav-flyout {
        .tk-nav-list {
            //main ul
            /**
			 * Dropdown with columns
             */
            //all
            li.tk-nav-dropdown.active > .tk-nav-dropdown-inner {
                display: none;
                ul {
                    display: block;
                }
            } //top level
            > li.tk-nav-dropdown {
                //container generated and wrapped around ul's
                > .tk-nav-dropdown-inner {
                    z-index: $zindex-navbar;
                    left: -9999px;
                    background: #fff;
                    position: absolute;
                    transition: opacity 0.25s ease;
                    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
                    border-top: 0;
                    padding: 10px 0;
                    ul {
                        border-right: 1px solid $color-border;
                        position: static;
                    }
                    li a {
                        border-bottom: 0;
                    }
                }
            }
            > li.tk-nav-dropdown.active {
                > .tk-nav-dropdown-inner {
                    left: 0;
                    width: 100%; //display: flex;
                    ul {
                        float: left;
                    }
                }
            }
        }
    }
    .tk-nav-priority {
        .tk-nav-list {
            display: inline-table;
            > li {
                display: table-cell;
                white-space: nowrap;
            }
        }
    }
}

//@media (max-width: $screen-xs-min - 1) { //mobile down
.state-nav-mobile {
    &.state-navicon-active .tk-nav {
        margin-left: 0;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    }
    .tk-nav {
        width: 100%;
        height: 100%;
        position: fixed;
        background: $color-module;
        z-index: 100;
        top: 0;
        left: 0;
        max-width: 100%;
        margin-left: -100%;
        transition: margin 0.25s ease;
        margin-bottom: 20px;
        @media (min-width: $screen-xs-min) {
            width: 330px;
        }
        @media (min-width: $screen-sm-min) {
            //width: 25%;
        }
        .wrapper-relative {
            overflow-y: scroll;
            -webkit-overflow-scrolling: touch;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
        }
        .tk-nav-inner {
            max-width: 100%;
            list-style: none;
            margin: 0;
            padding: 0;
            width: 100%;
            transition: all 0.2s ease;
        }
        .tk-nav-list {
            overflow: auto;
            a {
                display: block;
                border-bottom: 1px $color-border solid;
                border-color: rgba(black, 0.1);
            }
            ul {
                border-left: 4px solid $color-brand-2;
                background: rgba(black, 0.1);
            }
            li.tk-nav-dropdown > ul,
            li.tk-nav-dropdown > .tk-nav-dropdown-inner > ul {
                // display: none;
                transform-origin: 0 0;
                visibility: hidden;
                transform: rotateX(-90deg);
                height: 0;
                transition: all ease .25s;

                li {
                    opacity: 0;
                }

                &.active {
                    transform: rotateX(0deg);
                    height: auto;
                    visibility: visible;
                    transition: all ease .25s;

                    li {
                        opacity: 1;
                    }
                }
            }
            li.tk-nav-dropdown.active > ul,
            li.tk-nav-dropdown.active > .tk-nav-dropdown-inner > ul {

            }
        }
    }
    .tk-nav-flyout {
        .tk-nav-list {
            li.tk-nav-dropdown > .tk-nav-dropdown-inner {
                display: none;
                ul {
                }
            }
            li.tk-nav-dropdown.active > .tk-nav-dropdown-inner {
                ul {
                    display: block;
                }
            }
        }
    }
}

/**
 * CTA nav
 */

.tk-nav {
    .tk-nav-list-cta > li > a:after {
        @include tk-icon();
        @include tk-icon-chevron-right();
        margin-left: 5px;
        position: relative;
        top: 2px;
        color: $color-brand-2;
    }
}

.state-nav-desktop {
    //mobile up
    .tk-nav {
        .tk-nav-list-cta {
            float: right;
            > li > a {
                background: $color-masthead;
                color: white;
                padding: 10px 20px;
                &:hover {
                    background: $color-masthead-dark;
                }
                &:after {
                    display: none;
                }
            }
        }
    }
}

.state-nav-mobile {
    //mobile down
    .tk-nav {
        .tk-nav-list-cta {
            border-top: 3px solid $color-brand-2;
            > li > a {
                background: $color-module-dark;
                &:after {
                    margin-left: 5px;
                    position: relative;
                    top: 2px;
                    color: $color-brand-2;
                    float: right;
                }
            }
        }
    }
}

/**
 * CTA nav
 */

.tk-nav {
    .tk-nav-list-cta > li > a:after {
        @extend .tk-icon;
        @extend .tk-icon-chevron-right:before;
        margin-left: 5px;
        position: relative;
        top: 2px;
        color: $color-brand-2;
    }
}

@media (min-width: $screen-xs-min) {
    //mobile up

    .tk-nav {
        .tk-nav-list-cta {
            float: right;

            > li > a {
                background: $color-masthead;
                color: white;
                padding: 10px 20px;
                &:hover {
                    background: $color-masthead-dark;
                }
                &:after {
                    display: none;
                }
            }
        }
    }
}

@media (max-width: $screen-xs-min - 1) {
    //mobile down

    .tk-nav {
        .tk-nav-list-cta {
            border-top: 3px solid $color-brand-2;
            > li > a {
                background: $color-module-dark;

                &:after {
                    margin-left: 5px;
                    position: relative;
                    top: 2px;
                    color: $color-brand-2;
                    float: right;
                }
            }
        }
    }
}
