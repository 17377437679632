.fact {
    text-align: center;
    padding: 0rem 1rem 0.8rem 1rem;
    margin: 0 0 0.8rem 0;
    position: relative;
    &:after {
        position: absolute;
        bottom: 0px;
        left: 50%;
        content: '';
        margin-left: -45px;
        width: 90px;
        height: 1px;
        background: $color-brand-2;
    }
}

.heading-fact {
    font-size: 4.8rem;
    margin: 0;
    padding: 1rem 0 1rem 0;
    color: $color-brand-2;
    font-family: $font-family-serif;

    span {
        font-size: 6.4rem;
        line-height: 0;
        font-variant-numeric: normal;
        font-feature-settings: normal;
    }

    &.heading-fact-sm {
        span {
            font-size: 4.8rem;
        }
    }
}

.lead-fact {
    font-family: $font-family-serif;
    font-style: italic;
    font-size: 2.4rem;
    line-height: 1.4;
    margin: 0 0 0.8rem 0;
}
