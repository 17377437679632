/**
 * Forms Mixins
 */

@mixin hide-accessibly() {
    position: absolute;    
    clip: rect(1px, 1px, 1px, 1px);    
    height: 1px;
    width: 1px;
    overflow: hidden;
}

//Reserve hide accessibly e.g. for active state
@mixin hide-accessibly-off() {
    position: static;    
    clip: auto;    
    height: auto;
    width: auto;
    overflow: visible;
}

/**
 * Form states
 */

/**
 * We remove outline but js toggles class 
 * so accessible outlines exist when tabbing through
 */

@mixin form-state-focus() { //$TEUXDEUX remove important
    background-color: $color-module-light;
    border: 1px solid $color-border-dark !important;
    //outline: 0  !important;
    box-shadow: 0 0 3px rgba($color-brand-2, 1) !important;
}

@mixin form-state-disabled() {
    background-color: $color-module;
    border-style: dotted;
    color: $color-font-lighter;
}

/**
 * Type for form element
 * Keep labels and information in forms other than input consistent
 */

@mixin form-type {
    font-size: 16px;
}