/**
 * University masthead
 */

.masthead {
    @extend .clearfix;
    background: $color-masthead;
    position: relative; //for absolutely position navicon
}

.site-logo {
    float: right;
    position: relative;
    overflow: hidden;
    width: 32px;
    height: 32px;
    margin: 6px;
    @media (min-width: $screen-xs-min) {
        margin: 10px $space-xs 10px 15px;
        width: 183px; //for ie svg size
        height: 53px;
    }
    img {
        width: 183px;
        position: absolute;
        max-width: none;
        right: 0;
        top: 0;
        @media (min-width: $screen-xs-min) {
            width: 100%;
            position: static; //clip-path: inset(0 0px 20px 139px);
        }
    }
}

//deprecated
.logo {
    //logo container
    float: right;
    margin: 6px;
    max-width: 183px; //for ie svg size
    @media (min-width: $screen-xs-min) {
        margin: 10px 10px 10px 15px;
    }
}

.logo-full {
    //full logo with mark and type
    display: none;
    @media (min-width: $screen-xs-min) {
        display: block;
    }
}

.logo-mark {
    //mobile logo, just the logo mark
    @media (min-width: $screen-xs-min) {
        display: none;
    }
}

.masthead-links {
    //small masthead link container
    //fade effect
    height: 0;
    opacity: 0;
    .state-nav-desktop &,
    .state-nav-mobile & {
        height: auto;
        opacity: 1;
        position: absolute;
        z-index: 10;
    }
    .state-nav-mobile & {
        border-top: 1px solid $color-masthead-light;
        background: $color-masthead-dark;
        margin-top: -2px;
        bottom: 0;
    }
    .state-nav-desktop & {
        float: left;
        background: none;
        margin-left: $space-xs;
        bottom: -27px;
    }
}

.masthead-link {
    //small masthead link inline
    color: white;
    font-size: 13px;
    display: inline-block;
    padding: 5px 6px 6px 6px;
    background: none;
    border: 0;
    border-right: 1px solid $color-masthead-light;
    .state-nav-desktop & {
        border: 0;
        padding: 2px 3px;
    }
    &:hover,
    &:focus {
        color: white;
    }
}

.masthead-link-quicklinks,
.masthead-link-button {
    //featured masthead link e.g. quicklinks trigger
    text-transform: uppercase;
    font-size: 13px;
    background: $color-masthead-dark;
    .state-nav-desktop & {
        padding: 4px 6px;
        font-size: 14px;
        border: 1px solid $color-white;
        border-top: 0;
    }
}

.masthead-link-quicklinks {
    &:after {
        @include tk-icon;
        @include tk-icon-16;
        @include tk-icon-triangle-down;
        position: relative;
        top: 0px;
        left: 2px;
    }
    .no-js & {
        display: none;
    }
}

.state-quicklinks-active .masthead-link-quicklinks {
    margin-top: -1px;
    &:after {
        @extend .tk-icon-triangle-up:before;
    }
}
