// Publications
.publications {
    margin-bottom: 32px;
    .types, .years {
        display: block;
        ul {
            list-style-type: none;
            display: inline-block;
            padding: 0;
            margin-bottom: 12px;

            li {
                display: inline;
                padding-right: 8px;

                &:last-child {
                    padding: 0;
                }

                .active {
                    font-weight: bold;
                }
            }
        }
    }

    .types {
        border-bottom: 1px solid $dropdown-divider-bg;
        margin-bottom: 12px;
    }

    .years {
        margin-top: 12px;
        margin-bottom: 0;
    }

    .pub-list.active {
        animation: fadeFromRight 1s normal;
    }

    .single-pub {
        margin-bottom: 16px;
        font-size: $font-size-base;
        line-height: $line-height-base;
        display: block;

        &:last-child {
            margin-bottom: 0;
        }

        &.hide {
            display: none;
        }
    }

    .date, .pub-message {
        margin-top: 24px;
    }
}

@keyframes fadeFromRight {
    0%   {
        opacity: 0;
    }
    20% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}