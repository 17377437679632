/**
 * $SITESEARCH
 */

// See .state-site-search-active applied to _layout.scss

.site-search {	
	background: white;
	@media (max-width: $screen-xs-min) {
		//display: none !important; //overide .collapse
	}	
}

	.site-search-inner {	
		@extend .clearfix;			   
		padding: 5px 0; 			
	}

		.site-search-input,
		.site-search-select,
		.site-search-submit {
			float: left;
		}		

		input.site-search-input {
			border: 1px solid $color-border;
			padding: 5px;								
			width: 54%;		
			margin: 0 1% 0 0;
			@media (max-width: $screen-xs-min){
				width: 100%;
				margin: 0 0 5px 0;
			}
		}

		.lt-ie9 input.site-search-input {
			height: 40px;			
		}

		select.site-search-select {						
			padding: 5px 25px 5px 10px;			
			width: 29%;	
			margin: 0 1% 0 0;
			min-height: 40px;
			@media (max-width: $screen-xs-min){
				width: 75%;				
				margin-bottom: 0;
			}
		}

		.lt-ie9 select.site-search-select {
			min-height: 0;
			padding: 0;
			padding-bottom: 8px;
		}

		.no-js .site-search-select {
			display: none;
		}

		input.site-search-submit {
			border: 0;
			padding: 5px 10px;	
			margin: 0;
			width: 15%;				
			@media (max-width: $screen-xs-min){
				width: 24%;		
				margin-bottom: 0;		
			}	
		}		
