/**
 * Customisation of the Owl swiper
 */

/**
 * Default Swiper

+-----------------------+
|                       |
|                       |
|                       |
|                       |
+-----------------------+
| Content               |
+-----------------------+

*/

.no-js .swiper {
    opacity: 1;
}

.swiper {
    //default
    background: $color-masthead;
    opacity: 0;
    /* opacity 0 until init */
    transition: opacity 0.1s;
    position: relative; //Layout
    .slide {
        width: 100%;
    }
    .slide-inner {
        display: table;
        width: 100%;
    }
    /*
      simple inline list that wraps (see single residence quickfacts)
    */
    .slide-content-footer {
        background: rgba($color-masthead-dark, 0.55);
        text-transform: uppercase;
        padding: $space;
        color: $color-white;
        position: relative;
        z-index: 1;
        ul {
            padding: 0;
            margin: 0;
        }
        span {
            color: $color-brand-2;
            padding-right: 5px;
        }
        li {
            display: inline-block;
            &:after {
                content: '|';
                padding: 0 5px;
                color: $color-brand-2;
            }
            &:first-of-type,
            &:last-of-type {
                &:after {
                    content: '';
                }
            }
        }
    }
    .slide-content {
        display: table-footer-group;
        background: $color-masthead;
    }
    .slide-content-inner {
        padding: $space;
        @media (min-width: $screen-md-min) {
            padding: $space-lg;
        }
    }
    .slide-img,
    .slide-video {
        width: 100%;
        padding-bottom: 50%;
        display: table-cell;
        background-size: cover;
        background-position: center;
    }

    .slide-img {
        img {
            display: none;
        }
    }

    .slide-video {
        overflow: hidden;
        position: relative;
        video {
            display: none;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            min-height: 100%;
            min-width: 100%;
            @media (min-width: $screen-md-min) {
                display: block;
            }
        }
    }
    //Typography
    .slide-cta {
        color: white;
        text-transform: uppercase;

        &:after {
            @include tk-icon();
            @include tk-icon-chevron-right();
            color: $color-brand-2;
            margin-left: 3px;
        }
    }
    .slide-heading {
        color: white;
        font-size: 20px;
        margin-bottom: $space-half;
        @media (min-width: $screen-sm-min) {
            font-size: 26px;
            font-family: $font-family-big;
        }
        @media (min-width: $screen-md-min) {
            font-size: 32px;
        }
    }
    .slide-lead {
        color: white;
        margin-bottom: $space-half;
        font-size: 16px;
        @media (min-width: $screen-sm-min) {
            font-size: 18px;
        }
        @media (min-width: $screen-md-min) {
            margin-bottom: $space;
            font-family: $font-family-big;
            font-size: 22px;
        }
        br {
            display: none;
            @media (min-width: $screen-md-min) {
                display: block;
            }
        }
    }
}

@keyframes pulseBottom {
    0% {
        bottom: 16px;
    }
    50% {
        bottom: 8px;
    }
    100% {
        bottom: 16px;
    }
}

/**
 * Swiper 1

+----------+---------------+
|          |               |
|          |               |
|          |               |
| Content  |               |
+----------+---------------+

*/

.swiper-1 {
    @media (min-width: $screen-md-min) {
        //Layout
        .slide-content {
            display: table-cell;
            width: 33%; // decimal points creates a smal line so just use ints
            vertical-align: bottom;
        }
        .slide-img,
        .slide-video {
            display: table-cell;
            width: 67%;
            padding-bottom: 33%;
        } //Typography
        .slide-cta {
            padding: $space 0 0 0;
            display: block;
            font-size: 14px;
            border-top: 1px solid $color-brand-2;
            display: block;
            font-size: 18px;
        }
        .slide-heading {
            margin: 0 0 10px 0;
            font-size: 26px;
            &.char-below-half {
                //char limit 75
                font-size: 32px;
            }
        }
        .slide-lead {
            font-size: 18px;
            line-height: 140%;
            &.char-below-half {
                //char limit 200
                font-size: 22px;
            }
        }
        .slide-lead-sm {
            font-size: 14px;
            font-size: 18px;
            line-height: 140%;
            font-family: $font-family-sans-serif;
        }
    }
    @media (min-width: $screen-lg-min) {
        .slide-heading {
            font-size: 32px;
            &.char-below-half {
                //char limit 75
                font-size: 38px;
            }
        }
        .slide-lead {
            font-size: 21px;
            line-height: 150%;
            &.char-below-half {
                //char limit 75
                font-size: 24px;
            }
        }
    }
}

/**
 * Swiper 2

+-----------------------+
|                       |
|                       |
|        Content        |
|                       |
|                       |
+-----------------------+

*/

.swiper-2 {
    @media (min-width: $screen-md-min) {
        // Layout
        position: relative;

        .slide {
            position: relative;
            //Gradient mask on slide
            &:after {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%; //background: linear-gradient(to bottom, rgba($color-masthead-dark,0) 0%, rgba($color-masthead-dark,0.8) 100%);
                background: $color-mask; //ie9 fallback
                background: linear-gradient(
                    to bottom,
                    rgba($color-masthead, 0) 0%,
                    rgba($color-masthead, 1) 100%
                );
                top: 0;
                left: 0;
                z-index: 0;
            }
        }

        .slide-inner {
            position: relative;

            //Scroll more button
            &:before,
            &:after {
                content: '';
                position: absolute;
                left: 50%;
                z-index: 1000;
                transform: translateX(-50%);
            }

            &:before {
                animation: pulseBottom 2s;
                animation-iteration-count: infinite;
                bottom: 16px;
                width: 48px;
                height: 48px;
                background: url('../img/banner-scroll-lg.svg') center no-repeat;
            }

            &:after {
                bottom: 48px;
                content: 'Scroll';
                color: white;
                text-transform: uppercase;
            }
        }
        .slide-content {
            padding: 7% 15%;
            background: none;
            z-index: 1;
            text-align: center;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
        } // Typography
        .slide-cta {
            border: 1px solid white;
            padding: 10px;
            background: rgba(33, 33, 33, 0.7);
            padding: 12px 48px;
            display: inline-block;
            &:after {
                display: none;
            }
        }
        .slide-heading {
            //width: 100%;
            text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.65);
            font-size: 40px;
            position: relative;
            margin-bottom: 15px;
            display: inline-block;
            line-height: 120%;
            &:before {
                content: '';
                position: absolute;
                width: 70%;
                height: 1px;
                bottom: -8px;
                left: 15%;
                background-color: $color-brand-2;
            }
            &.char-below-half {
                //char limit 75
                font-size: 56px;
                line-height: 100%;
            }
        }
        .slide-lead {
            font-size: 22px;
            line-height: 120%;
            text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.65);
            margin-bottom: 28px;
            &.char-below-half {
                //char limit 200
                font-size: 30px;
            }
        }
        .slide-sub-lead {
            color: #fff;
            text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.65);
            font-size: 20px;
            margin-bottom: $space-xl;
        }
    }
    @media (min-width: $screen-lg-min) {
        .slide-content {
            padding: 6% 25%;
        }
    }
    //Handle swiper 2 parallax
    @media (max-width: $screen-md-max) {
        .slide-img {
            background-position: center !important;
        }
    }
    @media (min-width: $screen-lg-min) {
        .slide-img {
            //works with parrallax effect
            background-position: 0 -20px;
        }
        .slide-img,
        .slide-video {
            padding-bottom: 40%;
        }
    }
}

//???
.lt-ie9 .swiper-2 .slide-inner:after {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEUhISFeAxSwAAAAAXRSTlOzEo46UAAAAApJREFUCB1jYAAAAAIAAc/INeUAAAAASUVORK5CYII=);
}

/**
 * Left alignment per slide
 */

.slide-align-left {
    @media (min-width: $screen-md-min) {
        .slide-heading {
            &:before {
                width: 100%;
                left: 0;
            }
        }
        .slide-content {
            width: 50%;
            text-align: left;
            top: 0;
            padding: 10% 0 0 0;
        }
        .flexbox & .slide-content {
            display: flex;
            align-items: center;
            padding: 0;
        }
        .slide-content-inner {
            padding: $space-lg 0px $space-lg 65px;
        }
    }
    @media (min-width: ($site-container-md+100)) {
        .slide-content-inner {
            padding: $space-lg;
        }
    }
}

/**
 * Swiper with form
 * Search form used in banner
 * e.g. Library banner
 */

.swiper-2.swiper-form {
    .slide-content {
        padding: 0;
        background: $color-mask-darker;
        @media (min-width: $screen-xs-min) {
            position: absolute;
            top: 50%;
            left: 50%;
            bottom: auto;
            z-index: 10;
            width: 90%;
            transform: translate(-50%, -50%);
        }
        @media (min-width: $screen-md-min) {
            padding: 0 10%;
            background: none;
        }
    }
    .slide-content-inner {
        @media (max-width: $screen-md-min) {
            padding-bottom: 0;
        }
    }
    .slide-heading {
        @media (max-width: $screen-md-min) {
            margin-bottom: 0;
        }
    }
    .slide-content-form {
        padding: $space;
        width: 100%;
        text-align: left;
        label {
            @include type-all-caps();
            color: white;
        }
        @media (min-width: $screen-md-min) {
            padding: $space-xl;
            background: $color-mask-darker;
        }
        ul {
            text-align: left;
            @media (max-width: $screen-xxs-max) {
                @include list-quicklinks();
                margin-bottom: 0;

                li {
                    font-size: 16px;
                }
            }
            @media (min-width: $screen-xs-min) {
                @include list-related();
                @include list-related-divider();
                margin: $space-xs 0 0 0;
            }
        }
    }
    a {
        color: white;
    }
    @media (min-width: $screen-lg-min) {
        .slide-img {
            padding-bottom: 33.33%;
        }
    }
}

.lt-ie9,
.ie9 {
    .swiper-2.swiper-form .slide-content {
        margin-left: -45%;
    }
}

/**
 * Swiper nav
 */

.swiper-nav-2 li {
    width: 50%;
}

.swiper-nav-3 li {
    width: 33%;
    &:last-child {
        width: 34%;
    }
}

.swiper-nav-4 li {
    width: 25%;
}

/**
 * Swiper buttons
 */

.swiper .owl-nav-custom .owl-prev,
.swiper .owl-nav-custom .owl-next {
    @include owl-button();
}

.owl-nav-custom .owl-prev,
.owl-nav-custom .owl-next {
    @include owl-button();
}

// TODO: Check if we need this level of specificity
.swiper .owl-nav-custom .owl-prev {
    @include owl-button-prev();
    @include owl-button-prev-xs();
    @media (min-width: $screen-xs-min) {
        @include owl-button-prev-sm();
    }
    @media (min-width: $screen-md-min) {
        @include owl-button-prev-md();
    }
    @media (min-width: ($screen-lg-min+80)) {
        @include owl-button-prev-lg();
    }
}

.owl-nav-custom .owl-prev {
    @include owl-button-prev();
    @include owl-button-prev-xs();
    @media (min-width: $screen-xs-min) {
        @include owl-button-prev-sm();
    }
    @media (min-width: $screen-md-min) {
        @include owl-button-prev-md();
    }
    @media (min-width: ($screen-lg-min+80)) {
        @include owl-button-prev-lg();
    }
}

//General swiper has flush button
.swiper .owl-nav-custom .owl-prev {
    @media (min-width: ($screen-lg-min+80)) {
        left: 0px;
    }
}

///Swiper 1 has medium buttons
.swiper-1 .owl-nav-custom .owl-prev {
    @media (min-width: ($screen-lg-min+80)) {
        @include owl-button-prev-md();
    }
    @media (min-width: ($site-container-md+100)) {
        @include owl-button-prev-lg();
    }
}
// TODO: Check if we need this level of specificity
.swiper .owl-nav-custom .owl-next {
    @include owl-button-next();
    @include owl-button-next-xs();
    @media (min-width: $screen-xs-min) {
        @include owl-button-next-sm();
    }
    @media (min-width: $screen-md-min) {
        @include owl-button-next-md();
    }
    @media (min-width: ($screen-lg-min+80)) {
        @include owl-button-next-lg();
    }
}

.owl-nav-custom .owl-next {
    @include owl-button-next();
    @include owl-button-next-xs();
    @media (min-width: $screen-xs-min) {
        @include owl-button-next-sm();
    }
    @media (min-width: $screen-md-min) {
        @include owl-button-next-md();
    }
    @media (min-width: ($screen-lg-min+80)) {
        @include owl-button-next-lg();
    }
}


//General swiper has flush button
.swiper .owl-nav-custom .owl-next {
    @media (min-width: ($screen-lg-min+80)) {
        right: 0px;
    }
}


///Swiper 1 has medium buttons
.swiper-1 .owl-nav-custom .owl-next {
    @media (min-width: ($screen-lg-min+80)) {
        @include owl-button-next-md();
    }
    @media (min-width: ($site-container-md+100)) {
        @include owl-button-next-lg();
    }
}

.swiper-2 .owl-nav-custom .owl-next {
    @media (min-width: ($screen-md)) {
        @include owl-button-next-lg();
        right: 0;
    }
    @media (min-width: ($site-container-md+100)) {
        right: -45px;
    }
}

.swiper-2 .owl-nav-custom .owl-prev {
    @media (min-width: ($screen-md)) {
        @include owl-button-prev-lg();
        left: 0;
    }
    @media (min-width: ($site-container-md+100)) {
        left: -45px;
    }
}

.swiper-no-nav .owl-nav-custom {
    display: none;
}

//Used on spotlight gallery
.swiper .owl-dots {
    padding: 6px;
    position: absolute;
    top: 0;
    width: 100%;
    .owl-dot {
        border: 2px solid $color-brand-2;
        background: $color-masthead;
        display: inline-block;
        height: 12px;
        width: 12px;
        margin-right: 4px;
        //border-radius: 10px;
        &.active {
            background: $color-brand-2;
        }
    }
}
    
.swiper-caption {
    color: white;
    margin: 0;
    padding: $space-xs;
    font-size: 16px;
}
/* Swiper nav (footer tabs )*/

.swiper-nav {
    @extend .clearfix;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    li {
        float: left;
        padding: 0px;
        background: $color-masthead-light;
        text-align: center;
        transition: background-color 500ms ease-out;
        &.active {
            background: $color-brand-2-dark;
        }
        a {
            color: #fff;
            text-decoration: none;
            text-transform: uppercase;
            width: 100%;
            height: 100%;
            width: 100%;
            display: block;
            text-align: center;
            padding: 15px 15px 15px 15px; //border-right: 1px solid $color-masthead;
            @media (max-width: $screen-sm-max) {
                padding: 0;
                height: 10px;
                text-indent: -9999px;
            }
            @media (max-width: $screen-xs-max) {
                height: 5px;
            }
        }
    }
}

@keyframes pulseTop {
    0% {
        top: 0px;
    }
    50% {
        top: 6px;
    }
    100% {
        top: 0px;
    }
}
