/**
 * BOOTSTRAP EXTENDS - Used to add additional styles to already existing boostrap styles
 */

/**
 * Collapse
 */

.no-js .collapse { //accessibility: shows collapsed element when javscript is turned off 	         
	display: block;    
}

.no-js .fade {
	opacity: 1;
}


/**
 * Dropdown
 * Make dropdowns accessible
 */

// [data-toggle="dropdown"] {
//     &:focus + .dropdown-menu {
//         display: block;
//         visibility: visible;
//         clip: auto;
//         left: auto;
//     }
// }

.dropdown-menu {
	display: block;
	visibility: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    position: absolute;
    left: -9999px;
}

.open > .dropdown-menu {
    display: block;
    visibility: visible;
    clip: auto;
    left: auto;
}

/**
 * Panels
 */

.panel-title {
	font-family: $font-family-sans-serif;
}

/**
 * List group items
 */

.list-group-item-heading {
	font-family: $font-family-sans-serif;	
}

/* Other */

.badge {
	padding: 2px 6px 6px 6px;
}

.container-flush {
	padding: 0;
}


/* Improve blockquote */
blockquote .small, 
blockquote footer, 
blockquote small {
	display: inline-block;
}

//removes max-width off media img
.media-object {
    max-width: none !important;      
}

//Overwrite shared form styles
.form-group {
	input,
	textarea,
	select,
	button {
		margin-bottom: 0;
	}
}
