/* Responsive Accordion */

/* .accordion-plain parent class removes the solid border and background color to the accordion content and trigger

used in:
* People single for publications
* Courses
* Keys contact on people
* Accomodation
etc

*/

/**
 * New re-factored Accordion
 */

.accordion-heading {
    @extend .lead;
}

.accordion,
.single-accordion,
.cms .accordion {
    margin-bottom: $space;

    // Paragraphs (JADU)
    p {
        font-size: 16px;
        line-height: 1.42857;
        margin-bottom: 14px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &-header {
        @include skin-box-module-border();
        padding: $space-xs $space-xl $space-xs $space-xs;
        position: relative;
        display: block;
        margin-bottom: $space-xs;

        &.active {
            background: $color-module;
            box-shadow: none;
        }

        &:after {
            @include tk-icon();
            @include tk-icon-chevron-down();
            position: absolute;
            right: 6px;
            top: 50%;
            margin-top: -8px;
            font-size: 16px;
            color: $color-font;
        }

        &.active:after {
             @include tk-icon-chevron-up();
        }

        &:hover {
            background: $color-module;
        }

        &:hover,
        &:focus {
            color: $color-font;
            cursor: pointer;
            text-decoration: none;
            border: 1px solid $color-border-dark;
        }

        &:focus {

        }
    }

    &-header-lg {
        @media (min-width: $screen-xs-min) {
            padding: $space $space-xl $space $space;
        }
    }

    &-header [class*="accordion-heading"] {
        font-family: $font-family-sans-serif;
        color: $color-font;
        margin: 0;
        padding: 0;
        line-height: 130%;
        font-size: 18px;
    }

    a[aria-expanded="true"]{
        margin: 0;
    }

    &-body {
        opacity: 0;

        transition: opacity 0.25s ease;
        background: $color-white;
        box-shadow: 0 1px 2px rgba(0,0,0,.1);

        @include hide-accessibly();

        &.active {
            opacity: 1;
            @include hide-accessibly-off();
        }

        // &.trans {
        //     opacity: 0;
        // }
    }

    &-body {
        border: 1px solid $color-border-light;
        border-top: 0;
        margin-top: -$space-xs;
        margin-bottom: $space-xs;
        padding: $space-xs;
    }

    &-table {
        display: table;
        width: 100%;
        color: $color-font;

        span {
            display: table-cell;
        }
    }

    &-footer {
        border-top: 1px solid $color-border-light;
        margin-top: $space-xs;
        padding-top: $space-xs;
    }

    // JADU Accordions (different markup)
    .accordion-heading {

        .accordion-header {
            font-family: $font-family-sans-serif;
            color: $color-font;
            margin: 0;
            padding: 0;
            line-height: 130%;
            font-size: 18px;
            padding: 10px 26px 10px 10px;
        }
    }
}

// Single Accordion (JADU) fix - bit messy but it's just JADU
.single-accordion {
    margin-bottom: 10px !important;
}

.no-js {
    .accordion-body {
        display: block;
        opacity: 1;
    }
}


.accordion-alt {
    .accordion-header {
        background: none;
        border: 0;
        border-bottom: 1px solid $color-border;
        padding-left: 0;
        box-shadow: none;
        margin: 0;
        &:last-of-type {
            border-bottom: 1px solid $color-border;
        }
    }

    [class*="accordion-heading"]  {
        @include type-all-caps();
    }

    .accordion-body {
        border: 0;
        border-bottom: 1px solid $color-border;
        box-shadow: none;
        padding: $space 0;
        margin: 0;
    }
}

.no-js .accordion .accordion-body {
    display: block;
    opacity: 1;
}
