/**
 * iFrame Modal - Used for login page of third party uni systems
 */

/* iPhone iOS Fix */

body.modal-open {
    //overflow-y: auto;
    padding-right: 0px !important;
}

/* Custom XL modal */

.modal-iframe {
    .modal-dialog {
        height: 100%;
        margin: 0;
        @media (min-width: $screen-sm-min) {
            min-width: 100%;
        }
    }
    .modal-content {
        position: relative;
        max-width: 100%;
        border: 0;
    }
    .modal-header {
        padding: $space-half;
    }
    .modal-body {
        padding: $space-half;
    }
    .modal-footer {
    }
}

/* See scrollable iframes https://davidwalsh.name/scroll-iframes-ios */

.iframe-container {
    position: relative;
    min-height: 400px;
    width: 100%;
    height: 100%;
    display: inline-block;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    @media (min-width: $screen-sm-min) {
        min-height: 600px;
    }
}

.iframe-container iframe {
    max-width: 100%;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
}
