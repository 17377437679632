
//Transitions

// How to use: 
// basic: 
// @include transition(); 
// animate margin and make duration slow
// @include transition(margin, $duration: slow);
// animate background and change properties
// @include transition(background, $duration: 3s, $effect: ease-in, $delay: 3s);

@mixin tk-transition($property:all, $duration: 0.5s, $effect: ease, $delay: 0) {

    //Property
    transition-property: $property;

    //Duration
    @if $duration == slow {
        transition-duration: 1s;
    }
    @else if $duration == fast {
        transition-duration: 0.25s;
    }
    @else {
        transition-duration: $duration;
    }

    //Custom Bezier
    @if $effect == zoom {
        transition-timing-function: cubic-bezier(0.7,0,0.3,1);
    }
    //Effect - See http://easings.net/
    @else if $effect == easeInExpo {
        transition-timing-function: cubic-bezier(0.95, 0.05, 0.795, 0.035);
    }
    @else if $effect == easeInBack {
        transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
    }
    @else if $effect == easeInOutBack {
        transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }   
    @else {
        transition-timing-function: $effect;
    }

    transition-delay: $delay;
}
