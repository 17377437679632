/*------------------------------------*\
    $LISTS
\*------------------------------------*/

/**
 * Deals with list styles. NB does not style lists in the .cms wrapper
 * amongst typography. See _typography.scss partial for that.
 * Visit tk-components.html#lists for an overview
 */

/**
 * No style list helper class
 */

.list-no-style {
    @include list-no-style();
}

/**
 * List nav
 * A divider list that often sits on top of an island
 * tk-components.html#nav-list
 */

.list-nav {
    @include list-no-style();
    padding: $space-half 0;
    margin-bottom: $space-half;
    border-bottom: 1px solid $color-border;
    li {
        display: inline-block;
        margin-right: 8px;
    }
    a {
        color: $color-font;
        text-transform: uppercase;
        &:hover {
            color: $color-link;
        }
    }
    .tk-icon {
        margin-left: 5px;
    }
    .other-nav, /*deprecated*/
    .title,
    .list-title {
        font-style: italic;
        color: $color-font-light;
    }
}

/**
 * List related
 * All caps inline list
 * tk-components.html#related-list
 * Used on Banner with search box, research opp index and scholarships
 * TODO: check if still in use for the latter 2
 */

.list-related {
    @include list-related();

    a {
        color: $color-font-light;
    }
}

/**
 * List related modifier
 * Add pipes between each of list items
 * Used on research opp index and scholarships
 * TODO: check if still in use
 */

.list-related-divider {
    @include list-related-divider();
}

/**
 * List facts
 * List of ket facts
 * often used inside islands
 */

.key-facts, /*deprecated*/
.list-facts {
    @include list-no-style();
    .title, /*deprecated*/
    .list-title {
        text-transform: uppercase;
        font-weight: $font-weight-book;
    }
    .list-label {
        font-weight: $font-weight-semibold;
    }
    li {
        line-height: 140%;
        font-size: 15px;
        &:before {
            position: relative;
            content: '-';
            left: -5px;
            color: $color-brand-2;
        }
        @media (min-width: $screen-xs-min) {
            font-size: 16px;
            line-height: 160%;
            padding: 5px 0;
            &:before {
                display: none;
            }
        }
    }
}

/**
 * List featured
 * Used Accommodation single residence
 * TODO: possiblys remove, is it suitable for toolkit
 */

.list-centered {
    @include list-no-style();
    @extend .p;
    text-align: center;
    padding-bottom: $space-lg;
}

/**
 * List headings
 * Used in expandle card components on dir index scholarships
 */

.list-headings {
    margin: 0;
    padding: 0;
    .list-heading {
        display: block;
    }
    li {
        list-style: none;
        margin-bottom: $space;
        padding: 0 0 0 $space-xs;
        font-size: 18px;
        position: relative;
        &:before {
            content: '-';
            position: absolute;
            left: 0;
            top: 0;
        }
    }
}

/**
 * List special
 * Used on
 */

.list-featured-item {
    font-family: $font-family-serif;
    margin: 0 0 $space 0;

    display: block;
    position: relative;

    .list-featured-item-inner {
        position: relative;
        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0px;
            left: 0px;
            height: 100%;
            width: 1px;
            background: $color-brand-2;
        }
        padding: 0 0 0 $space;
        margin: 0;

        p:last-of-type {
            margin-bottom: 0;
        }
    }
}

.list-featured-item-numbered {
    display: table;
    vertical-align: top;

    .list-featured-item-inner {
        display: table-cell;
        vertical-align: top;
    }
    .list-featured-item-number {
        padding-right: $space;
        display: table-cell;
        font-size: 4.8rem;
        color: $color-brand-2;
        background: none;
        line-height: 1;
    }
}

/**
 * List numbered
 * 
 */

.list-numbered {
    padding: 0;
    margin: 0;

    li {
        list-style: none;
        padding: 0;
        margin: 0;
        overflow: hidden;
        font-family: $font-family-serif;

        span {
            font-size: 26px;

            font-weight: 600;
            //float: left;
            //border: 1px solid blue;
            line-height: 1.5;
            margin: 0 $space-xs 0 0;
        }
    }
}
