// PhD Single record view
body.phd {

    .col-right {
        // @include tk-clearfix;
        padding: 0;
        position: relative;
    
        @media (min-width: $screen-sm-min) {
            height: 100%;
            right: 0;
            float: right;
            padding-right: $grid-gutter-width/2 !important;
            padding-left: $grid-gutter-width/2;
        }
    }

    .header-single {
        padding-top: 2rem;
        margin-bottom: 2.4rem;

        @media (min-width: $screen-sm-min) {
            padding-top: 2.4rem;
        }

        @media (min-width: $screen-md-min) {
            padding-top: 5rem;
            margin-bottom: 3.2rem;
        }
    }

    .heading {
        font-family: $font-family-serif;
        font-size: 2.4rem;
        line-height: 1em;
        letter-spacing: -0.55px;

        @media (min-width: $screen-sm-min) {
            font-size: 3.2rem;
        }

        @media (min-width: $screen-md-min) {
            font-size: 4rem;
        }
    }

        .heading--sm {
            font-size: 2.2rem;

            @media (min-width: $screen-sm-min) {
                font-size: 2.8rem;
            }

            @media (min-width: $screen-md-min) {
                font-size: 3.2rem;
            }
        }

        .heading--lg {
            font-size: 2.8rem;

            @media (min-width: $screen-sm-min) {
                font-size: 4rem;
            }

            @media (min-width: $screen-md-min) {
                font-size: 4.8rem;
            }
        }
        

    .subtitle {
        font-size: 1.6rem;

        @media (min-width: $screen-sm-min) {
            font-size: 1.8rem;
        }
    }

    .key-facts {
        padding: 0 1rem;
    }

        .key-facts__title {
            margin-bottom: 1.2rem;
        }

        .key-facts__list {
            font-size: 1.6rem;
            
            @media (min-width: $screen-md-min) {
                font-size: 1.8rem;
            }
        }

        .key-facts__item-title {
            padding-top: .5rem;
            margin-bottom: .2rem;
            border-top: 1px solid #c0c0c0;

            &:first-child {
                border-top: 2px solid $color-brand-2;
            }
        }

        .key-facts__item-content {
            margin-bottom: 0.8em;

            @media (min-width: $screen-sm-min) {
                margin-bottom: 1.15em;
            }

            a {
                // text-decoration: underline;
            }
        }

    .phd-section {
        padding-bottom: 8px;

        @media (min-width: $screen-md-min) {
            padding-bottom: 2.4rem;
        }

    }

        .phd-section__hr {
            height: 3px;
            background-color: $color-brand-2;
            margin: 0 0 20px;

            @media (min-width: $screen-sm-min) {
                margin: 5px 0 25px;
            }

            @media (min-width: $screen-md-min) {
                margin: 45px 0;
            }
        }

    .cms {

        &>* {
            line-height: 140%;

            @media (min-width: $screen-md-min) {
                line-height: 160%;
            }
        }

        h2,
        h3,
        h4,
        h5,
        h6 {
            font-family: $font-family-serif;
        }

        h2,
        h3 {
            font-size: 2.2rem;

            @media (min-width: $screen-sm-min) {
                font-size: 2.8rem;
            }

            @media (min-width: $screen-md-min) {
                font-size: 3.2rem;
            }
        }

        h4 {
            font-size: 2rem;

            @media (min-width: $screen-sm-min) {
                font-size: 2.4rem;
            }

            @media (min-width: $screen-md-min) {
                font-size: 2.8rem;
            }
        }

        h5 {
            font-size: 1.8rem;

            @media (min-width: $screen-sm-min) {
                font-size: 2.2rem;
            }

            @media (min-width: $screen-md-min) {
                font-size: 2.4rem;
            }
        }

        h6 {
            font-size: 1.6rem;

            @media (min-width: $screen-sm-min) {
                font-size: 1.8rem;
            }

            @media (min-width: $screen-md-min) {
                font-size: 2.2rem;
            }
        }

        .heading-underline {
            text-align: left;
            margin-top: 0;
            margin-bottom: .6em;
            &:before {
                content: none;
            }
        }

        p {
            font-size: 1.6rem;
            line-height: 140%;

            @media (min-width: $screen-sm-min) {
                font-size: 1.8rem;
            }

            @media (min-width: $screen-md-min) {
                font-size: 2rem;
                line-height: 160%;
            }
        }

        ul,
        ol {

            li {
                font-size: 1.6rem;
                @media (min-width: $screen-sm-min) {
                    font-size: 2.0rem;
                }
            }
        }

        ul {
            list-style: circle;

            @media (min-width: $screen-lg) {
                margin-left: 0;
            }
        }

        ol {
            @media (min-width: $screen-lg) {
                margin-left: 0;
            }
        }

        li {
           
        }

        .tk-title-content-logo__content {
            padding-top: 0;
        }
    }

    .phd-linked-content {

    }

        .phd-linked-content__list {
            list-style: none;
            padding-left: 0;
            font-size: 1.6rem;
            
            @media (min-width: $screen-md) {
                font-size: 20px;
            }
        }

        .phd-linked-content__item {
            margin-bottom: .5em;
        }

        .phd-linked-content__link {

        }
}

// PhD homepage search

.phd-home-search {
    background: url('../img/phd-search-bg-mobile.jpg') center;
    background-size: cover;
    margin: 0;
    padding: 12px;

    @media (min-width: $screen-sm) {
        position: relative;
        min-height: 70vh;
        padding-top: 10vh;
        background: url('../img/phd-search-bg-desktop.jpg') top right;
        background-size: cover;
    }
}

.phd-home-search-form {
    position: relative;
    padding: 12px 18px 8px;
    background: rgba(#000, .7);
    color: #fff;
    max-width: 907px;
    margin: 0 auto;

    @media (min-width: $screen-md) {
        padding: 24px 32px;
    }
}

    .phd-home-search-form__fieldset {
        
    }

        .phd-home-search-form__fieldset--search {

            @media (max-width: $screen-sm - 1px) {
                margin-bottom: 20px;
            }
        }

        .phd-home-search-form__fieldset--radios {
            @media (max-width: $screen-sm - 1px) {
                margin-bottom: 28px;
            }

            @media (min-width: $screen-sm) {
                width: calc(100% - 84px);
                position: absolute;
                bottom: 150px;
                box-sizing: border-box;
                border: 2px dashed rgba(#fff, 0);

                .phd-home-search-form:not(.phd-home-search-form--no-outline) & {
                    &:focus-within {
                        outline-color:rgba(0, 103, 244, 0.247);
                        outline-offset:-2px;
                        outline-style:auto;
                        outline-width:5px;
                    }
                }

            }

            @media (min-width: $screen-md) {
                width: calc(100% - 273px);
            }

        }

        .phd-home-search-form__radios {
            @media (min-width: $screen-sm) {
                .phd-home-search-form:not(.phd-home-search-form--no-outline) & {
                    // &:focus-within {
                    //     outline-color:rgba(0, 103, 244, 0.247);
                    //     outline-offset:-2px;
                    //     outline-style:auto;
                    //     outline-width:5px;
                    // }
                }
            }
            
        }


    .phd-home-search-form__legend {
        border: none;
        color: #fff;
        line-height: 1em;
        font-size: 20px;
        margin-bottom: 20px;
    }

        .phd-home-search-form__legend--large {
            font-family: $font-family-serif;
            letter-spacing: -0.55px;
            font-size: 36px;
            margin-bottom: .5em;

            @media (min-width: $screen-sm) {
                font-size: 48px;
                max-width: 90%;
                letter-spacing: -0.5px;
                margin-bottom: 90px;
            }
        }

        .phd-home-search-form__legend--hide-sm {
            @media (min-width: $screen-sm) {
                @include hide-accessibly();
            }
        }

        .phd-home-search-form__hidden {
            @include hide-accessibly();
        }

        .phd-home-search-form__searchbox {
            position: relative;
            height: 40px;
            margin-bottom: 6px;

            @media (min-width: $screen-sm) {
                height: 48px;
                margin-bottom: 73px;
            }
        }

    .phd-home-search-form__input-text {
        color: $color-font;
        body.phd & {
            position: absolute;
            top: 0;
            left: 0;
            display: inline-block;
            width: calc(100% - 28px);
            margin: 0;
            border-radius: 2px 0 0 2px;

            @media (min-width: $screen-sm) {
                height: 48px;
                width: calc(100% - 46px);
            }

            @media (min-width: $screen-md) {
                border-radius: 2px;
                width: calc(100% - 210px);
            }
        }
    }

    .phd-home-search-form__input-submit {
        body.phd & {
            position: absolute;
            top: 0;
            right: 0;
            display: inline-block;
            width: 40px;
            height: 40px;
            border-radius: 0 2px 2px 0;
            background: $color-brand-1;
            border: 1px solid $color-brand-1;

            @media (max-width: $screen-md - 1px) {
                text-indent: -9999px;
                background: $color-brand-1 url('../img/search-white.svg') center no-repeat;
            }

            @media (min-width: $screen-sm) {
                height: 48px;
                width: 48px;
            }

            @media (min-width: $screen-md) {
                width: 199px;
                font-size: 20px;
                border-radius: 2px;
            }
        }
    }

    .phd-home-search-form__radio-container {
        font-size: 20px;

        @media (max-width: $screen-sm - 1px) {
            position: relative;
            height: 48px;
            border-bottom: 1px solid rgba(#fff, .7);
        }

        @media (min-width: $screen-sm) {
            display: inline-block;
            margin-left: 30px;
        }

        @media (min-width: $screen-sm) and (max-width: $screen-md - 1px) {
            margin-left: 0;
            margin-right: 7%;

            &:first-of-type {
                margin-left: 30px;
            }
        }

    }

    .phd-home-search-form__radio-label {
        transition: all .3s ease;
        opacity: .8;

        &:hover {
            cursor: pointer;
        }

        @media (max-width: $screen-sm - 1px) {
            position: absolute;
            top: 4px;
            right: 0;
            bottom: 4px;
            left: 0;
            padding: 8px 8px 8px 18px;


            .js & {
                padding-left: 34px;
            }
        }
    
        @media (min-width: $screen-md) {
            padding: 0 20px 0 0;
            will-change: transform;

            // .phd-home-search-form__radio-container:last-child  &{
            //     padding-right: 0;
            // }

            .phd-home-search-form__radio-container:last-child  &{
                padding-right: 0;
            }

            .no-js & {
                padding: 0 20px 0 5px;
            }
        }

    }

        .phd-home-search-form__radio-label--active {
            opacity: 1;

            @media (min-width: $screen-sm) {
               transform: scale(1.2);
            }
        } 

    .phd-home-search-form__radio-input {

        @media (max-width: $screen-sm - 1px) {
            opacity: 0;
        }

        .js & {
            opacity: 0;
            margin-left: -20px;
            position: absolute;
            left: -9999px;
        }
        
    }

    .phd-home-search-form__radio-checkmark {
        @media (max-width: $screen-sm - 1px) {
            position: absolute;
            top: 9px;
            left: 6px;
            height: 20px;
            width: 20px;
            border: 2px solid #fff;
            border-radius: 50%;
        }
    }

    .phd-home-search-form__radio-input:checked ~ .phd-home-search-form__radio-checkmark {
        @media (max-width: $screen-sm - 1px) {
            &:after {
                content: '';
                position: absolute;
                top: 3px;
                left: 3px;
                height: 10px;
                width: 10px;
                background: #fff;
                border-radius: 50%;
            }
        }
    }

    .phd-home-search-form__chevron-pointer {
        @media (min-width: $screen-sm) {
            .js & {
                position: relative;
                display: block;
                height: 26px;
                overflow: hidden;
            }
        }
    }


    .phd-home-search-form__chevron-pointer-inner {   
        @media (min-width: $screen-sm) {
            .js & {
                display: block;
                width: 1200px;
                height: 26px;
                position: absolute;
                top: 0;
                left: 0;
                background: url('../img/search-chevron-pointer.svg') center repeat-x;
                background-size: 1200px 26px;
                background-position-x: 18px;
                transition: all .2s ease-out;
            }
        }
    }



/*
 * PHD Search results
 */

 .phd-results-search-form {
     position: relative;
 }

 .phd-results-search-form__radios {
     height: 48px;
     border-radius: 2px;
     background-color: #fff !important;
     box-shadow: none;

     @media (min-width: $screen-sm) {
        font-size: 22px;
    }
 }

 .phd-results-search-form__searchbox {
        position: relative;
        height: 40px;
        margin-bottom: 0;

        @media (min-width: $screen-sm) {
            height: 48px;
        }
 }

 .phd-results-search-form__input-text {
    color: $color-font;
    body.phd & {
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        width: calc(100% - 28px);
        margin: 0;
        border-radius: 2px 0 0 2px;
        background: #fff !important;
        box-shadow: none;

        @media (min-width: $screen-sm) {
            height: 48px;
            width: calc(100% - 46px);
        }

        @media (min-width: $screen-md) {
            border-radius: 2px;
            width: calc(100% - 197px);
            font-size: 22px;
        }
    }
}

 .phd-results-search-form__input-submit {
     body.phd & {
         position: absolute;
         top: 0;
         right: 0;
         display: inline-block;
         width: 40px;
         height: 40px;
         border-radius: 0 2px 2px 0;
         background: $color-brand-1;
         border: 1px solid $color-brand-1;

         @media (max-width: $screen-md - 1px) {
             text-indent: -9999px;
             background: $color-brand-1 url('../img/search-white.svg') center no-repeat;
         }

         @media (min-width: $screen-sm) {
             height: 48px;
             width: 48px;
         }

         @media (min-width: $screen-md) {
             width: 199px;
             font-size: 20px;
             border-radius: 2px;
         }
     }
 }

 .phd-results-options {
    position: fixed;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    opacity: 0;
    transform: translateY(-100%);

    @media (min-width: $screen-md) {
        display: none;
    }
}

    .phd-results-options--filter {
        @media (min-width: $screen-md) {
            width: calc(100% / 4 - 20px);
            position: relative;
            transform: none;
            opacity: 1;
            display: inline-block;
            padding: 0;
        }
    }

    .phd-results-options--active {
        display: block;
        opacity: 1;
        transform: translateY(0);
        transition: all .3s ease-out;
        z-index: 10;
    }

    .phd-results-options__header {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 64px;
        background: $color-masthead;

        .phd-results-options--filter & {
            @media (min-width: $screen-md) {
                display: none;
            }
        }
    }

    .phd-results-options__footer {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 72px;
        background: #fff;
        box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.21);

        .phd-results-options--filter & {
            @media (min-width: $screen-md) {
                display: none;
            }
        }
    }

    .phd-results-options__content {

        padding: 16px;

        @media (max-width: $screen-md - 1px) {
            position: absolute;
            top: 72px;
            bottom: 72px;
            left: 0;
            right: 0;
            overflow-y: auto;
        }

        .phd-results-options--filter & {
            @media (min-width: $screen-md) {
                padding: 0;
                padding-top: 20px;
            }
        }
    }

    .phd-results-options__title {
        
        font-size: 16px;
        margin: 26px 80px;
        text-align: center;
        color: #fff;

        // @media (min-width: $screen-md) {
        //     @include hide-accessibly();
        // }
    }

    .btn--phd {
        position: absolute;
        top: 12px;
        color: #fff;
        transition: all .3s ease;

        &:hover,
        &:focus {
            color: #fff;
            background: $color-masthead;
        }
    }

    .btn--phd-filter {
        left: 16px;
        width: calc(100% - 32px);
        height: 48px;
        background-color: $color-brand-1;
        font-size: 18px;
    }

    .btn--phd-clear {
        font-size: 16px;
        top: 15px;
        right: 2px;
        height: 36px;
        background-color: transparent;
        border: transparent;
        text-transform: none;
        &:hover,
        &:focus {
            color: #fff;
            background: $color-masthead;
        }
    }

    .btn--phd-close {
        width: 36px;
        height: 36px;
        left: 6px;
        background: transparent;
        text-indent: -9999px;

        &:before,
        &:after {
            content: '';
            display: block;
            width: 20px;
            height: 2px;
            background: #fff;
            top: 50%;
            left: 8px;
            position: absolute;
        }

        &:before {
            transform: rotate(45deg);
        }

        &:after {
            transform: rotate(135deg);
        }
    }

    .phd-pseudo-radio {
        display: block;
        position: relative;
        font-size: 18px;
        background: transparent;
        border-bottom: 0 solid #c4c4c4;
        border-width: 0 0 1px;
        width: 100%;
        text-align: left;
        padding: 16px 16px 16px 30px;
        opacity: .7;
        transition: opacity .3s ease;

        &:before {
            content: '';
            position: absolute;
            top: 16px;
            left: 2px;
            height: 20px;
            width: 20px;
            border: 2px solid #000;
            border-radius: 50%;
        }
    }

        .phd-pseudo-radio--selected {
            opacity: 1;

            &:after {
                content: '';
                position: absolute;
                top: 21px;
                left: 7px;
                height: 10px;
                width: 10px;
                background: $color-font-dark;
                border-radius: 50%;
            }
        }


/*
 * Select2
 */

.select2-container {
    margin-bottom: 20px;
    
    @media (max-width: $screen-sm) {
        width: calc(100% - 20px) !important;
    }
}

 .phd-results-options .select2-container--default.select2-container--focus .select2-selection--multiple {
    border: solid 1px $color-font !important;
    border-radius: 2px !important;
 }

 .phd-results-options .select2-container--default .select2-search--inline .select2-search__field {

    min-height: auto;
    margin: 6px 0;
    padding: 4px;
    font-size: 16px;
    
    &:focus {
        border: none !important;
        box-shadow: none !important;

    }
 }

 .phd-results-options .select2-selection__choice {
     border-radius: 2px;
     border: none !important;
     border-radius: 2px !important;
     font-size: 16px !important;
     padding: 8px !important;
 }

 .phd-results-options .select2-selection__choice__remove {
    float: right;
    margin-left: 10px;
    font-weight: 100 !important;
    color: $color-font !important;
 }

 .phd-results-options .select2-container .select2-selection--multiple .select2-selection__rendered {
     white-space: normal;
 }

 .select2-container--default .select2-results__option--highlighted[aria-selected] {
     background-color: #D0E2F1;
     color: #000;
 }

.phd-results-fieldset {
    list-style: none;
    padding: 0 0 8px;
    margin: 0 0 16px;
    border-bottom: 1px solid #ddd5cc;
    
    @media (min-width: $screen-md) {
        padding-bottom: 20px;
        margin-bottom: 16px;
    }
    
}

.phd-results-search-form__fieldset--search {
    padding: 16px;
    background: #d6d0c7;
    border-radius: 2px;

    @media (min-width: $screen-md) {
        padding: 30px 30px 30px 25%;
    }
}

 .phd-results-search-form__fieldset--radios {
    @media (max-width: $screen-md - 1px) {
        @include hide-accessibly();
    }

    @media (min-width: $screen-md) {
        position: absolute;
        top: 30px;
        left: 30px;
        width: calc(25% - 30px);
        font-size: 22px;
    }
    

 }

 .phd-results-search-form__hidden {
    @include hide-accessibly();
 }

.phd-results-legend {
    font-size: 20px;
    font-weight: 600;
    border-bottom: none;
    margin-bottom: 16px;
}

.phd {
    .input-tk-check {
        label {
            position: relative;
            padding-left: 30px;
            &:before {
                position: absolute;
                left: 0;
                top: 2px;
                // transform: translateY(-50%);
                margin-right: 8px;
                color: #949494;
            }
        }
    }
}

.input-tk-check label:before

.input-tk-check--phd {
    margin-bottom: 16px;
}

.phd-results__label-count {
    color: #575757;
}

.phd-btn-options-container {
    margin: 24px 10px;

    @media (min-width: $screen-md) {
        display: none;
    }
}

.phd-btn-options-show {
    border-radius: 2px;
    text-transform: none;
    font-size: 18px;

    @media (min-width: $screen-md) {
        display: none;
    }
}

    .phd-btn-options-show--type {
        background: $color-brand-1;
        color: #fff;
        margin-right: 16px;

        &:hover,
        &:focus {
            color: #fff;
        }
    }

    .phd-btn-options-show--filter {
        background: #fff;
        border: 1px solid #979797;
    }


.phd-results-search-form__legend {
    @include hide-accessibly();
}

.header--phd-results {
    margin: 28px auto 0;

    @media (min-width: $screen-md) {
        margin: 67px auto 0px;
    }

    .heading--lg {
        font-size: 36px !important;

        @media (min-width: $screen-sm) {
            font-size: 48px !important;
        }
    }
}


// PHD Search results list

.phd-results-list {
    clear: both;

    @media (min-width: $screen-md) {
        float: right;
        padding-top: 20px;
    }
}

    .phd-results-item {
        position: relative;
        padding-top: 32px;
        border-bottom: 1px solid #c4c4c4;
        margin-bottom: 16px;
    }
    
        .phd-results-item__title {
            font-family: $font-family-serif;
            font-size: 24px;
            color: $color-brand-1;
        }
        
        .phd-results-item__type {
            position: absolute;
            top: 0;
            left: 0;
            font-weight: 600;
        }
            .phd-results-item__type__label {
                @include hide-accessibly();
            }
            
            .phd-results-item__type__data {
                font-size: 18px;
            }
        
        .phd-results-item__description {

        }

        .phd-results-item__meta {
            color:  #757575;
        }

// Phd Pagination
body.phd {
    .pagination a {
        border: 1px solid #2c2c2c;
        background: #fff;
    }

    .pagination > .active > a,
    .pagination > .active > span {
        border: 1px solid #2c2c2c;
        background-color: #2c2c2c;
    }

    .pagination > li > a:hover {
        color: $color-brand-2;
    }
}