/**
 * Clearfix 
 */

@mixin tk-clearfix(){ //We cant extend boostrap mixins so have to replicate it
    &:before,
    &:after {
        content: " "; // 1
        display: table; // 2
    }

    &:after {
        clear: both;
    }
}